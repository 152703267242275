<div class="flex-row">
    <div class="flex-row flex"
         #infiniteScrollContainerBuildActivities
         style="overflow-x: auto">
        <div *ngIf="isView()"
             class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex-100">
            <div class="start-center flex-row">
                <h3 class="mat-title flex">
                    Build Activities
                </h3>
                <button class="header-button"
                        mat-raised-button
                        (click)="generateReport();"
                        color="primary">
                    Generate Report
                </button>
                <button class="header-button"
                        mat-raised-button
                        (click)="newActivityClicked();"
                        color="primary">
                    New Activity
                </button>
            </div>
            <form>
                <div class="search-filter-row flex-row flex">
                    <div class="cb-margin-top cb-margin-bottom cb-margin-right flex-row flex-50">
                        <mat-icon class="cb-margin-top-2x cb-margin-right">
                            search
                        </mat-icon>
                        <mat-form-field class="flex">
                            <input matInput
                                   placeholder="Activity Code"
                                   [(ngModel)]="userCacheItem.data.activityCode"
                                   name="searchActivityCode"/>
                        </mat-form-field>
                    </div>
                    <div class="cb-margin-top cb-margin-bottom cb-margin-right flex-row flex-50">
                        <mat-icon class="cb-margin-top-2x cb-margin-right">
                            search
                        </mat-icon>
                        <mat-form-field class="flex">
                            <input matInput
                                   placeholder="Activity Name"
                                   [(ngModel)]="userCacheItem.data.activityName"
                                   name="searchActivityName"/>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <cb-table label="Build Activities"
                      #infiniteScrollTable
                      maxHeightOffset="251"
                      [columns]="['Code', 'Activity', 'Version', 'Active', 'Compliance', 'Show Draft Activity To Supplier', 'Retained %', '']"
                      [loaded]="searchResults"
                      infiniteScroll
                      [immediateCheck]="false"
                      [infiniteScrollContainer]="infiniteScrollTable?.infiniteScrollContainer"
                      (scrolled)="doSearch()">
                <tbody cbTableBody>
                <tr *ngFor="let element of searchResults">
                    <cb-td-text [value]="element.code"></cb-td-text>
                    <cb-td-text [value]="element.name"></cb-td-text>
                    <cb-td-text [value]="element.version"></cb-td-text>
                    <cb-td-checkbox [value]="element.isActive"></cb-td-checkbox>
                    <cb-td-checkbox [value]="element.hasCompliance"></cb-td-checkbox>
                    <cb-td-checkbox [value]="element.showDraftActivityToSupplier"></cb-td-checkbox>
                    <cb-td-text [value]="element.retain+'%'"></cb-td-text>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <button mat-raised-button
                                mat-icon-button
                                (click)="editClicked(element)">
                            <mat-icon>
                                edit
                            </mat-icon>
                        </button>
                    </td>
                </tr>
                </tbody>
            </cb-table>
        </div>
        <div *ngIf="isEdit()"
             class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x flex-col flex">
            <div class="start-center flex-row">
                <h3 class="mat-title">
                    Build Activities
                </h3>
            </div>
            <div class="flex-row flex">
                <form class="flex-100"
                      #buildActivityForm="ngForm">
                    <mat-tab-group class="flex">
                        <mat-tab label="Activity Details">
                            <build-activity-details *ngIf="mappedItem && buildActivityForm"
                                                    [buildActivity]="mappedItem"
                                                    [buildActivityForm]="buildActivityForm">
                            </build-activity-details>
                        </mat-tab>
                        <mat-tab label="Activity Schedule">
                            <build-activity-schedule *ngIf="mappedItem && buildActivityForm"
                                                     [buildActivity]="mappedItem"
                                                     [buildActivityForm]="buildActivityForm">
                            </build-activity-schedule>
                        </mat-tab>
                        <mat-tab label="Trade/Supply Types">
                            <build-activity-trade-and-supply-types *ngIf="mappedItem && buildActivityForm"
                                                        [buildActivity]="mappedItem"
                                                        [buildActivityForm]="buildActivityForm">
                            </build-activity-trade-and-supply-types>
                        </mat-tab>
                    </mat-tab-group>
                </form>
            </div>
        </div>
</div>
<cb-side-bar class="flex-10"
             *ngIf="isEdit() && mappedItem">
    <div class="flex-col flex">
        <h3 class="mat-title">
            {{sideBarBuildActivityCode}}
        </h3>
        <h3>
            {{sideBarBuildActivityName}}
        </h3>
        <button class="cb-margin"
                mat-raised-button
                color="primary"
                (click)="generateReport();">
            Report
        </button>
        <button mat-raised-button
                class="cb-margin"
                color="primary"
                (click)="save()"
                [disabled]="!formDirty() || !formValid()">
            Save
        </button>
        <button mat-raised-button
                class="cb-margin"
                color="primary"
                (click)="goBack()"
                [disabled]="formDirty()">
            Back
        </button>
        <button mat-raised-button
                class="cb-margin"
                color="primary"
                (click)="goBack()"
                [disabled]="!formDirty()">
            Cancel
        </button>
    </div>
</cb-side-bar>
</div>
