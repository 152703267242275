<cb-dialog class="flex-col"
           dialogHeading="{{ isEdit() ? 'Edit' : 'Add' }} Project">
    <form class="flex-col flex"
          #form="ngForm"
          *cbWaitFor="mappedItem">
        <div class="flex-row flex-fill">
            <div class="cb-margin flex-col flex-33">
                <h3 class="mat-title"
                    style="margin-bottom: 0">
                    Project Details
                </h3>
                <div style="position: relative"
                     class="cb-margin-bottom-3x">
                    <mat-divider></mat-divider>
                </div>
                <cb-display-value-new label="Project Status"
                                      [value]="PROJECT_STATUS_ENUM[mappedItem?.status]">
                </cb-display-value-new>
                <cb-client-accounts-input-find-cards label="Principal"
                                                     [(ngModel)]="mappedItem.accountOwner"
                                                     name="selectedAccount"
                                                     [text]="mappedItem?.accountOwner?.label"
                                                     [removeable]="true"
                                                     [required]="true"
                                                     [canCreate]="true"
                                                     (change)="onAccountChanged($event)">
                </cb-client-accounts-input-find-cards>
                <cb-input class="flex-100"
                          name="projectName"
                          label="Project Name"
                          [required]="true"
                          maxlength="100"
                          [disabled]="false"
                          [(ngModel)]="mappedItem.projectName"
                          [cbUniqueProjectName]="mappedItem"
                          [cbProjectId]="mappedItem?.id || 0">
                </cb-input>
                <span *ngIf="form.controls?.projectName?.errors?.projectName"
                      class="error-msg mat-caption cb-error"
                      style="margin-top: -15px">
                    {{form.controls?.projectName?.errors?.projectName}}
                </span>
                <cb-text-area label="Marketing Description"
                              name="question"
                              [(ngModel)]="mappedItem.description"
                              [maxlength]="1000">
                </cb-text-area>
                <cb-input name="lot"
                          label="Lot"
                          [required]="true"
                          maxlength="100"
                          [(ngModel)]="mappedItem.lot">
                </cb-input>
                <cb-input name="dpNumber"
                          label="DP Number"
                          [required]="true"
                          maxlength="10"
                          [(ngModel)]="mappedItem.dpNumber">
                </cb-input>
                <cb-input name="totalNoStages"
                          label="Total Release Stages"
                          type="number"
                          [required]="true"
                          [(ngModel)]="mappedItem.totalNoStages"
                          [cbTotalNoStagesValidator]="mappedItem?.releaseStages?.length">
                </cb-input>
                <span *ngIf="form.controls?.totalNoStages?.errors?.totalNoStages"
                      class="error-msg mat-caption cb-error"
                      style="margin-top: -15px">
                    {{form.controls?.totalNoStages?.errors?.totalNoStages}}
                </span>
                <cb-address-new [(ngModel)]="mappedItem.address"
                                addressTitle="Project Address"
                                [hideHeading]="false"
                                name="address"
                                [(regionId$)]="regionId$"
                                [required]="true">
                </cb-address-new>
            </div>
            <div class="cb-margin flex-col flex-66">
                <h3 class="mat-title"
                    style="margin-bottom: 0">
                    Account Contacts
                </h3>
                <cb-table class="cb-margin-top cb-margin-bottom-3x"
                          [loaded]="loaded">
                    <thead cbTableHead>
                        <tr>
                            <td>Name</td>
                            <td>Email</td>
                            <td>Home Phone</td>
                            <td>Work Phone</td>
                            <td>Mobile Phone</td>
                        </tr>
                    </thead>
                    <tr cbTableRow
                        *ngFor="let result of mappedItem?.contacts | cbSortByPipe: 'firstName': 'asc'">
                        <cb-td-text [value]="SALUTATION_ENUM[result.title] + ' ' + result.firstName + ' ' +result.lastName">
                        </cb-td-text>
                        <cb-td-text [ngClass]="ContactUtilities.isEmailPreferred(result) ? 'cb-accent' : 'cb-dark-grey'"
                                    [value]="result.email"></cb-td-text>
                        <cb-td-text [ngClass]="ContactUtilities.isHomePreferred(result) ? 'cb-accent' : 'cb-dark-grey'"
                                    [value]="result.phoneHome | cbPhoneNumber"></cb-td-text>
                        <cb-td-text [ngClass]="ContactUtilities.isWorkPreferred(result) ? 'cb-accent' : 'cb-dark-grey'"
                                    [value]="result.phoneWork | cbPhoneNumber"></cb-td-text>
                        <cb-td-text [ngClass]="ContactUtilities.isMobilePreferred(result) ? 'cb-accent' : 'cb-dark-grey'"
                                    [value]="result.phoneMobile | cbPhoneNumber"></cb-td-text>
                    </tr>
                </cb-table>
                <div class="flex-row">
                    <h3 class="mat-title"
                        style="margin-bottom: 0">
                        Project Team
                    </h3>
                    <span class="flex"></span>
                    <div>
                        <button mat-raised-button
                                color="primary"
                                (cbClick)="openAddUserDialog(form.form)">
                            Add User
                        </button>
                    </div>
                </div>
                <cb-table class="cb-margin-top cb-margin-bottom-3x"
                          [loaded]="loaded">
                    <thead cbTableHead>
                        <tr>
                            <td>Name</td>
                            <td>Role</td>
                            <td>Company</td>
                            <td>Mobile Phone</td>
                            <td>Email</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tr cbTableRow
                        *ngFor="let user of mappedItem?.teamMembers | cbSortByPipe: 'firstName': 'asc'">
                        <cb-td-text [value]="user.firstName + ' ' + user.lastName">
                        </cb-td-text>
                        <cb-td-text [value]="user.jobTitle"></cb-td-text>
                        <cb-td-text [value]="user.company"></cb-td-text>
                        <cb-td-text [value]="user.phoneMobile | cbPhoneNumber"></cb-td-text>
                        <cb-td-text [value]="user.email"></cb-td-text>
                        <td cbTableStickyCol="end"
                            cbTableShrinkCol>
                            <button mat-raised-button
                                    mat-icon-button
                                    *ngIf="securityPermissions.canView()"
                                    (cbClick)="deleteUser(user, form.form)">
                                <mat-icon class="cb-grey">remove_circle</mat-icon>
                            </button>
                        </td>
                    </tr>
                </cb-table>
                <cb-checkbox [(ngModel)]="mappedItem.hasProjectLevelRates"
                             name="hasProjectLevelRates"
                             *ngIf="mappedItem"
                             label="Product Rates can be loaded against this Project's Releases"
                             [readonly]="!isEdit() || mappedItem?.$original?.hasProjectLevelRates">
                </cb-checkbox>
                <cb-input *ngIf="!isAdd()"
                          name="projectcode"
                          label="Project Code for Import"
                          [required]="true"
                          maxlength="100"
                          [readonly]="true"
                          [required]="true"
                          [(ngModel)]="mappedItem.code">
                </cb-input>
                <cb-select [required]="true"
                           label="Area"
                           name="locationId"
                           [(ngModel)]="mappedItem.locationId"
                           (change)="onAreaChanged();"
                           [options]="areas">
                </cb-select>
                <cb-select [required]="true"
                           label="Build Consent Council"
                           name="buildingConsentCouncilId"
                           [(ngModel)]="mappedItem.buildingConsentCouncilId"
                           [options]="councils">
                </cb-select>
                <cb-checkbox [(ngModel)]="mappedItem.areAllLotsToBeSoldToOneProjectClient"
                             name="areAllLotsToBeSoldToOneProjectClient"
                             label="Are all lots to be sold to one project client?">
                </cb-checkbox>
            </div>
        </div>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    [disabled]="getDisabled(form.form)"
                    (cbClick)="save()">Save Project</button>
            <button mat-raised-button
                    (click)="cancel(form.dirty)">Cancel</button>
        </footer>
    </form>
</cb-dialog>
