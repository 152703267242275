import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {InfoPopupComponent} from './info-popup.component';
import {CommonModule} from '@angular/common';

const COMPONENTS = [
    InfoPopupComponent,
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CommonModule,
    ],
    exports: COMPONENTS
})
export class CbInfoPopupModule {
}
