
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* eslint-disable max-len */
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CbLoadingSpinnerModule, CbInfoMessageModule } from 'cb-hub-lib';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbHistoryModule } from '@app/shared/components/history/history.module';
import { CbImageButtonModule } from '@app/shared/components/image-handlers/image-button/image-button.module';
import { CbImageGalleryModule } from '@app/shared/components/image-handlers/image-gallery/image-gallery.module';
import { CbLightboxModule } from '@app/shared/components/image-handlers/lightbox/lightbox.module';
import { CbSideBarModule } from '@app/shared/components/side-bar/side-bar.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbTableModule } from 'cb-hub-lib';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbButtonControlModule } from '@app/shared/directives/button-control/button-control.module';
import { CbTabRouteModule } from '@app/shared/directives/tab-route/tab-route.module';
import { CbCallbackModule } from '@app/shared/pipe/callback/callback.module';
import { CbDasherModule } from 'cb-hub-lib';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { CbPrettyBoolModule } from '@app/shared/pipe/pretty-bool/pretty-bool.module';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { DragulaModule } from 'ng2-dragula';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbNotesModule } from '@app/shared/components/notes/notes.module';
import { CbPhoneNumberModule } from '@app/shared/pipe/phone-number/phone-number.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { CbTasksModule } from '@app/shared/components/tasks/tasks.module';
import { CbInfiniteScrollModule } from 'cb-hub-lib';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbUsersModule } from '@app/views/users/users.module';
import { DesignSchemeSearchComponent } from './search/design-scheme-search.component';
import { ManageDesignSchemeViewComponent } from './manage/manage-scheme-view/manage-scheme-view.component';
import { DesignSchemeActionBarComponent } from './manage/action-bar/action-bar.component';
import { DesignSchemeFinaliseDialogComponent } from './manage/finalise-dialog/finalise-dialog.component';
import { DesignSchemeRejectDialogComponent } from './manage/reject-dialog/reject-dialog.component';
import { DesignSchemeSideBarCardComponent } from './manage/side-bar-card/side-bar-card.component';
import { ManageDesignSchemeTabsComponent } from './manage/manage-scheme-tabs/manage-scheme-tabs.component';
import { CbDesignSchemeCardModule } from '@app/shared/components/search-results/cards/design-scheme-card/design-scheme-card.module';
import { DesignSchemesViewComponent } from './design-schemes-view/design-schemes-view.component';
import { DesignSchemeDialogComponent } from './manage/design-scheme-dialog/design-scheme-dialog.component';
import { DesignSchemeDetailsComponent } from './manage/design-scheme-details/design-scheme-details.component';
import { DesignSchemeItemsComponent } from './manage/design-scheme-items/design-scheme-items.component';
import { DesignSchemeItemDialogComponent } from './manage/design-scheme-item-dialog/design-scheme-item-dialog.component';
import { RequestDesignSchemesDialogComponent } from './search/request-design-schemes-dialog/request-design-schemes-dialog.component';
import { CbPopupTipModule } from '@app/shared/components/popup-tip/popup-tip.module';
import { RequestDesignSchemesStepOneComponent } from './search/request-design-schemes-dialog/step-one/request-schemes-step-one.component';
import { CbGenericQuestionsModule } from '@app/shared/components/generic-questions/generic-questions.module';
import { DesignSchemeLotSpecificationComponent } from './manage/lot-specification/lot-specification.component';
import { SchemeDetailsComponent } from './manage/scheme-details/scheme-details.component';
import { LotSpecificationModule } from '../../specification/specification.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { RequestDesignSchemesStepTwoComponent } from './search/request-design-schemes-dialog/step-two/request-schemes-step-two.component';
import { RequestDesignSchemesEditItemDialogComponent } from './search/request-design-schemes-dialog/step-two/request-schemes-edit-item-dialog/request-schemes-edit-item-dialog.component';
import { RequestSchemesItemComponent } from './search/request-design-schemes-dialog/step-two/request-schemes-item/request-schemes-item.component';
import { DesignSchemeAssignedChangesComponent } from './manage/design-scheme-assigned-changes/design-scheme-assigned-changes.component';
import { RequestDesignSchemesStepThreeComponent } from './search/request-design-schemes-dialog/step-three/request-schemes-step-three.component';
import { RequestDesignSchemesStepFourComponent } from './search/request-design-schemes-dialog/step-four/request-schemes-step-four.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { DesignSchemeRequestPricingDialogComponent } from './search/request-pricing-dialog/request-pricing-dialog.component';
import { RequestDesignSchemesStepFiveComponent } from './search/request-design-schemes-dialog/step-five/request-schemes-step-five.component';
import { CompleteSchemeDialogComponent } from './search/complete-scheme-dialog/complete-scheme-dialog.component';
import { RequestDesignSchemeStepSevenComponent } from './search/request-design-schemes-dialog/step-seven/request-scheme-step-seven.component';
import { BasicListItemComponent } from './search/request-design-schemes-dialog/step-seven/basic-list-item/basic-list-item.component';
import { RequestDesignSchemesStepSixComponent } from './search/request-design-schemes-dialog/step-six/request-schemes-step-six.component';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';

const MAT_MODULES = [
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    MatTooltipModule,
    MatMenuModule,
    MatRadioModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSelectModule
];

@NgModule({
    exports: [
        RequestDesignSchemesDialogComponent,
        RequestDesignSchemesEditItemDialogComponent,
        RequestSchemesItemComponent,
        DesignSchemeAssignedChangesComponent,
        DesignSchemesViewComponent
    ],
    declarations: [
        DesignSchemeSearchComponent,
        RequestDesignSchemesEditItemDialogComponent,
        ManageDesignSchemeViewComponent,
        DesignSchemeActionBarComponent,
        DesignSchemeFinaliseDialogComponent,
        DesignSchemeRejectDialogComponent,
        DesignSchemeSideBarCardComponent,
        ManageDesignSchemeTabsComponent,
        DesignSchemesViewComponent,
        DesignSchemeDialogComponent,
        DesignSchemeDetailsComponent,
        DesignSchemeItemsComponent,
        DesignSchemeItemDialogComponent,
        SchemeDetailsComponent,
        DesignSchemeLotSpecificationComponent,
        RequestDesignSchemesDialogComponent,
        RequestDesignSchemesStepOneComponent,
        RequestDesignSchemesStepTwoComponent,
        RequestSchemesItemComponent,
        RequestDesignSchemesStepThreeComponent,
        CompleteSchemeDialogComponent,
        RequestDesignSchemesStepFourComponent,
        DesignSchemeAssignedChangesComponent,
        RequestSchemesItemComponent,
        RequestDesignSchemesStepThreeComponent,
        RequestDesignSchemesStepFiveComponent,
        DesignSchemeRequestPricingDialogComponent,
        RequestDesignSchemeStepSevenComponent,
        BasicListItemComponent,
        RequestDesignSchemesStepSixComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        DragulaModule,
        ReactiveFormsModule,
        BrowserModule,
        CbFormsModule,
        CbTabRouteModule,
        CbTasksModule,
        CbWaitForModule,
        CbDesignSchemeCardModule,
        InfiniteScrollModule,
        CbLoadingSpinnerModule,
        CbInfoMessageModule,
        CbUsersModule,
        CbInfiniteScrollModule,
        CbDialogModule,
        CbPhoneNumberModule,
        CbCurrencyModule,
        CbPrettyBoolModule,
        CbSortByModule,
        CbPopupTipModule,
        CbDocumentsModule,
        CbHubTableModule,
        CbTableModule,
        CbButtonControlModule,
        CbNotesModule,
        CbDasherModule,
        CbSideBarModule,
        CbEnumToLabelModule,
        CommonModule,
        BrowserModule,
        CbHistoryModule,
        CbLightboxModule,
        CbImageGalleryModule,
        CbImageButtonModule,
        CbCallbackModule,
        CbDisplayValueModule,
        CbClickModule,
        CbUserDateTimeModule,
        CbGenericQuestionsModule,
        LotSpecificationModule,
        CbDatePipeModule,
        ...MAT_MODULES],
})

export class LotDesignSchemesModule { }
