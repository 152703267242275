<form class="flex-col flex"
      #searchLeadsForm="ngForm">
    <div class="flex-row flex">
        <mat-card class="cb-margin start flex-col flex">
            <div class="cb-margin start-center gt-sm-row flex">
                <div class="flex-col flex">
                    <div class="start-center flex-row flex">
                        <mat-icon>
                            search
                        </mat-icon>
                        <mat-form-field class="cb-margin-left cb-margin-right flex">
                            <input matInput
                                   type="text"
                                   name="search"
                                   placeholder="Search Leads"
                                   [(ngModel)]="userCacheItem?.data.query"
                                   (ngModelChange)="this.searchQueryUpdate.next($event)"/>
                        </mat-form-field>
                        <div class="flex-col gt-sm-hide">
                            <button class="mat-icon-button mat-raised"
                                    (cbClick)="expanded = !expanded">
                                <mat-icon>
                                    {{expanded ? 'arrow_drop_up' : 'arrow_drop_down'}}
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="filter-selects cb-margin flex-col flex"
                     *ngIf="buildingConsultants?.length > 0">
                    <cb-select-list class="flex"
                                    label="Building Consultant"
                                    [allOptions]="buildingConsultants"
                                    [multiple]="true"
                                    [value]="userCacheItem?.data?.buildingConsultantId"
                                    (selectChanged)="selectedBuildConsultantIdsChanged($event)">
                    </cb-select-list>
                </div>
                <div class="filter-selects cb-margin flex-col flex"
                     *ngIf="leadStatuses?.length > 0">
                    <cb-select-list class="flex"
                                    label="Status"
                                    [allOptions]="leadStatuses"
                                    [multiple]="true"
                                    [value]="userCacheItem?.data?.status"
                                    (selectChanged)="selectedStatusesChanged($event)">
                    </cb-select-list>
                </div>
                <div class="filter-selects cb-margin flex-col flex">
                    <cb-datepicker label="Follow up from"
                                   name="followUpFrom"
                                   [(ngModel)]="fromDateObject"
                                   pickerType="calendar"
                                   (change)="dateUpdated()">
                    </cb-datepicker>
                </div>
                <div class="filter-selects cb-margin flex-col flex">
                    <cb-datepicker label="Follow up to"
                                   name="enquiryDate"
                                   [(ngModel)]="toDateObject"
                                   pickerType="calendar"
                                   (change)="dateUpdated()">
                    </cb-datepicker>
                </div>
                <span class="flex"></span>
                <button mat-raised-button
                        color="primary"
                        (cbClick)="createLead()">
                    Create Lead
                </button>
            </div>
            <div class="flex-row flex"
                 *ngIf="filterChips$?.value?.length > 0">
                <div layout-gt-sm="row"
                     class="cb-margin start-center flex">
                    <div class="flex-col flex">
                        <div class="start-center flex-row flex">
                            <cb-filter-chip-list [(filterChips$)]="filterChips$"
                                                 (updateDataBehindFilterChips)="updateDataBehindFilterChips($event)">
                            </cb-filter-chip-list>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div infiniteScroll
         [infiniteScrollDistance]="1"
         [fromRoot]="true"
         [infiniteScrollContainer]="'div#mainContainer'"
         (scrolled)="doSearch()"
         class="flex-parent flex-wrap xs-column">
        <div class="lead-search-card md-33 sm-50 xs-100 lg-25 xl-20"
             *ngFor="let lead of searchResults">
            <cb-lead-search-card [lead]="lead"
                                 [buildingConsultants]="buildingConsultants">
            </cb-lead-search-card>
        </div>
        <div *ngIf="showNoResultsMessage()"
             class="cb-margin-left start-center flex-row">
            <mat-icon class="mat-accent">
                info_circle
            </mat-icon>
            <span class="mat-body-1 flex">
                There are no results for your search criteria
            </span>
        </div>
    </div>
</form>
