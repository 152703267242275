<button mat-icon-button
        mat-raised-button
        color="accent"
        class="collapse-button"
        (click)="collapsed = !collapsed"
        [matTooltip]="collapsed ? 'Expand' : 'Collapse'">
    <mat-icon class="collapse-icon">{{ collapsed ? 'chevron_right' : 'chevron_left '}}</mat-icon>
</button>
<mat-card *ngIf="collapsed"
          class="collapsed-card flex-row">
    <span class="mat-title collapsed-title">My Leads</span>
</mat-card>
<ng-container *ngIf="!collapsed">
    <mat-card class="filter-card flex-col">
        <div class="flex-row">
            <span class="mat-title flex">My Leads</span>
            <button mat-raised-button
                    mat-icon-button
                    *ngIf="permissions.canCreate()"
                    (click)="createLead()"
                    matTooltip="Create Lead">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <form class="start-center flex-row"
              #searchForm="ngForm">
            <cb-input class="cb-margin-right flex"
                      label="Search"
                      name="search"
                      [(ngModel)]="userCacheItem.data.query"></cb-input>
            <button mat-raised-button
                    [matMenuTriggerFor]="actionsMenu">
                <mat-icon svgIcon="dots-horizontal"></mat-icon>
                &nbsp;Filter
            </button>
            <mat-menu #actionsMenu>
                <div class="cb-margin-2x flex-col">
                    <cb-selection-list label="Show my leads that are"
                                       name="status"
                                       [(ngModel)]="userCacheItem.data.status"
                                       [options]="leadStatuses"
                                       (click)="$event.stopPropagation()"></cb-selection-list>

                    <!-- <cb-select label="Order Leads by"
                               name="order"
                               [(ngModel)]="userCacheItem.data.order"
                               [options]="orders"
                               (click)="$event.stopPropagation()"></cb-select> -->
                </div>
            </mat-menu>
        </form>
    </mat-card>
    <mat-card class="list-card scroll-container flex-col"
              [cbInfiniteScroll]="listContainer"
              [fetch]="fetchResults()"
              [queryUpdated]="queryUpdate"
              [enabled]="searchEnabled"
              [(results)]="results"
              [(currentPage)]="currentPage">
        <cb-info-message *ngIf="results.length < 1"
                         message="There are no Leads available to view"
                         iconClass="cb-accent"></cb-info-message>
        <div class="list-container flex-col"
             #listContainer>
            <mat-list class="flex-col">
                <mat-list-item *ngFor="let lead of results"
                               [ngClass]="{'selected-lead': currentLeadId === lead.id, 'lead-list-item': true}">
                    <div class="follow-up-none flex-row flex"
                         [ngClass]="getFollowUpColour(lead)">
                        <div class="flex-col flex">
                            <h3 matLine
                                class="mat-body-2 item-line"> {{lead.name}} </h3>
                            <p matLine
                               class="item-line"> {{getStatus(lead)}} </p>
                            <p matLine
                               [hidden]="!showFollowUp(lead)"
                               class="item-line">
                                Next follow up
                                <strong class="follow-up-days">{{getCallbackDays(lead.callbackDate)}}</strong>
                            </p>
                            <p matLine
                               class="item-line"> Last activity {{lead.updatedDate | date}}</p>
                        </div>
                        <div class="center-center flex-col">
                            <button mat-icon-button
                                    mat-raised-button
                                    (click)="loadLead(lead.id)"
                                    [disabled]="viewLeadDisabled(lead.id)">
                                <mat-icon>exit_to_app</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </mat-card>
</ng-container>
