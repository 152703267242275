import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CbButtonModule } from '@app/shared/components/button/button.module';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbPopupTipModule } from '@app/shared/components/popup-tip/popup-tip.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { CbDasherModule, CbTableModule } from 'cb-hub-lib';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DesignSchemeCardComponent } from './design-scheme-card.component';

@NgModule({
    declarations: [
        DesignSchemeCardComponent
    ],
    exports: [
        DesignSchemeCardComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        MatTableModule,
        MatIconModule,
        CbDialogModule,
        CbDasherModule,
        CbButtonModule,
        CbPopupTipModule,
        CbUserDateTimeModule,
        CbCurrencyModule,
        CommonModule,
        FormsModule,
        CbFormsModule,
        MatInputModule,
        CbDisplayValueModule,
        MatListModule,
        CbWaitForModule,
        MatSelectModule,
        MatDatepickerModule,
        MatCardModule,
        MatIconModule,
        MatCheckboxModule,
        InfiniteScrollModule,
        MatTableModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatTooltipModule,
        CbHubTableModule,
        CbTableModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MatDividerModule
    ]
})
export class CbDesignSchemeCardModule { }

