<cb-dialog dialogHeading="Contract Pricing Details">
    <form class="flex-col flex"
          #contractPricingForm="ngForm">
        <cb-currency *ngIf="data.clientSale.isThirdPartyHouseAndLand"
                     type="number"
                     label="Lot Deposit"
                     required
                     [inclGst]="true"
                     name="thirdPartyLotDeposit"
                     [(ngModel)]="contractPricingUpdateDto.thirdPartyLotDeposit">
        </cb-currency>
        <cb-currency *ngIf="data.clientSale.isThirdPartyHouseAndLand"
                     type="number"
                     label="Lot Purchase Price"
                     required
                     [inclGst]="true"
                     name="thirdPartyLotPurchasePrice"
                     [(ngModel)]="contractPricingUpdateDto.thirdPartyLotPurchasePrice">
        </cb-currency>
        <cb-currency *ngIf="data.clientSale.isExternalDesignAndLand"
                     type="number"
                     label="Lot Deposit"
                     required
                     [inclGst]="true"
                     name="designAndLandLotDeposit"
                     [(ngModel)]="contractPricingUpdateDto.designAndLandLotDeposit">
        </cb-currency>
        <cb-currency *ngIf="data.clientSale.isExternalDesignAndLand"
                     type="number"
                     label="Lot Purchase Price"
                     required
                     [inclGst]="true"
                     name="designAndLandLotPurchasePrice"
                     [(ngModel)]="contractPricingUpdateDto.designAndLandLotPurchasePrice">
        </cb-currency>
        <div class="start-center flex-row"
             *ngIf="data.clientSale.isThirdPartyHouseAndLand || data.clientSale.isExternalDesignAndLand">
            <cb-client-account-autocomplete-scroller class="cb-margin-right flex"
                                                     label="Lot Vendor"
                                                     name="lotVendorSearch"
                                                     [(ngModel)]="selectedLotVendor"
                                                     [requireMatch]="true"
                                                     [matchProps]="['id']"
                                                     [required]="true">
            </cb-client-account-autocomplete-scroller>
            <button mat-raised-button
                    mat-icon-button
                    matTooltip="Add a new Account"
                    (click)="openAddDialog(contractPricingForm)">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <cb-currency label="Contract Price"
                     required
                     name="contractPrice"
                     [inclGst]="true"
                     (change)="TriggerContractPriceUpdate()"
                     [(ngModel)]="contractPricingUpdateDto.contractPrice">
        </cb-currency>
        <cb-currency *ngIf="data.clientSale.isHouseAndLand || (data.clientSale.isDesignAndLand && !data.clientSale.lot.hasExternalLandVendor)"
                     class="cb-margin-top"
                     label="Contract Generation Fee"
                     required
                     [inclGst]="true"
                     name="contractGenerationFee"
                     [(ngModel)]="contractPricingUpdateDto.contractGenerationFee">
        </cb-currency>
        <cb-currency *ngIf="data.clientSale.isHouseAndLand || (data.clientSale.isDesignAndLand && !data.clientSale.lot.hasExternalLandVendor)"
                     label="Lot Holding Deposit"
                     required
                     [inclGst]="true"
                     name="lotHoldingDeposit"
                     [(ngModel)]="contractPricingUpdateDto.lotHoldingDeposit">
        </cb-currency>
        <cb-user-autocomplete-scroller class="cb-margin-top flex"
                                       label="Contract Manager"
                                       name="contractManagerSearch"
                                       [(ngModel)]="selectedContractManager"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [active]="true"
                                       [tag]="CONTRACT_MANAGER_TAG"
                                       required>
        </cb-user-autocomplete-scroller>
        <cb-select required
                   label="Payment Template"
                   name="paymentTemplates"
                   [valueProp]="WHOLE_OPTION_VALUE_PROP"
                   [(ngModel)]="selectedPaymentTemplate"
                   (change)="onPaymentTemplateChanged($event)"
                   [options]="paymentTemplates">
        </cb-select>
        <cb-progress-payments-table [contractPricingUpdateDto]="contractPricingUpdateDto">
        </cb-progress-payments-table>
        <cb-select [required]="true"
                   *ngIf="data.clientSale.isDesignAndBuild"
                   class="cb-margin-top-3x"
                   label="Balance Payable on"
                   name="balancePayableOn"
                   [(ngModel)]="contractPricingUpdateDto.clientSaleBalanceDueTrigger"
                   [options]="CLIENT_SALE_BALANCE_DUE_TRIGGER_ENUM.toLookup()">
        </cb-select>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="contractPricingForm.invalid || contractPricingForm.pristine"
                (click)="save()">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
