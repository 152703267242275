<mat-card class="flex-col">
    <div class="card-area">
        <mat-card-title>
            <h1 class="mat-title">
                {{lead.name}}
            </h1>
        </mat-card-title>
        <mat-card-content>
            <section>
                <cb-display-value-new label="Lead Type"
                                      [value]="contractType">
                </cb-display-value-new>
                <cb-display-value-new label="Building Consultant"
                                      [value]="lead.buildingConsultant">
                </cb-display-value-new>
                <cb-display-value-new label="Status"
                                      [value]="lead.status">
                </cb-display-value-new>
                <cb-user-date-time-new label="Next Callback"
                                       [date]="lead.callbackDate">
                </cb-user-date-time-new>
            </section>
        </mat-card-content>
    </div>
    <mat-divider></mat-divider>
    <mat-card-actions>
        <div class="end-center flex-row">
            <button mat-stroked-button
                    (cbClick)="viewLead($event)"
                    color="accent">
                View Lead
            </button>
        </div>
    </mat-card-actions>
</mat-card>
