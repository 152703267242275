import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { getOwnPropertyDescriptorDeep } from '@app/shared/utils/object.util';
import { filter, Observable, Observer, Subject, Subscription, tap, throttleTime } from 'rxjs';

/**
 * **cbClick**: The below example has the following effects:
 *  - (cbClick) triggers a function just like (click)
 *  - [throttleTime] (optional) means that clicks will only fire the (cbClick) function if they are atleast 1000ms apart
 *  - [clickLimit] (optional) means that this button can only be click twice before it is permanently disabled
 * ```typescript
 * <button (cbClick)="saveOrder()"
 *         [throttleTime]="1000"
 *         [clickLimit]="2">Click me</button>
 * ```
 * \
 * **cbClickRx**: The below example has the following effects:
 *  - (cbClickRx) subscribes to the passed in observable
 *  - [observer] (optional) takes an observer object and fires the next/error/complete; just like a standard subscribe.
 * ```typescript
 * <button (cbClickRx)="saveObservable"
 *         [observer]="{ next: handleNext, error: handleError, complete: handleComplete }">Click me</button>
 * ```
 */
@Directive({
    selector: '[cbClick], [cbClickRx]'
})
export class CbClickDirective implements OnInit, OnDestroy {
    // plain click event binding
    @Output() public readonly cbClick = new EventEmitter();

    // observable bindings
    @Input() public cbClickRx: Observable<any> | null;
    @Input() public observer: Observer<any> | null;
    private cbClickRxInProgress = false;

    /** minimum time allowed between clicks */
    @Input() public throttleTime = 500;
    /** limit the number of times this button can be clicked */
    @Input() public clickLimit = 0; // 0 means not set/infinite

    private cbClickDisabled = false;
    private totalClicks = 0;
    private clicks$ = new Subject<MouseEvent>();
    private subscription$: Subscription;

    private get clickLimitReached(): boolean {
        return this.clickLimit > 0 && this.totalClicks >= this.clickLimit;
    }

    constructor(public readonly host: MatButton) {
        this.extendButtonDisabled();
    }

    public ngOnInit(): void {
        this.subscription$ = this.clicks$.pipe(
            filter(_ => !this.host.disabled && !this.clickLimitReached),
            throttleTime(this.throttleTime),
            tap(_ => {
                this.cbClickDisabled = true;
                setTimeout(() => {
                    this.cbClickDisabled = this.clickLimitReached;
                }, this.throttleTime);
            }),
            tap(_ => this.totalClicks += 1),
        ).subscribe(this.handleSubscribe);
    }

    private readonly handleSubscribe = (mouseEvent: MouseEvent): void => {
        if (this.cbClickRx != null) {
            this.cbClickRxInProgress = true;
            this.cbClickRx
                .pipe(tap(() => this.cbClickRxInProgress = false))
                .subOnce(this.observer);
        } else {
            this.cbClick.emit(mouseEvent);
            this.cbClickDisabled = this.clickLimitReached;
        }
    };

    public ngOnDestroy(): void {
        this.subscription$.unsubscribe();
    }

    @HostListener('click', ['$event'])
    public handleClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.clicks$.next(event);
    }

    /** Extends [disabled] getter on Angular MatButton */
    private extendButtonDisabled(): void {
        const propName = 'disabled';
        const original = getOwnPropertyDescriptorDeep(this.host, propName, {
            descriptorCondition: (descriptor) => {
                return descriptor.get != null && descriptor.set != null;
            }
        });
        if (!original) {
            return;
        }
        Object.defineProperty(this.host, propName, {
            ...original,
            get: (): boolean => {
                return this.cbClickRxInProgress || this.cbClickDisabled || original.get.call(this.host);
            }
        });
    }

}
