import {NgModule} from '@angular/core';
import {
    AbmIntegrationPermissions,
    AccountsReceivablePermissions,
    BuildProgrammePermissions,
    BuildProgrammeTemplatePermissions,
    BusinessAccountPermissions,
    ClientAccountPermissions,
    ClientSalePermissions,
    ContactPermissions,
    DesignSchemePermissions,
    DesignTeamPermissions,
    HelpPermissions,
    HouseLandPermissions,
    LeadPermissions,
    LotBuildOverheadCostsPermissions,
    LotBuildPermissions,
    LotChangePermissions,
    LotCostPermissions,
    LotDesignPermissions,
    LotPermissions,
    LotQuotePermissions,
    LotVariationPermissions,
    PermissionsPermissions,
    ProductPermissions,
    ProjectPermissions,
    QSTeamPermissions,
    SpecificationTemplatePermissions,
    StandardPlanPermissions
} from '../permissions';
import {CouncilRfiPermissions} from './council.rfi.permission';
import {ProjectReleaseStagePermissions} from './project-release-stage.permissions';

@NgModule({
    providers: [
        QSTeamPermissions,
        DesignTeamPermissions,
        ContactPermissions,
        LeadPermissions,
        ClientAccountPermissions,
        ClientSalePermissions,
        HouseLandPermissions,
        StandardPlanPermissions,
        LotPermissions,
        ProjectPermissions,
        ProjectReleaseStagePermissions,
        SpecificationTemplatePermissions,
        BuildProgrammeTemplatePermissions,
        BuildProgrammePermissions,
        LotCostPermissions,
        BusinessAccountPermissions,
        ProductPermissions,
        PermissionsPermissions,
        HelpPermissions,
        LotChangePermissions,
        LotBuildPermissions,
        LotDesignPermissions,
        CouncilRfiPermissions,
        DesignSchemePermissions,
        LotVariationPermissions,
        LotQuotePermissions,
        AccountsReceivablePermissions,
        AbmIntegrationPermissions,
        LotBuildOverheadCostsPermissions
    ]
})

export class PermissionsModule {
}
