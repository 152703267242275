<div class="start-center flex-row">
    <h3 class="mat-title flex">
        Colour Items
    </h3>
    <button class="header-button"
            mat-raised-button
            (click)="newItemClicked()"
            color="primary">
        Add New Colour Item
    </button>
</div>
<cb-table class="cb-margin-top"
          [loaded]="true"
          [columns]="columns"
          [maxHeightOffset]="207">
    <tr cbTableRow
        *ngFor="let result of searchResults">
        <cb-td-text [value]="result.code"></cb-td-text>
        <cb-td-text [value]="result.name"></cb-td-text>
        <cb-td-checkbox [value]="result.isActive"></cb-td-checkbox>
        <td cbTableStickyCol="end"
            cbTableShrinkCol>
            <button mat-raised-button
                    mat-icon-button
                    (click)="editItemClicked(result)">
                <mat-icon>
                    edit
                </mat-icon>
            </button>
        </td>
    </tr>
</cb-table>
