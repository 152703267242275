<form class="flex-col flex"
      #form="ngForm">
    <mat-card class="flex-col flex">
        <div class="start-start flex-row">
            <div class="cb-margin-right-2x flex-row"
                 style="margin-top: 5px;">
                <mat-icon [ngClass]="getStatusIconClass()"
                          class="help-cursor"
                          [matTooltip]="getStatusIconTitle()">{{getStatusIconIcon()}}</mat-icon>
            </div>
            <span class="mat-title">Import #{{mappedItem.id}}</span>
        </div>
        <mat-card-content class="flex">
            <div class="flex-col flex">
                <cb-user-date-time-new label="Imported"
                                       *ngIf="mappedItem?.createdDate && mappedItem?.createdByName"
                                       [date]="mappedItem?.createdDate"
                                       [userFullName]="mappedItem?.createdByName">
                </cb-user-date-time-new>
                <cb-user-date-time-new label="Updated"
                                       *ngIf="mappedItem?.updatedDate && mappedItem?.updatedByName"
                                       [date]="mappedItem?.updatedDate"
                                       [userFullName]="mappedItem?.updatedByName">
                </cb-user-date-time-new>
            </div>
        </mat-card-content>
        <div position="relative">
            <mat-divider></mat-divider>
        </div>
        <mat-card-actions class="end-center flex-row">
            <cb-button color="white"
                       [isStroked]="true"
                       icon="file_download"
                       buttonLabel="Download"
                       *ngIf="mappedItem.transferHistoryId"
                       (clicked)="getTransferHistoryFile()">
            </cb-button>
            <span class="flex"></span>
            <cb-button color="accent"
                       [isStroked]="true"
                       [disabled]="!canViewTakeOff"
                       *ngIf="mappedItem.transferHistoryId"
                       (clicked)="goToTakeOff()">
                Manage
            </cb-button>
        </mat-card-actions>
    </mat-card>
</form>
