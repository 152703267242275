<div class="flex-col flex-fill"
     [style.minWidth]="collapsed$.value ? 'unset' : '280px'">
    <button mat-icon-button
            mat-raised-button
            color="accent"
            class="collapse-button"
            (click)="collapsed$.next(!collapsed$.value)"
            [matTooltip]="collapsed$.value ? 'Expand' : 'Collapse'">
        <mat-icon class="collapse-icon">
            {{ collapsed$.value ? 'chevron_left' : 'chevron_right '}}
        </mat-icon>
    </button>
    <mat-card class="collapsible-card flex-col">
        <div class="flex-row"
             *ngIf="(collapsed$ | async)">
            <h1 class="mat-title collapsed-title">Build Programme Template Information</h1>
        </div>
        <div class="flex-col flex"
             *ngIf="!(collapsed$ | async)">
            <div class="flex-col">
                <label class="mat-subheading-2 cb-margin-bottom">{{template.name}}</label>
                <hr class="mat-divider"/>
                <cb-input label="Regions"
                          class="cb-margin-top"
                          [readonly]="true"
                          [ngModel]="getRegionsText()"></cb-input>
                <cb-checkbox label="Active"
                             [readonly]="true"
                             [ngModel]="template.isActive"></cb-checkbox>
                <div class="flex-col flex"
                     *ngIf="!isEditing$.value">
                    <button mat-raised-button
                            color="primary"
                            class="cb-margin-top"
                            [disabled]="!canEdit"
                            (click)="editItemClicked()">
                        Edit Details
                    </button>
                    <button mat-raised-button
                            color="primary"
                            class="cb-margin-top"
                            [disabled]="!canEdit"
                            (click)="isEditing$.next(true)">
                        Manage
                    </button>
                </div>
                <div class="flex-col flex"
                     *ngIf="isEditing$.value">
                    <button mat-raised-button
                            color="primary"
                            class="cb-margin-top"
                            (click)="saveClick.emit()">
                        Save
                    </button>
                    <button mat-raised-button
                            color="default"
                            class="cb-margin-top"
                            (click)="isEditing$.next(false)">
                        Cancel
                    </button>
                </div>
                <cb-user-date-time-new *ngIf="template.createdByName && template.createdDate"
                                       class="cb-margin-top-3x"
                                       [label]="'Created'"
                                       [userFullName]="template.createdByName"
                                       [date]="template.createdDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true">
                </cb-user-date-time-new>
                <cb-user-date-time-new *ngIf="template.updatedByName && template.updatedDate"
                                       [label]="'Updated'"
                                       [userFullName]="template.updatedByName"
                                       [date]="template.updatedDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true">
                </cb-user-date-time-new>
            </div>
        </div>
    </mat-card>
</div>
