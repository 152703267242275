<cb-dialog dialogHeading="Select Lead">
    <div class="flex-col flex-100">
        <div class="cb-table cb-margin-top">
            <mat-table [dataSource]="resultList">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{LEAD_STATUS_ENUM[element.status]}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="mainContact">
                    <mat-header-cell *matHeaderCellDef> Main Contact </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.mainContact }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions"
                              stickyEnd>
                    <mat-header-cell *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-raised-button
                                mat-icon-button
                                (click)="viewLead(element, $event)">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>
    </div>
</cb-dialog>
