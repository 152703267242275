import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TradeTypesComponent} from './trade-types/trade-types.component';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {FormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {TradeTypesTableComponent} from './trade-types-table/trade-types-table.component';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {CbTableModule} from 'cb-hub-lib';
import {MatIconModule} from '@angular/material/icon';
import {TradeTypeDialogComponent} from './trade-type-dialog/trade-type-dialog.component';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {CbClickModule} from '@app/shared/directives/click/click.module';
import {CbWaitForModule} from '@app/shared/directives/wait-for/wait-for.module';


@NgModule({
    declarations: [
        TradeTypesComponent,
        TradeTypesTableComponent,
        TradeTypeDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        CbFormsModule,
        MatButtonModule,
        CbHubTableModule,
        CbTableModule,
        MatIconModule,
        CbDialogModule,
        CbClickModule,
        CbWaitForModule,
    ],
    exports: [
        TradeTypesComponent
    ],
})
export class TradeTypesModule { }
