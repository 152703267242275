<div #infiniteScrollContainer
     class="cb-padding-left-2x cb-padding-right-2x cb-padding-bottom-2x cb-padding-top flex-row flex">
    <mat-card class="flex-col flex-100">
        <div class="flex-row flex-100">
            <div class="cb-margin-right-3x flex-col flex-33">
                <div>
                    <h4 class="cb-title">Account Info</h4>
                </div>
                <mat-divider [inset]="true"
                             class="cb-margin-bottom"> </mat-divider>
                <cb-display-value-new label="Name"
                                      [value]="account.name"></cb-display-value-new>
                <cb-display-value-new label="Legal Name"
                                      [value]="account.legalName"></cb-display-value-new>
                <cb-display-value-new label="Account Type"
                                      [value]="ACCOUNT_TYPE_ENUM[account.accountType]"></cb-display-value-new>
                <cb-display-value-new label="IRD Number"
                                      [value]="account.irdNumber"></cb-display-value-new>
                <cb-display-value-new label="Solicitor"
                                      [value]="account.solicitor"></cb-display-value-new>
                <cb-display-value-new label="Solicitor Email"
                                      [value]="account.solicitorEmail"></cb-display-value-new>
                <cb-display-value-new label="ABM Code"
                                      [value]="account.abmCode"></cb-display-value-new>
                <mat-checkbox [disabled]="true"
                              name="getsSurveys"
                              style="height: 25px;"
                              [(ngModel)]="account.isGstRegistered"
                              class="cb-margin-bottom">Is GST Registered</mat-checkbox>
                <cb-display-value-new label="Account Status"
                                      [value]="CLIENT_ACCOUNT_STATUS_ENUM[account.status]"></cb-display-value-new>
            </div>
            <div class="cb-margin-left-3x cb-margin-right-3x flex-col flex-33">
                <h4 class="cb-title">Physical Address</h4>
                <mat-divider [inset]="true"
                             class="cb-margin-bottom"> </mat-divider>
                <p>{{ account.physicalAddress?.street }}</p>
                <p>
                    {{ account.physicalAddress?.suburb }}
                </p>
                <p>
                    {{ account.physicalAddress?.city }}
                    {{ account.physicalAddress?.postCode }}
                </p>
                <p>
                    {{ account.physicalAddress?.regionName }}
                </p>
            </div>
            <div class="cb-margin-left-3x cb-margin-right-3x flex-col flex-33">
                <h4 class="cb-title">Postal Address</h4>
                <mat-divider [inset]="true"
                             class="cb-margin-bottom"> </mat-divider>
                <p>{{ postalAddress?.street }}</p>
                <p>
                    {{ postalAddress?.suburb }}
                </p>
                <p>
                    {{ postalAddress?.city }}
                    {{ postalAddress?.postCode }}
                </p>
                <p>
                    {{ postalAddress?.regionName }}
                </p>
            </div>
        </div>
        <div class="flex-row flex-100">
            <div class="flex-col flex-100">
                <div class="flex-col">
                    <div class="flex-row">
                        <h3 class="mat-title flex"
                            style="margin: 0;">Contacts
                        </h3>
                    </div>
                    <mat-divider [inset]="true"
                                 class="cb-margin-bottom"> </mat-divider>
                </div>
                <div class="cb-table cb-margin-top">
                    <mat-table [dataSource]="contacts">
                        <ng-container matColumnDef="isMainContact">
                            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <mat-checkbox name="getsSurveys"
                                              style="height: 25px;"
                                              [checked]="element.id == account.mainContactId"
                                              [disabled]="true"
                                              class="cb-margin-bottom"></mat-checkbox>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="title">
                            <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{SALUTATION_ENUM[element.title]}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{[element.name]}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <mat-header-cell *matHeaderCellDef>Email </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.email }} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="phoneHome">
                            <mat-header-cell *matHeaderCellDef> Home Phone </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.phoneHome}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="phoneWork">
                            <mat-header-cell *matHeaderCellDef> Work Phone </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.phoneWork}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="phoneMobile">
                            <mat-header-cell *matHeaderCellDef> Mobile </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.phoneMobile}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="address">
                            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.address}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="isStaffMember">
                            <mat-header-cell *matHeaderCellDef> Staff </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <mat-checkbox [disabled]="true"
                                              name="getsSurveys"
                                              style="height: 25px;"
                                              [(ngModel)]="element.isStaffMember"
                                              class="cb-margin-bottom"></mat-checkbox>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.status}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="actions"
                                      stickyEnd>
                            <mat-header-cell *matHeaderCellDef>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <button mat-raised-button
                                        mat-icon-button
                                        (click)="viewContact(element.id, $event)">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                    <cb-info-message *ngIf=" contacts.length === 0"
                                     [message]="'No Contacts Found'"
                                     [icon]="'info_circle'"></cb-info-message>
                </div>
            </div>
        </div>
    </mat-card>
</div>
