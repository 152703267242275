<cb-lot-spec-colour-item-icons [mappedItem]="mappedItem"></cb-lot-spec-colour-item-icons>
<cb-td-text #codeCol
            [value]="mappedItem.colourItem.code"></cb-td-text>
<td>
    {{mappedItem.colourItem.name}}: {{mappedItem.name}}
</td>
<td #categoryCol>-</td>
<td>Colour</td>
<td #supplierCol>-</td>
<td [delayed]="false"
    (cbAfterInit)="colWidthSyncService.update(mappedItem.id, codeCol?.elementRef?.nativeElement, categoryCol, supplierCol)"
    (cbOnResize)="colWidthSyncService.update(mappedItem.id, codeCol?.elementRef?.nativeElement, categoryCol, supplierCol)"
    (cbOnDestroy)="colWidthSyncService.delete(mappedItem.id)"
    cbTableShrinkCol
    cbTableStickyCol="end">
    <button mat-raised-button
            mat-icon-button
            [matMenuTriggerFor]="menu">
        <mat-icon mat-menu-origin>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item
                (click)="uiActions.editColourItem()"
                *ngIf="uiActions.canChangeSpecification()">Edit</button>
        <button mat-menu-item
                (click)="uiActions.removeColourItem()"
                *ngIf="uiActions.canChangeSpecification()">Remove</button>
    </mat-menu>
</td>
