<div class="flex-col"
     *cbWaitFor="searchEnabled$ | async">
    <cb-lot-search-filter [(searchData)]="this.userCacheItem.data"
                          [(fetchInProgress)]="fetchInProgress"
                          [floorAreas]="floorAreas"
                          class="cb-margin-bottom flex">
    </cb-lot-search-filter>

    <!-- CARD VIEW -->
    <ng-container *ngIf="!this.userCacheItem.data.enableListView">
        <cb-info-message *ngIf="shouldShowNoResultsMessage()"
                         class="cb-margin"
                         message="There are no lots available for your search criteria"
                         iconClass="cb-accent">
        </cb-info-message>
        <cb-loading-spinner *ngIf="fetchInProgress"
                            message="Please wait, fetching results...">
        </cb-loading-spinner>
        <div class="scroll-container"
             [cbInfiniteScroll]="listContainer"
             [fetch]="fetchResults()"
             [queryUpdated]="queryUpdate$"
             [enabled]="searchEnabled$ | async"
             [(results)]="results"
             [(currentPage)]="currentPage"
             (fetchInProgressChange)="fetchInProgress = $event">
            <div class="flex-wrap"
                 #listContainer>
                <cb-lot-card class="flex-col md-33 sm-50 xs-100 lg-25 xl-20"
                             *ngFor="let result of results"
                             [lotDocumentDto]="result">
                </cb-lot-card>
            </div>
        </div>
    </ng-container>

    <!-- TABLE VIEW -->
    <div *ngIf="this.userCacheItem.data.enableListView"
         class="scroll-container"
         [cbInfiniteScroll]="listContainer"
         [fetch]="fetchResults()"
         [queryUpdated]="queryUpdate$"
         [enabled]="searchEnabled$ | async"
         [(results)]="results"
         (fetchInProgressChange)="fetchInProgress = $event"
         [(currentPage)]="currentPage">
        <cb-table [loaded]="true">
            <thead cbTableHead>
                <tr>
                    <td style="min-width: 86px;">
                        Lot Number
                    </td>
                    <td>
                        Project
                    </td>
                    <td>
                        Address
                    </td>
                    <td>
                        Client Acount
                    </td>
                    <td>
                        Status
                    </td>
                    <td style="min-width: 86px;">
                        Job Number
                    </td>
                    <td class="actions"></td>
                </tr>
            </thead>
            <tbody cbTableBody
                   #listContainer>
                <tr *ngFor="let result of results">
                    <td>
                        {{result.lotNumber}}
                    </td>
                    <td>
                        {{result.projectName}}
                    </td>
                    <td>
                        {{result.addressStreet}}, {{result.addressSuburb}}, {{result.addressCity}}
                    </td>
                    <td>
                        {{result.clientAccount}}
                    </td>
                    <td>
                        {{result.jobStatus}}
                    </td>
                    <td>
                        {{result.siteNumber}}
                    </td>
                    <td class="actions">
                        <button mat-raised-button
                                mat-icon-button
                                (click)="viewLot(result.id)">
                            <mat-icon>
                                pageview
                            </mat-icon>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="fetchInProgress">
                    <td colspan="7">
                        <cb-loading-spinner message="Please wait, fetching results..."></cb-loading-spinner>
                    </td>
                </tr>
                <tr *ngIf="(!(results.length > 0) && !fetchInProgress)">
                    <td colspan="7">
                        <mat-icon class="cb-accent">
                            info_circle
                        </mat-icon>
                        &nbsp;There are no lots available for your search criteria
                    </td>
                </tr>
            </tbody>
        </cb-table>
    </div>
</div>
