<div class="flex-row flex">
    <div class="flex">
        <mat-tab-group [selectedIndex]="currentTabIndex"
                       (selectedIndexChange)="tabIndexChanged($event)">
            <mat-tab label="Build Programme Template">
                <ng-template [matTabContent]>
                    <div *ngIf="template"
                         class="cb-padding flex-row flex">
                        <div class="scrollable-container cb-padding-right flex">
                            <div style="text-align: right;">
                                <button mat-raised-button
                                        class="cb-margin-bottom"
                                        (click)="expandOrCollapseAll()">
                                    <span *ngIf="!isAllExpanded()">Expand All&nbsp;</span>
                                    <span *ngIf="isAllExpanded()">Collapse All&nbsp;</span>
                                    <mat-icon *ngIf="!isAllExpanded()">keyboard_arrow_down</mat-icon>
                                    <mat-icon *ngIf="isAllExpanded()">keyboard_arrow_up</mat-icon>
                                </button>
                            </div>
                            <div class="dragula-stage-drop"
                                 dragula="BUILD_STAGES"
                                 [(dragulaModel)]="template.stages">
                                <mat-expansion-panel class="panel cb-margin-bottom"
                                                     *ngFor="let stage of template.stages"
                                                     [ngClass]="{'dragula-stage': isEditing$.value}"
                                                     [(expanded)]="expansionPanelStates[stage.buildStageId]">
                                    <mat-expansion-panel-header [ngClass]="{'dragula-stage-handler': isEditing$.value}">
                                        <mat-panel-title [ngClass]="{'dragula-stage-handler': isEditing$.value}">
                                            <strong [ngClass]="{'dragula-stage-handler': isEditing$.value}">
                                                {{stage.buildStageCode}}</strong>
                                            <span class="cb-margin-left-2x"
                                                  [ngClass]="{'dragula-stage-handler': isEditing$.value}">
                                                {{stage.buildStageLabel}}
                                                ({{stage.activities ? stage.activities.length : 0}})
                                            </span>
                                        </mat-panel-title>
                                        <button *ngIf="isEditing$.value"
                                                class="cb-margin-right-3x"
                                                mat-raised-button
                                                mat-icon-button
                                                (click)="$event.stopPropagation();removeStageClicked(stage);">
                                            <mat-icon>remove_circle</mat-icon>
                                        </button>
                                    </mat-expansion-panel-header>
                                    <cb-table [loaded]="true"
                                              [columns]="activityColumns">
                                        <tbody cbTableBody
                                               class="dragula-activity-drop"
                                               dragula="BUILD_ACTIVITIES"
                                               [(dragulaModel)]="stage.activities">
                                            <tr *ngFor="let activity of stage.activities"
                                                cbTableRow
                                                class="dragable"
                                                [ngClass]="{'dragula-activity': isEditing$.value}">
                                                <td>
                                                    <mat-icon *ngIf="activity.relationships && activity.relationships.length">
                                                        attachment</mat-icon>
                                                </td>
                                                <cb-td-text [value]="activity.activity.code"></cb-td-text>
                                                <cb-td-text [value]="activity.activity.name"></cb-td-text>
                                                <td>{{activity.activityDurationDays || "0"}} days</td>
                                                <td cbTableStickyCol="end"
                                                    cbTableShrinkCol>
                                                    <button *ngIf="!isEditing$.value"
                                                            mat-raised-button
                                                            mat-icon-button
                                                            [disabled]="!canEdit()"
                                                            (click)="activityEditClicked(stage, activity)">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>
                                                    <button *ngIf="isEditing$.value && canRemoveActivity(activity)"
                                                            mat-raised-button
                                                            mat-icon-button
                                                            [disabled]="!canEdit()"
                                                            (click)="removeActivityClicked(stage, activity)">
                                                        <mat-icon>remove_circle</mat-icon>
                                                    </button>
                                                    <button *ngIf="isEditing$.value && !canRemoveActivity(activity)"
                                                            mat-raised-button
                                                            mat-icon-button
                                                            [disabled]="true">
                                                        <mat-icon>lock</mat-icon>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr *ngIf="!stage.activities || !stage.activities.length"
                                                cbTableRow>
                                                <td colspan="5">
                                                    <mat-icon class="mat-accent">info</mat-icon>
                                                    &nbsp;
                                                    <span *ngIf="!isEditing$.value"
                                                          class="mat-body-1">
                                                        There are no activities in this stage.</span>
                                                    <span *ngIf="isEditing$.value"
                                                          class="mat-body-1">
                                                        To get started, please select the Activity tab on the right, and
                                                        drag one or more Build Activities from the list into this
                                                        Stage.</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </cb-table>
                                </mat-expansion-panel>
                                <mat-card *ngIf="!template.stages || !template.stages.length">There are no items to
                                    display</mat-card>
                            </div>
                        </div>
                        <mat-card *ngIf="isEditing$.value"
                                  class="cb-no-padding flex-34">
                            <mat-tab-group class = "flex">
                                <mat-tab label="Stages">
                                    <ng-template [matTabContent]>
                                        <div class="scrollable-container cb-padding">
                                            <small>
                                                <strong>Drag Stages to the Template</strong>
                                            </small>
                                            <cb-table [loaded]="true"
                                                      class="cb-margin-top cb-margin-bottom-2x">
                                                <thead cbTableHead>
                                                    <th>Code</th>
                                                    <th>Stage</th>
                                                </thead>
                                                <tbody cbTableBody
                                                       dragula="BUILD_STAGES"
                                                       [(dragulaModel)]="availableStages">
                                                    <tr *ngFor="let stage of availableStages"
                                                        cbTableRow
                                                        class="dragable dragula-stage">
                                                        <cb-td-text [value]="stage.code"
                                                                    class="dragula-stage-handler"></cb-td-text>
                                                        <cb-td-text [value]="stage.label"
                                                                    class="dragula-stage-handler"></cb-td-text>
                                                    </tr>
                                                </tbody>
                                            </cb-table>
                                        </div>
                                    </ng-template>
                                </mat-tab>
                                <mat-tab label="Activities">
                                    <ng-template [matTabContent]>
                                        <div class="scrollable-container cb-padding">
                                            <div>
                                                <small>
                                                    <strong>Drag Activities to Stages in the Template</strong>
                                                </small>
                                            </div>
                                            <form class="cb-margin-top">
                                                <cb-input label="Filter Activities"
                                                          name="activityFilter"
                                                          [ngModelOptions]="{standalone: true}"
                                                          [(ngModel)]="activityFilter"
                                                          (change)="setAvailableActivities()"></cb-input>
                                            </form>
                                            <cb-table [loaded]="true"
                                                      class="cb-margin-bottom-2x">
                                                <thead cbTableHead>
                                                    <th>Code</th>
                                                    <th>Activity</th>
                                                </thead>
                                                <tbody cbTableBody
                                                       dragula="BUILD_ACTIVITIES"
                                                       [dragulaModel]="availableActivities">
                                                    <tr *ngFor="let activity of availableActivities"
                                                        cbTableRow
                                                        class="dragula-activity">
                                                        <cb-td-text [value]="activity.code"></cb-td-text>
                                                        <cb-td-text [value]="activity.name"></cb-td-text>
                                                    </tr>
                                                </tbody>
                                            </cb-table>
                                        </div>
                                    </ng-template>
                                </mat-tab>
                            </mat-tab-group>
                        </mat-card>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab label="History"
                     [disabled]="isEditing$.value">
                <ng-template [matTabContent]>
                    <cb-history-list [entityId]="templateId"
                                     entity="buildprogrammetemplate">
                    </cb-history-list>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
    <cb-build-programme-template-side-panel *ngIf="template"
                                            class="cb-margin"
                                            [template]="template"
                                            [canEdit]="canEdit()"
                                            [isEditing$]="isEditing$"
                                            [refresh$]="refresh$"
                                            (saveClick)="saveClicked()">
    </cb-build-programme-template-side-panel>
</div>
