<mat-card class="list-card flex-col flex"
          style="overflow: visible">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon">
            <mat-icon class="cb-primary"
                      *ngIf="mappedItem?.stepHouseAndLandComplete">
                check_circle
            </mat-icon>
            <mat-icon class="cb-warn"
                      *ngIf="!mappedItem?.stepHouseAndLandComplete">
                warning
            </mat-icon>
        </div>
        <mat-card-title>2. Lot Interests</mat-card-title>
    </mat-card-header>
    <mat-card-content class="start-start flex-col flex">
        <ng-container *ngIf="mappedItem?.interests?.length > 0">
            <div class="flex-col"
                 *ngFor="let interest of sortedMappedItemInterests; let last = last;"
                 style="width: 100%;">
                <div position="relative">
                    <mat-divider></mat-divider>
                </div>
                <div class="start-center flex-row flex"
                     style="padding: 16px 0">
                    <mat-icon *ngIf="interest.isPrimary && (!interest.lotIsNotAvailable || interest.isQualified)"
                              title="Primary Lot Interest"
                              class="cb-accent">stars</mat-icon>
                    <mat-icon *ngIf="!interest.isPrimary && (!interest.lotIsNotAvailable || interest.isQualified)">
                        radio_button_unchecked
                    </mat-icon>
                    <mat-icon *ngIf="interest.lotIsNotAvailable && !interest.isQualified"
                              class="cb-warn">
                        warning
                    </mat-icon>
                    <div class="flex-row flex"
                         style="margin-left: 12px; width: max-content;">
                        <div class="flex-col"
                             style="width: max-content;">
                            <span class="mat-body-2">
                                Lot {{interest.lot.lotNumber}}
                            </span>
                            <span class="mat-body-2"
                                  *ngIf="interest.lot.marketStatus == marketStatusEnum.PreRelease">
                                Lot {{interest.lot.lotNumber}} ({{MARKET_STATUS_ENUM[interest.lot.marketStatus]}})
                            </span>
                            <div>
                                <span class="mat-body-2">{{interest.lot.projectName}},</span>
                                <span class="mat-body-1">&nbsp;{{interest.lot.address.city}}</span>
                            </div>
                            <span class="mat-body-1"
                                  *ngIf="interest.lotIsNotAvailable && !interest.isQualified">
                                {{interest.lot.projectName}}&nbsp;-&nbsp;No longer
                                Available
                            </span>
                        </div>
                    </div>
                    <span class="flex"></span>
                    <button [matMenuTriggerFor]="menu"
                            mat-raised-button
                            mat-icon-button>
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                                *ngIf="canSetLotInterestAsMain() && !interest.isPrimary"
                                (cbClick)="setPrimaryLot($event, interest)">
                            <mat-icon>stars</mat-icon>
                            &nbsp;
                            Set as Primary
                        </button>
                        <button *ngIf="(isHouseAndLand || isDesignAndLand) && canRemoveLotInterest() && !interest.isPrimary"
                                mat-menu-item
                                (cbClick)="removeLotInterest($event, interest)">
                            <mat-icon>remove_circle</mat-icon>
                            &nbsp;
                            Remove
                        </button>
                        <button mat-menu-item
                                (cbClick)="viewLot($event, interest)">
                            <mat-icon>pageview</mat-icon>
                            &nbsp;View
                        </button>
                    </mat-menu>
                </div>
                <div position="relative"
                     *ngIf="last">
                    <mat-divider></mat-divider>
                </div>
            </div>
        </ng-container>
    </mat-card-content>
    <div position="relative"
         *ngIf="canEdit && !this.mappedItem.isQualified()">
        <mat-divider></mat-divider>
    </div>
    <mat-card-actions class="end-center flex-row"
                      *ngIf="canEdit && !this.mappedItem.isQualified()">
        <button mat-stroked-button
                color="accent"
                *ngIf="canAddLotInterest()"
                (cbClick)="openAddLotDialog($event)">
            <mat-icon>add</mat-icon>
            &nbsp;Add Lot Interest
        </button>
    </mat-card-actions>
</mat-card>
