<cb-dialog dialogHeading="{{data.task.id > 0 ? 'Edit' : 'Add'}} Task">
    <form class="flex-col flex"
          #taskForm="ngForm">
        <cb-select label="Task Type"
                   [(ngModel)]="data.task.taskType"
                   name="taskType"
                   [options]="taskTypes"
                   [required]="true"></cb-select>
        <cb-select *ngIf="!data.isProjectRelease"
                   label="Assigned Team"
                   name="assignedTeam"
                   [(ngModel)]="data.task.assignedToTeamId"
                   (ngModelChange)="loadUsers()"
                   [options]="teams | async"
                   [required]="!data.task.assignedToId"></cb-select>
        <mat-form-field>
            <mat-label>Assigned User</mat-label>
            <mat-select [(ngModel)]="data.task.assignedToId"
                        name="assignedUser"
                        [required]="userRequired.value">
                <mat-option *ngFor="let user of users | async"
                            [value]="user.id">
                    {{user.firstName}} {{user.lastName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <cb-input type="text"
                  name="taskDialogSubject"
                  label="Subject"
                  maxlength="100"
                  [required]="true"
                  [(ngModel)]="data.task.subject"></cb-input>
        <cb-input *ngIf="data.task.isMeeting"
                  type="text"
                  name="taskDialogLocation"
                  label="Location"
                  maxlength="100"
                  [required]="true"
                  [(ngModel)]="data.task.location"></cb-input>
        <mat-form-field class="flex">
            <textarea matInput
                      name="task-dialog-comments"
                      type="text"
                      placeholder="Comments"
                      maxlength="1000"
                      [(ngModel)]="data.task.comments"></textarea>
        </mat-form-field>
        <cb-datepicker name="taskDialogDueDate"
                       [(ngModel)]="data.task.dueDate"
                       [required]="true"
                       label="Choose a date"></cb-datepicker>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="!taskForm.dirty || !taskForm.valid"
                (click)="saveItem()">{{ data.task.id > 0 ? 'Save' : 'Add' }}</button>
        <button class="raised"
                mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
