<form #searchForm="ngForm">
    <mat-card class="start flex-col"
              infiniteScroll
              [infiniteScrollDistance]="1"
              [fromRoot]="true"
              [infiniteScrollContainer]="'div#mainContainer'"
              (scrolled)="doSearch()">
        <div class="end-center flex-row">
            <mat-icon>search</mat-icon>
            <mat-form-field class="cb-margin-left cb-margin-right-2x flex">
                <input matInput
                       type="text"
                       name="search"
                       placeholder="Search"
                       [(ngModel)]="this.userCacheService.standardPlansSearch.data.query"
                       (ngModelChange)="this.searchQueryUpdate.next($event)"/>
            </mat-form-field>
            <div class="cb-margin-right-2x end-center flex-row">
                <button mat-raised-button
                        mat-icon-button
                        class="cb-margin-right-2x"
                        [disabled]="false"
                        [matMenuTriggerFor]="menu">
                    <mat-icon>filter_list</mat-icon>
                </button>
            </div>
            <mat-menu #menu="matMenu">
                <cb-standard-plan-search-filter *ngIf="this.costsAreasLoaded && this.paramsSetupComplete"
                                                (click)="$event.stopPropagation()"
                                                (emitEvent)="this.doSearch()"
                                                [(standardPlans)]="this.standardPlans"
                                                [costs]="this.costs"
                                                [areas]="this.areas"
                                                [(searchData)]="this.userCacheService.standardPlansSearch.data">
                </cb-standard-plan-search-filter>
            </mat-menu>
            <button mat-raised-button
                    mat-icon-button
                    (click)="this.addStandardPlan()">
                <mat-icon>add_circle</mat-icon>
            </button>
        </div>
        <mat-form-field *ngIf="this.paramsSetupComplete && this.anyFilterApplied()"
                        class="flex padding-fix">
            <mat-chip-list>
                <mat-chip *ngIf="this.searchParams.query !== null && this.searchParams.query !== ''"
                          (removed)="remove('query')">
                    Query : {{this.searchParams.query}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="this.searchParams.numberOfBedrooms > 0"
                          (removed)="remove('numberOfBedrooms')">
                    Bedrooms : {{this.searchParams.numberOfBedrooms}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="this.searchParams.numberOfBathrooms > 0"
                          (removed)="remove('numberOfBathrooms')">
                    Bathrooms : {{this.searchParams.numberOfBathrooms}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="this.searchParams.numberOfLivingRooms > 0"
                          (removed)="remove('numberOfLivingRooms')">
                    Living : {{this.searchParams.numberOfLivingRooms}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="this.searchParams.numberOfGarages > 0"
                          (removed)="remove('numberOfGarages')">
                    Garage : {{this.searchParams.numberOfGarages}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="this.showMinCostChip()"
                          (removed)="remove('costToBuild[0]')">
                    Min Cost : ${{this.searchParams.costToBuild[0]}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="this.showMaxCostChip()"
                          (removed)="remove('costToBuild[1]')">
                    Max Cost : ${{this.searchParams.costToBuild[1]}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="this.showMinAreaChip()"
                          (removed)="remove('floorArea[0]')">
                    Min Area : {{this.searchParams.floorArea[0]}}m²
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="this.showMaxAreaChip()"
                          (removed)="remove('floorArea[1]')">
                    Max Area : {{this.searchParams.floorArea[1]}}m²
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <div class="flex"></div>
                <button mat-stroked-button
                        style="border-radius: 16px;"
                        (click)="this.clearAll()">
                    <mat-icon>clear_all</mat-icon>
                    &nbsp;Clear Filter
                </button>
            </mat-chip-list>
        </mat-form-field>
    </mat-card>
</form>
