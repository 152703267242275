import {Component, EventEmitter, Inject} from '@angular/core';
import {FormMode} from '@app/shared/enums/form';
import {IStandardPlanMappedItem} from '@app/logic/standard-plans/interfaces/i.standard-plan.mapped';
import {IStandardPlanStyleDto} from '@app/logic/styles/interfaces/i.style.dto';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {StandardPlanCostLogicService} from '@app/logic/standard-plans/standard-plan-cost.logic.service';
import {UploadImageDialogComponent} from '../manage-image/upload-image-dialog/upload-image-dialog.component';
import {ViewImageComponent} from '@app/shared/components/image-handlers/view-image/view-image.component';
import {StandardPlanLogicService} from '@app/logic/standard-plans';
import {ComputedProperty} from '@app/shared/utils/computed-property.util';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {IAddressRegionDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-standard-plan-dialog',
    templateUrl: './standard-plan-dialog.html',
    styleUrls: ['./standard-plan-dialog.scss']
})
export class StandardPlanDialogComponent {
    public static readonly MIN_WIDTH = '400px';

    private dataIsCloned = false;
    public onSave = new EventEmitter();

    public Object = Object;

    public filteredPlans = new ComputedProperty(
        () => this.data.standardPlans
    );

    public isView(): boolean {
        return this.data.formMode === FormMode.View;
    }

    public isAdd(): boolean {
        return this.data.formMode === FormMode.Add;
    }

    public isEdit(): boolean {
        return this.data.formMode === FormMode.Edit;
    }

    constructor(
        @Inject(CbDialogService) public readonly dialog: CbDialogService,
        public readonly dialogRef: MatDialogRef<StandardPlanDialogComponent>,
        public readonly standardPlanCostLogicService: StandardPlanCostLogicService,
        @Inject(MAT_DIALOG_DATA) public data: IData,
        private readonly snackBar: MatSnackBar,
        @Inject(StandardPlanLogicService) public readonly standardPlanLogic: StandardPlanLogicService,
    ) {
    }

    public viewImage(style: IStandardPlanStyleDto): void {
        this.dialog.open(ViewImageComponent,
            {
                data: {
                    name: style.name,
                    url: style.url
                },
                panelClass: 'cb-full-width-dialog'
            }
        );
    }

    public uploadImage(style: IStandardPlanStyleDto): void {
        this.dialog
            .open(
                UploadImageDialogComponent,
                {
                    data: {
                        plan: this.data.plan,
                        style
                    },
                    minWidth: 400,
                    width: '600px',
                }
            );
    }

    public edit(): void {
        this.data.formMode = FormMode.Edit;
        if (!this.dataIsCloned) {
            this.data.plan = this.data.plan.$clone();
            this.dataIsCloned = true;
        }

    }

    public getDialogHeader(): string {
        if (this.isEdit()) {
            return `Edit ${this.data.plan.name}`;
        } else if (this.isView()) {
            return `View ${this.data.plan.name}`;
        } else if (this.isAdd()) {
            return 'Add Standard Plan ';
        }
    }

    public close(): void {
        this.dialogRef.close(false);
    }

    public cancel(): void {
        if (this.isEdit()) {
            this.data.formMode = FormMode.View;
            this.data.plan = this.data.plan.$original;
            this.dataIsCloned = false;
        } else {
            this.dialogRef.close(false);
        }
    }

    public duplicate(): void {
        this.standardPlanLogic.loadStandardPlanStyles();
        const mappedItem = this.standardPlanLogic.$createMappedItem();

        mappedItem.floorArea = this.data.plan.floorArea;
        mappedItem.isActive = this.data.plan.isActive;
        mappedItem.isPublished = this.data.plan.isPublished;
        mappedItem.m2CostToBuild = this.data.plan.m2CostToBuild;
        mappedItem.name = `Duplicate of ${this.data.plan.name}`;

        mappedItem.amenities = this.data.plan.amenities;

        this.close();

        this.dialog.open(StandardPlanDialogComponent, {
            data: { plan: mappedItem, formMode: FormMode.Add, addressRegions: this.data.addressRegions },
            panelClass: 'cb-dialog-container',
            minWidth: 400,
            width: '600px',
        })
            .afterClosed()
            .subOnce(plan => {
                if (plan) {
                    this.data.standardPlans.unshift(plan);
                    this.filteredPlans.recompute();
                }
            });

    }

    public deleteStandardPlan(): void {
        this.dialog.confirm({
            message: `Are you sure you want to delete this Standard Plan ${this.data.plan.name}?`,
            confirmed: this.handleDeleteStandardPlan,
        });
    }

    private readonly handleDeleteStandardPlan = (): void => {
        this.data.plan.$logicService.deleteStandardPlan(this.data.plan.id)
            .subOnce(plan => {
                this.close();
                const _standardPlans = this.data.standardPlans.filter(obj => obj.id !== plan.id);
                this.onSave.emit(_standardPlans);
            });
    };

    public deleteImage(style: IStandardPlanStyleDto): void {
        const imageId = this.data.plan.images.find(x => x.styleId === style.id).id;

        this.data.plan.$logicService.deletePlanImage(imageId, this.data.plan.id).subOnce(plan => {
            this.data.plan.$save()
                .subOnce(() => {
                    this.openSnackBar('Image Deleted', 'OK');
                });
        });
    }

    public save(): void {
        this.data.plan.$save()
            .subOnce(plan => {
                this.dialogRef.close(this.data.plan);
            });
    }

    private openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['snackbar']
        });
    }

}

export interface IData {
    standardPlans: IStandardPlanMappedItem[];
    plan: IStandardPlanMappedItem;
    formMode: FormMode;
    addressRegions: IAddressRegionDto[];
}

