<cb-user-profile [sidenav]="sidenav"></cb-user-profile>
<mat-nav-list>
    <mat-list-item (click)="navigateHome()">Dashboard</mat-list-item>
    <mat-list-item *ngIf="canSeeSalesDashboard()"
                   (click)="navigate('/building-consultant-dashboard', $event)">Building Consultant Dashboard</mat-list-item>
    <mat-list-item *ngIf="canSeeDesignTeamComponent()"
                   (click)="navigate('/teams/design', $event)">
        Design Team
    </mat-list-item>
    <mat-list-item *ngIf="canSeeQSTeamComponent()"
                   (click)="navigate('/teams/qs', $event)">
        QS Team
    </mat-list-item>
    <mat-list-item *ngIf="canSeeAllTeamsComponent()"
                   (click)="navigate('/teams/all', $event)">
        All Teams
    </mat-list-item>
    <mat-list-item *ngIf="canSeeAllTeamsComponent()"
                   (click)="navigate('/purchase-orders', $event)">Purchase Order Search</mat-list-item>
    <mat-divider></mat-divider>
    <mat-accordion multi="true">
        <mat-expansion-panel expanded="true"
                             class="mat-elevation-z0 cb-panel">
            <mat-expansion-panel-header>
                <strong>CRM</strong>
            </mat-expansion-panel-header>
            <mat-nav-list class="cb-panel-list">
                <mat-list-item *ngIf="canSeeContactComponent()"
                               (click)="navigate('/contacts', $event)">Contact Search</mat-list-item>
                <mat-list-item *ngIf="canSeeLeadComponent()"
                               (click)="navigate('/leads/search', $event)">Lead Search</mat-list-item>
                <mat-list-item *ngIf="canSeeMyLeadsComponent()"
                               (click)="navigate('/leads', $event)">My Leads</mat-list-item>
                <mat-list-item *ngIf="canSeeContactComponent()"
                               (click)="navigate('/accounts', $event)">Client Account Search</mat-list-item>
                <mat-list-item *ngIf="canSeeClientSaleComponent()"
                               (click)="navigate('/clientsales/search', $event)">Client Sale Search</mat-list-item>
                <mat-list-item *ngIf="canSeeMyClientSalesComponent()"
                               (click)="navigate('/clientsales', $event)">My Client Sales</mat-list-item>
                <mat-list-item *ngIf="canSeeHouseLandComponent()"
                               (click)="navigate('/house-and-land', $event)"> House & Land Search
                </mat-list-item>

                <mat-list-item *ngIf="canSeeStandardPlanComponent()"
                               (click)="navigate('/standard-plans', $event)">Standard Plans
                </mat-list-item>
            </mat-nav-list>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-divider></mat-divider>
    <mat-accordion multi="true">
        <mat-expansion-panel expanded="true"
                             class="mat-elevation-z0 cb-panel">
            <mat-expansion-panel-header>
                <strong>Build</strong>
            </mat-expansion-panel-header>
            <mat-nav-list class="cb-panel-list">
                <mat-list-item *ngIf="canSeeLotComponent()"
                               (click)="navigate('/lots', $event)">Lot Search
                </mat-list-item>

                <mat-list-item *ngIf="canSeeLotComponent()"
                               (click)="navigate('/temp-lots', $event)">Temp Lots
                </mat-list-item>
                <mat-list-item *ngIf="canSeeProjectComponent()"
                               (click)="navigate('/projects/search', $event)">Project Search
                </mat-list-item>
                <mat-list-item *ngIf="canSeeSpecificationTemplateComponent()"
                               (click)="navigate('/spec-templates', $event)">Spec Templates
                </mat-list-item>
                <mat-list-item *ngIf="canSeeBuildingProgrammeTemplateComponent()"
                               (click)="navigate('/build-programme-templates', $event)">Build Programme Templates
                </mat-list-item>
                <mat-list-item *ngIf="canGenerateBusinessAccountContract()"
                               (click)="navigate('/contract-generation', $event)">Contract Generation
                </mat-list-item>
            </mat-nav-list>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-divider></mat-divider>
    <mat-accordion multi="true">
        <mat-expansion-panel expanded="true"
                             class="mat-elevation-z0 cb-panel">
            <mat-expansion-panel-header>
                <strong>Products</strong>
            </mat-expansion-panel-header>
            <mat-nav-list class="cb-panel-list">
                <mat-list-item *ngIf="canSeeBusinessAccountComponent()"
                               (click)="navigate('/business-accounts', $event)">Business Accounts
                </mat-list-item>
                <mat-list-item *ngIf="canSeeProductComponent()"
                               (click)="navigate('/products', $event)">Product Catalogue
                </mat-list-item>
            </mat-nav-list>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-divider></mat-divider>
    <mat-accordion multi="true">
        <mat-expansion-panel expanded="true"
                             class="mat-elevation-z0 cb-panel">
            <mat-expansion-panel-header>
                <strong>Security &amp; Settings</strong>
            </mat-expansion-panel-header>
            <mat-nav-list class="cb-panel-list">
                <mat-list-item *ngIf="canSeeSettingsComponent()"
                               (click)="navigate('/settings', $event)">Settings</mat-list-item>
                <mat-list-item *ngIf="canSeeUsersComponent()"
                               (click)="navigate('/users/search', $event)">User Search</mat-list-item>
                <mat-list-item *ngIf="canSeeRolesComponent()"
                               (click)="navigate('/security-roles', $event)">Roles</mat-list-item>
                <mat-list-item *ngIf="canSeeConnectionsComponent()"
                               (click)="navigate('/connections', $event)">Connections</mat-list-item>
            </mat-nav-list>
        </mat-expansion-panel>
    </mat-accordion>
</mat-nav-list>