<mat-card class="cb-margin">
    <mat-card-header>
        <div class="start-start flex-row flex">
            <div class="cb-margin-right-2x flex-row"
                 style="margin-top: 5px;">
                <mat-icon [ngClass]="getStatusIconClass()"
                          class="help-cursor"
                          [matTooltip]="getStatusIconTitle()">{{getStatusIconIcon()}}</mat-icon>
            </div>
            <span class="mat-title">{{mappedItem.name}}</span>
            <span class="flex"></span>
            <mat-icon class="help-cursor mat-primary"
                      matTooltip="Applied to Lot"
                      svgIcon="bookmark-check"
                      *ngIf="mappedItem.designSchemeDocumentDto?.isConceptAppliedToLot">
            </mat-icon>
            <mat-icon class="help-cursor mat-warn"
                      matTooltip="Has unanswered question"
                      svgIcon="comment-question-outline"
                      *ngIf="mappedItem.hasUnansweredQuestion">
            </mat-icon>
        </div>
    </mat-card-header>
    <mat-card-content class="cb-margin-bottom-nil">
        <div class="flex-col">
            <cb-display-value-new label="Prepared by"
                                  [value]="mappedItem.createdByUserName">
            </cb-display-value-new>
            <cb-user-date-time-new label="Created"
                                   [floatingLabel]="true"
                                   [date]="mappedItem.createdDate">
            </cb-user-date-time-new>
            <div class="flex-row flex">
                <cb-display-value-new class="flex"
                                      label="Assigned Changes"
                                      [value]="mappedItem.designSchemeDocumentDto?.numberOfAssignedChanges || 0">
                </cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Design Complexity"
                                      [value]="mappedItem.designComplexity?.complexity || mappedItem.designSchemeDocumentDto?.complexityCode">
                </cb-display-value-new>
            </div>
            <div class="flex-row flex">
                <cb-display-value-new class="flex"
                                      label="Assigned To"
                                      [value]="mappedItem.assignedToUserName">
                </cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Reviewer"
                                      [value]="mappedItem.reviewerName">
                </cb-display-value-new>
            </div>
            <div class="flex-row flex">
                <cb-display-value-new class="flex"
                                      label="Status"
                                      [value]="mappedItem.status">
                </cb-display-value-new>
                <cb-currency class="flex"
                             label="Client Budget"
                             name="clientBudget"
                             [(ngModel)]="mappedItem.clientBudget"
                             [inclGst]="true"
                             [readonly]="true">
                </cb-currency>
            </div>
        </div>
    </mat-card-content>
    <mat-divider inset="true"></mat-divider>
    <mat-card-actions>
        <div class="flex-row">
            <button mat-stroked-button
                    *ngIf="mappedItem.canCompleteSearchItem"
                    color="accent"
                    (click)="completeScheme()">
                Review
            </button>
            <cb-button [disabled]="!(canRequestPricing$ | async)"
                       (clicked)="requestPricing()"
                       *ngIf="canRequestPricing()"
                       (popupClick)="goToLotSpecification()"
                       color="accent"
                       [isStroked]="true"
                       [popup]="{
                            description: 'There are items on your Lot Specification that are missing information. Please check all your Lot Spec Items have a Cost Type and Product Name/Description',
                            buttonText: 'Go to Lot Specification',
                            horizontalPosition: 'right',
                            verticalPosition: 'top',
                            cssPosition: 'absolute'
                        }">
                Request Pricing
            </cb-button>
            <span class="flex"></span>
            <button mat-stroked-button
                    color="accent"
                    (click)="viewDesignScheme()">
                View
            </button>
        </div>
    </mat-card-actions>
</mat-card>
