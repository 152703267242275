<div class="flex-row flex"
     style="overflow-x: auto">
    <div class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex-100">
        <div class="start-center flex-row">
            <h3 class="mat-title flex">
                Maintenance
            </h3>
        </div>
        <cb-table [loaded]="true"
                  [columns]="columns">
            <tr cbTableRow>
                <cb-td-text [value]="'All'"></cb-td-text>
                <td cbTableStickyCol="end"
                    cbTableShrinkCol>
                    <button mat-raised-button
                            mat-icon-button
                            [disabled]="isRunAllDisabled()"
                            (click)="onRunAllClicked()">
                        <mat-icon>
                            cached
                        </mat-icon>
                    </button>
                </td>
            </tr>
            <tr cbTableRow
                *ngFor="let maintenanceItem of maintenanceItems">
                <cb-td-text [value]="maintenanceItem.label"></cb-td-text>
                <td cbTableStickyCol="end"
                    cbTableShrinkCol>
                    <button mat-raised-button
                            mat-icon-button
                            [disabled]="maintenanceItem.isRunning"
                            (click)="onItemClicked(maintenanceItem)">
                        <mat-icon>
                            cached
                        </mat-icon>
                    </button>
                </td>
            </tr>
        </cb-table>
    </div>
</div>
