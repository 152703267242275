import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatNativeDateModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {CbClickModule} from '@app/shared/directives/click/click.module';
import {CbFillHeightModule} from '@app/shared/directives/fill-height/fill-height.module';
import {CbWaitForModule} from '@app/shared/directives/wait-for/wait-for.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {CbFilterChipsModule} from '@app/shared/components/filter-chips/filter-chips.module';
import {CbDisplayValueModule} from '@app/shared/components/display-value/display-value.module';
import {CbUserDateTimeModule} from '@app/shared/components/user-date-time/user-date-time.module';
import {CbCallbackModule} from '@app/shared/pipe/callback/callback.module';
import {ContactsModule} from '../contacts/contacts.module';
import {CbTasksModule} from '@app/shared/components/tasks/tasks.module';
import {CbNotesModule} from '@app/shared/components/notes/notes.module';
import {CbDocumentsModule} from '@app/shared/components/documents/documents.module';
import {CbHistoryModule} from '@app/shared/components/history/history.module';
import {CbImageButtonModule} from '@app/shared/components/image-handlers/image-button/image-button.module';
import {LotBuildDocumentsModule} from '../lot/build/documents/documents.module';
import {ViewHouseLandDialogComponent} from './dialogs';
import {ViewHouseLandDetailsComponent} from './view-house-land-details';
import {ViewHouseLandSpecificationComponent} from './view-house-land-specification';
import {CbLoadingSpinnerModule, CbTableModule} from 'cb-hub-lib';
import {MatExpansionModule} from '@angular/material/expansion';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {ViewLotInterestsDialogComponent} from './dialogs/view-lot-interests-dialog/view-lot-interests-dialog.component';
import {AddHouseLandLeadDialogComponent} from './dialogs/add-house-land-lead-dialog/add-house-land-lead-dialog.component';
import {CbCurrencyModule} from '@app/shared/pipe/currency/currency.module';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
    declarations: [
        ViewHouseLandDialogComponent,
        ViewHouseLandDetailsComponent,
        ViewHouseLandSpecificationComponent,
        ViewLotInterestsDialogComponent,
        AddHouseLandLeadDialogComponent
    ],
    exports: [
        ViewHouseLandDialogComponent,
        ViewLotInterestsDialogComponent,
        AddHouseLandLeadDialogComponent
    ],
    imports: [
        InfiniteScrollModule,
        CommonModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MatButtonModule,
        CbDialogModule,
        CbFormsModule,
        CbClickModule,
        CbFillHeightModule,
        CbWaitForModule,
        CbFilterChipsModule,
        CbDisplayValueModule,
        CbUserDateTimeModule,
        CbCallbackModule,
        ContactsModule,
        CbTasksModule,
        CbNotesModule,
        CbDocumentsModule,
        CbHistoryModule,
        CbImageButtonModule,
        CbLoadingSpinnerModule,
        MatExpansionModule,
        LotBuildDocumentsModule,
        CbHubTableModule,
        CbTableModule,
        MatDividerModule,
        CbCurrencyModule
    ]
})

export class CbHouseLandModule { }
