<cb-dialog>
    <form #form="ngForm">
        <mat-label>Type of Hold</mat-label>
        <cb-checkbox *ngFor="let hold of availableHolds"
                     [name]="'tradeType'+hold.holdAction+'-'+hold.holdType"
                     [label]="hold.label"
                     [ngModel]="isAccountHoldChecked(hold.holdAction, hold.holdType)"
                     (change)="onAccountHoldChanged(hold.holdAction, hold.holdType, $event)"></cb-checkbox>
        <cb-text-area label="Description"
                      name="description"
                      [maxlength]="1000"
                      [required]="true"
                      [(ngModel)]="description"></cb-text-area>
    </form>
    <footer class="center-center flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                [disabled]="!form.dirty || !form.valid || !canSave()"
                (click)="onSaveClicked()">Save</button>
        <button mat-raised-button
                (click)="dialogRef.close()">Cancel</button>
    </footer>
</cb-dialog>
