<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <mat-card-title>{{dto.tradingName}}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex">
        <ng-container>
            <div class="flex-col">
                <cb-display-value-new label="Legal Name">{{dto.legalName}}</cb-display-value-new>
                <cb-display-value-new label="Account Type">
                    {{getAccountType()}}
                </cb-display-value-new>
                <cb-display-value-new label="ABM Code">{{dto.abmCode}}</cb-display-value-new>
                <cb-display-value-new label="Locations">{{transposeRegions(dto.regions)}}</cb-display-value-new>
            </div>
        </ng-container>
    </mat-card-content>
    <mat-card-actions class="end-center flex-row">
        <button mat-stroked-button
                *ngIf="cardType !== 'addAccount'">
            <mat-icon>pageview</mat-icon>
            &nbsp;View
        </button>
        <button mat-stroked-button
                color="accent"
                *ngIf="cardType === 'addAccount'"
                (click)="selectionChosen(dto)">
                Add Account
        </button>
    </mat-card-actions>
</mat-card>
