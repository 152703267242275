import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbLoadingSpinnerModule, CbSelectListModule } from 'cb-hub-lib';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { NgModule } from '@angular/core';
import { RevisionReportComponent } from './revision-report.component';

@NgModule({
    declarations: [
        RevisionReportComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatSelectModule,
        MatOptionModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatCardModule,
        MatCheckboxModule,
        InfiniteScrollModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbSelectListModule
    ],
    exports: [
        RevisionReportComponent
    ]
})

export class RevisionReportModule { }
