<cb-dialog>
    <form class="flex-col flex"
          #categoryForm="ngForm">
        <mat-dialog-content>
            <cb-input type="text"
                      name="name"
                      label="Category Name"
                      [required]="true"
                      [(ngModel)]="mappedItem.name"></cb-input>
            <cb-checkbox name="isActive"
                         label="Is Active"
                         [(ngModel)]="mappedItem.isActive"></cb-checkbox>
        </mat-dialog-content>
        <footer class="cb-margin-top-2x end-center flex-row">
            <button mat-raised-button
                    class="save-button"
                    color="primary"
                    (click)="save()"
                    [disabled]="!categoryForm.dirty || !categoryForm.valid || saveInProgress">
                Save
            </button>
            <button mat-raised-button
                    (click)="cancel()">
                Cancel
            </button>
        </footer>
    </form>
</cb-dialog>
