<mat-card class="top-panel button-row start-center flex-row">
    <button mat-raised-button
            (click)="goBackToList()">
        <mat-icon>arrow_back</mat-icon>
        Back to Consents
    </button>
    <button mat-raised-button
            *ngIf="buildingConsent?.isApproved"
            color="primary"
            (click)="editConsentNumber()">
        <mat-icon>edit</mat-icon>
        Edit Consent Number
    </button>
    <button mat-raised-button
            *ngIf="canEditConsent()"
            color="primary"
            (click)="editBuildingConsent()"
            [disabled]="buildingConsent?.isComplete">
        <mat-icon>edit</mat-icon>
        Manage Consent
    </button>
    <button *ngIf="canChangeConsentStatus()"
            mat-raised-button
            aria-label="Change Consent Status"
            color="primary"
            [matMenuTriggerFor]="menu"
            [disabled]="buildingConsent?.isComplete">
        <mat-icon mat-menu-origin>arrow_drop_down</mat-icon>
        Consent Status
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item
                (click)="setInQueue()"
                [disabled]="buildingConsent?.isComplete || !buildingConsent?.canSetInQueue">
            <mat-icon mat-menu-align-target
                      color="accent">timelapse</mat-icon>
            In Queue
        </button>
        <button mat-menu-item
                (click)="setInProgress()"
                [disabled]="!buildingConsent?.canSetInProgress">
            <mat-icon mat-menu-align-target
                      color="warn">check</mat-icon>
            In Progress
        </button>
        <button mat-menu-item
                (click)="setOnHold()"
                [disabled]="!buildingConsent?.canSetOnHold">
            <mat-icon mat-menu-align-target
                      color="primary">pause</mat-icon>
            On Hold
        </button>
        <button mat-menu-item
                (click)="setSubmittedToCouncil()"
                [disabled]="!buildingConsent?.canSetSubmitted">
            <mat-icon mat-menu-align-target
                      color="primary">send</mat-icon>
            Submitted to Council
        </button>
        <button mat-menu-item
                (click)="setCouncilReceived()"
                [disabled]="!buildingConsent?.canSetCouncilReceived">
            <mat-icon mat-menu-align-target
                      color="primary">inbox</mat-icon>
            Council Received
        </button>
        <button mat-menu-item
                (click)="grantConsent()"
                [disabled]="buildingConsent?.isComplete || !hasBuildingConsentDocument || buildingConsent.hasUnresolvedRfi">
            <mat-icon mat-menu-align-target
                      color="primary">check</mat-icon>
            Consent Granted
        </button>
        <button mat-menu-item
                (click)="denyConsent()"
                [disabled]="buildingConsent?.isComplete">
            <mat-icon mat-menu-align-target
                      color="warn">error</mat-icon>
            Consent Denied
        </button>
    </mat-menu>
    <button mat-raised-button
            *ngIf="canEditConsent()"
            color="primary"
            (click)="assignBuildingConsent()"
            [disabled]="buildingConsent?.isComplete">
        <mat-icon>person</mat-icon>
        Reassign
    </button>
    <button mat-raised-button
            color="primary"
            *ngIf="canDeleteConsent()"
            [disabled]="buildingConsent?.isComplete"
            (click)="deleteBuildingConsent()">
        <mat-icon>delete</mat-icon>
        Delete
    </button>
</mat-card>
