<button mat-button
        [color]="color"
        type="submit"
        (cbClick)="onClick($event)"
        [clickLimit]="clickLimit"
        [disabled]="disabled$ | async"
        [ngClass]="isStroked ? 'mat-stroked-button' : 'mat-raised-button'"
        *ngIf="!icon && !isMenuItem && !menu">
    <ng-content></ng-content>
    <cb-popup-tip icon="help"
                  iconColour="cb-accent"
                  [horizontalPosition]="popup.horizontalPosition"
                  [verticalPosition]="popup.verticalPosition"
                  [cssPosition]="popup.cssPosition"
                  *ngIf="popup && disabled">
        <div class="flex-col">
            <span class="mat-body-1 cb-margin"
                  style="white-space: normal;">
                {{popup.description}}
            </span>
            <button class="cb-margin-top"
                    mat-raised-button
                    color="primary"
                    [disabled]="false"
                    (cbClick)="onPopupClick($event)">
                {{popup.buttonText}}
            </button>
        </div>
    </cb-popup-tip>
</button>
<button *ngIf="icon && !isMenuItem && !buttonLabel"
        mat-icon-button
        mat-raised-button
        [color]="color"
        (click)="onClick($event)"
        [disabled]="disabled$ | async">
    <mat-icon [color]="getIconColor()"
              [ngClass]="getIconClass()">{{icon}}</mat-icon>
</button>
<button *ngIf="icon && buttonLabel"
        mat-button
        [color]="color"
        (click)="onClick($event)"
        [disabled]="disabled$ | async"
        [ngClass]="isStroked ? 'mat-stroked-button' : 'mat-raised-button'">
    <mat-icon [color]="getIconColor()"
              [ngClass]="getIconClass()">{{icon}}</mat-icon>
    {{buttonLabel}}
</button>
<button *ngIf="menu && !menuButtonLabel"
        [matMenuTriggerFor]="menu"
        mat-icon-button
        mat-raised-button
        (click)="onClick($event)"
        [disabled]="disabled$ | async">
    <mat-icon [color]="getIconColor()"
              [ngClass]="getIconClass()"
              mat-menu-origin>more_horiz</mat-icon>
</button>
<button *ngIf="menu && menuButtonLabel"
        [matMenuTriggerFor]="menu"
        mat-raised-button
        (click)="onClick($event)"
        [disabled]="disabled$ | async">
    <mat-icon [color]="getIconColor()"
              [ngClass]="getIconClass()"
              mat-menu-origin>arrow_drop_down</mat-icon>
    {{menuButtonLabel}}
</button>
<button *ngIf="isMenuItem"
        mat-menu-item
        [disabled]="disabled$ | async"
        (click)="onClick($event)">
    <mat-icon [color]="getIconColor()"
              [ngClass]="getIconClass()"
              mat-menuAlign-target>{{icon}}</mat-icon>
    {{menuItemLabel}}
</button>
