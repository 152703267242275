<cb-dialog>
    <form class="flex-col flex"
          #activityForm="ngForm">
        <span class="mat-title">{{getTitle()}}</span>
        <cb-input label="Activity Duration (Days)"
                  name="duration"
                  [required]="true"
                  [(ngModel)]="data.activity.activityDurationDays"></cb-input>
        <cb-checkbox label="Default to Block Level"
                     name="isControlledByParentLot"
                     class="cb-margin-top-2x"
                     [(ngModel)]="data.activity.isControlledByParentLot"></cb-checkbox>
        <cb-checkbox label="Is Horizontal Predecessor"
                     name="isActive"
                     class="cb-margin-top-2x"
                     [(ngModel)]="data.activity.isHorizontalPredecessor"></cb-checkbox>
        <mat-radio-group class="flex-col flex"
                         *ngIf="data.activity.isHorizontalPredecessor"
                         [(ngModel)]="data.activity.horizontalPredecessorDateCalcType"
                         [ngModelOptions]="{standalone: true}">
            <mat-radio-button *ngFor="let dateType of dateCalculationTypes"
                              class="mat-accent"
                              [value]="dateType.id">{{dateType.label}}
            </mat-radio-button>
        </mat-radio-group>
        <span class="mat-title cb-margin-top-2x">Predecessor Activities</span>
        <p>Activity Start Date will be set by the earliest matching Activity with "Set Start Date" set</p>
        <cb-table [loaded]="true"
                  [columns]="activityColumns"
                  class="cb-margin-top-2x cb-margin-bottom-2x">
            <tbody cbTableBody
                   dragula="PREDECESSOR"
                   [(dragulaModel)]="data.activity.relationships">
                <tr cbTableRow
                    *ngFor="let predecessor of data.activity.relationships"
                    class="dragula-allow">
                    <cb-td-text [value]="predecessor.predecessorName"
                                class="dragula-visible"></cb-td-text>
                    <cb-td-enum [value]="predecessor.dateCalcType"
                                [enum]="DATE_CALC_TYPE_ENUM"> </cb-td-enum>
                    <cb-td-icon [value]="predecessor.shouldSetStartBasedOnPredecessor ? 'check_box' : 'check_box_outline_blank'">
                    </cb-td-icon>
                    <cb-td-text [value]="predecessor.lagDays"></cb-td-text>
                    <td cbTableStickyCol="end"
                        cbTableShrinkCol>
                        <button mat-raised-button
                                mat-icon-button
                                class="cb-margin-right-2x"
                                (click)="openPredecessorDialog(predecessor)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-raised-button
                                mat-icon-button
                                (click)="predecessorRemoveClicked(predecessor)">
                            <mat-icon>remove_circle</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tr cbTableRow>
                <td colspan="5"
                    style="text-align: right; border-top: 1px solid #d0d0d0;">
                    <button mat-raised-button
                            mat-icon-button
                            (click)="openPredecessorDialog()">
                        <mat-icon>add</mat-icon>
                    </button>
                </td>
            </tr>
        </cb-table>
    </form>
    <footer class="flex-row flex center-center">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                [disabled]="!activityForm.valid"
                (click)="saveClicked()">Save</button>
        <button mat-raised-button
                (click)="dialogRef.close()">Cancel</button>
    </footer>
</cb-dialog>
