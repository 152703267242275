import { Component, Inject, Input } from '@angular/core';
import { AccountsReceivablePermissions } from '@app/core/permissions';
import { ARProgressPaymentsLogicService } from '@app/logic/ar-progress-payments/ar-progress-payments.logic-service';
import { ARVariationLogicService } from '@app/logic/ar-variation/ar-variation.logic-service';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { VariationLogicService } from '@app/logic/variation/variation.logic-service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { AREditProgressPaymentsDialogComponent } from '@app/views/lot/lot-ar/ar-edit-progresspayments-dialog.component/ar-edit-progresspayments-dialog.component';
import { AREditVariationDialogComponent } from '@app/views/lot/lot-ar/ar-edit-variation-dialog.component/ar-edit-variation-dialog.component';
import {
    ARViewProgressPaymentHistoryDialogComponent
} from '@app/views/lot/lot-ar/ar-view-progresspayment-history-dialog.component/ar-view-progresspayment-history-dialog.component';

import {
    BUILD_STAGE_EVENT_ENUM,
    IARPaymentScheduleDto,
    IARVariationDto,
    ILotProgressPaymentDto,
    VARIATION_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cb-lot-ar',
    templateUrl: './lot-ar.component.html',
    styleUrls: ['./lot-ar.component.scss']
})
export class LotArComponent {

    public BUILD_STAGE_EVENT_ENUM = BUILD_STAGE_EVENT_ENUM;
    public VARIATION_STATUS_ENUM = VARIATION_STATUS_ENUM;
    public progressPaymentDto: IARPaymentScheduleDto;
    public progressPaymentLoaded: boolean;
    public variations: IARVariationDto[];
    public variationTotals: number;
    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);

    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);
            this.loadProgressPayments(mappedItem.id);
            this.loadAcceptedVariations(mappedItem.id);
        }
    }

    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    public canEditPaymentSchedule = (): boolean => this.accountsReceivablePermissions && this.accountsReceivablePermissions.canView();

    public canEditProgressPayments = (): boolean => this.accountsReceivablePermissions.canEditPaymentSchedule();

    public lotIsUnconditional = (): boolean => this.lotMappedItem.hasUnconditionalContract;

    public lotHasProgressPayments = (): boolean => this.progressPaymentDto?.payments.length > 0;

    public get balance(): number {
        // eslint-disable-next-line max-len
        return (this.progressPaymentDto?.contractPriceWithGst) - (this.progressPaymentDto?.payments?.reduce((sum: number, b: ILotProgressPaymentDto) => sum + b.dollarValue, 0) ?? 0);
    }

    constructor(
        private readonly lotsLogicService: LotsLogicService,
        private readonly variationLogicService: VariationLogicService,
        private readonly arVariationLogicService: ARVariationLogicService,
        private readonly arProgressPaymentsLogicService: ARProgressPaymentsLogicService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        public readonly accountsReceivablePermissions: AccountsReceivablePermissions,
    ) { }

    public getEditPaymentIconTitle(): string {
        if (!this.canEditProgressPayments()){
            return 'Insufficient permissions to edit payments';
        } else if (!this.lotIsUnconditional()){
            return 'Cant edit payments. ClientSale is not "Unconditional"';
        } else {
            return '';
        }
    }

    public exportARInformation(): void {
        this.lotsLogicService.generateARReport(this.lotMappedItem.id).subOnce();
    }

    public editProgressPayments(): void {
        if (!this.canEditPaymentSchedule()){
            return;
        }

        const paymentsMappedItem = this.arProgressPaymentsLogicService.$createMappedItem(this.progressPaymentDto);
        this.cbDialog.open(
            AREditProgressPaymentsDialogComponent,
            {
                data: {
                    mappedItem: paymentsMappedItem.$clone(),
                    lotMappedItem: this.lotMappedItem.$clone()
                },
                minWidth: AREditProgressPaymentsDialogComponent.MIN_WIDTH,
            })
            .afterClosed()
            .subOnce({
                next: (updatedDto: IARPaymentScheduleDto) => {
                    if (updatedDto) {
                        this.loadProgressPayments(this.lotMappedItem.id);
                    }
                }
            });
    }

    public viewProgressPaymentHistory(): void {
        this.cbDialog.open(
            ARViewProgressPaymentHistoryDialogComponent,
            {
                data: {
                    mappedItem: this.lotMappedItem.id
                },
                minWidth: ARViewProgressPaymentHistoryDialogComponent.MIN_WIDTH,
                maxHeight: ARViewProgressPaymentHistoryDialogComponent.MAX_HEIGHT
            });
    }

    public editVariationLine(variation: IARVariationDto): void {
        if (!this.canEditPaymentSchedule) {
            return;
        }
        const arMappedItem = this.arVariationLogicService.$createMappedItem(variation);
        this.cbDialog.open(
            AREditVariationDialogComponent,
            {
                data: {
                    mappedItem: arMappedItem.$clone(),
                },
                minWidth: AREditVariationDialogComponent.MIN_WIDTH,
            })
            .afterClosed()
            .subOnce({
                next: (updatedDto: IARVariationDto) => {
                    if (updatedDto) {
                        this.loadAcceptedVariations(this.lotMappedItem.id);
                    }
                }
            });
    }

    private loadProgressPayments(lotId: number): void{
        this.lotsLogicService
            .getProgressPayments(lotId)
            .subOnce((_progressPayment) => {
                this.progressPaymentDto = _progressPayment;
                this.progressPaymentLoaded = true;
            });
    }

    private loadAcceptedVariations(lotId: number): void {
        this.variationLogicService
            .getAcceptedByLot(lotId)
            .subOnce((_variations) => {
                this.variations = _variations;
                this.variationTotals = this.variations?.reduce((sum: number, b: IARVariationDto) => sum + b.lineCostWithGst, 0) ?? 0;
            });
    }

    public isVariationPendingOrAwaiting(variation: IARVariationDto): boolean {
        return variation.variationStatus === VARIATION_STATUS_ENUM.Pending || variation.variationStatus === VARIATION_STATUS_ENUM.Awaiting;
    }
}
