<div class="cb-padding-left-2x cb-padding-right-2x flex-col flex-100">
    <h3 class="mat-title">
        Default {{label}} Documents
    </h3>
    <form #addForm="ngForm"
          class="cb-margin-top start-center flex-row">
        <cb-select *ngIf="!disableContractType"
                   class="cb-margin-right"
                   label="Lot Contract Type"
                   name="lotContractType"
                   [(ngModel)]="selectedContractType"
                   valueProp="id"
                   [options]="lotContractTypes"></cb-select>
        <cb-select class="cb-margin-right"
                   label="Document Group"
                   name="documentGroup"
                   [(ngModel)]="selectedDocumentGroupCode"
                   valueProp="codeName"
                   [options]="documentGroups"></cb-select>
        <cb-select class="cb-margin-right"
                   label="Document Type"
                   name="documentType"
                   [(ngModel)]="selectedDocumentTypeId"
                   valueProp="documentTypeId"
                   [options]="possibleDocumentTypes.value | async"
                   [disabled]="contractTypeInvalid || !selectedDocumentGroupCode || !anyPossibleDocumentTypes">
        </cb-select>
        <button mat-button
                mat-raised-button
                color="primary"
                [disabled]="contractTypeInvalid || !selectedDocumentTypeId"
                (click)="addItem()">
            Add
        </button>
    </form>
    <cb-table [label]="'Default ' + label + ' Documents'"
              [loaded]="loaded">
        <thead cbTableHead>
            <tr>
                <td *ngIf="!disableContractType">
                    Contract Type
                </td>
                <td>
                    Document Group
                </td>
                <td>
                    Document Type
                </td>
                <td>
                    Active
                </td>
                <td></td>
            </tr>
        </thead>
        <tr cbTableRow
            *ngFor="let documentType of systemAreaDocumentTypes">
            <cb-td-text *ngIf="!disableContractType"
                        [value]="LOT_CONTRACT_TYPE_ENUM[documentType.lotContractType]"></cb-td-text>
            <cb-td-text [value]="documentGroupLabels ? documentGroupLabels[documentType.documentGroupId] : '-'">
            </cb-td-text>
            <cb-td-text [value]="documentType.label"></cb-td-text>
            <cb-td-checkbox [value]="documentType.isActive"></cb-td-checkbox>
            <td cbTableStickyCol="end">
                <button mat-raised-button
                        mat-icon-button
                        (click)="removeItem(documentType)">
                    <mat-icon>
                        remove_circle
                    </mat-icon>
                </button>
            </td>
        </tr>
    </cb-table>
</div>
