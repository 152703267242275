import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { BuildProgrammePermissions, LotBuildPermissions } from '@app/core/permissions';
import { IBuildProgrammeActivityMappedItem } from '@app/logic/build-programme-activity';
import { PurchaseOrdersLogicService } from '@app/logic/purchase-orders';
import { SsrsLogicService } from '@app/logic/ssrs';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { LotBuildProgrammeEventService } from '@app/views/lot/build/services/lot-build-programme-event.service';
import { ILotDto, LOT_TYPE_ENUM, MANUAL_PURCHASE_ORDER_ORIGIN_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable, Subscription } from 'rxjs';
import { ViewManualOrderDialogComponent } from '../../../orders/view-manual-order-dialog/view-manual-order-dialog.component';
import { ConvertToManualOrderDialogComponent } from '../convert-to-manual-order-dialog/convert-to-manual-order-dialog.component';
import { EditBuildProgrammeActivityDialogComponent } from '../edit-build-programme-activity-dialog/edit-build-programme-activity-dialog.component';
import { EditBuildProgrammeSsrDialogComponent } from '../edit-build-programme-ssr-dialog/edit-build-programme-ssr-dialog.component';
import { PoDocumentHistoryComponent } from '../po-document-history/po-document-history.component';
import { SetBuildProgrammeActivitySupplierDialogComponent } from '../set-build-programme-activity-supplier-dialog/set-build-programme-activity-supplier-dialog.component';
import { LotBuildProgrammeActivityMenuPermissions } from './lot-build-programme-activity-menu.permissions';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'cb-lot-build-programme-activity-menu',
    templateUrl: './lot-build-programme-activity-menu.component.html',
    styleUrls: ['./lot-build-programme-activity-menu.component.scss']
})
export class LotBuildProgrammeActivityMenuComponent implements OnDestroy {

    @Input() public readonly downloadOnly = false;

    public lotDto: ILotDto;
    @Input() public set lot(lot: ILotDto) {
        this.lotDto = lot;
        this.toggleControlData.recompute();
    }

    public mappedItem: IBuildProgrammeActivityMappedItem;
    @Input() public set buildProgrammeActivity(v: IBuildProgrammeActivityMappedItem) {
        this.mappedItem = v;
        this.toggleControlData.recompute();
        this.$activitySub.unsubscribe();
        this.$activitySub = this.mappedItem.CHANGED.subscribe(() => {
            this.lotBuildProgrammeEvents.STAGE_REQUIRES_UPDATE.next(this.mappedItem.buildStageId);
            this.toggleControlData.recompute();
        });
    }

    public readonly toggleControlData = new ComputedProperty(() => {
        const isUnit = this.lotDto?.lotType === LOT_TYPE_ENUM.Unit;
        const isBlock = this.lotDto?.lotType === LOT_TYPE_ENUM.ApartmentBlock
            || this.lotDto?.lotType === LOT_TYPE_ENUM.TerracedBlock
            || this.lotDto?.lotType === LOT_TYPE_ENUM.Duplex;
        return {
            showButton: isBlock || isUnit,
            buttonLabel: `Move to ${isUnit && !this.mappedItem?.isBlockLevelActivity ? 'Block' : 'Unit'} Build Programme`,
            enableButton: this.mappedItem?.rules?.canEditIsControlledByParentLot,
            icon: isUnit && !this.mappedItem?.isBlockLevelActivity ? 'transfer-up' : 'transfer-down',
        };
    });

    public get permissions(): LotBuildProgrammeActivityMenuPermissions {
        return this.computedPermissions.value;
    }
    private readonly computedPermissions = new ComputedProperty(() =>
        new LotBuildProgrammeActivityMenuPermissions(this.lotBuildPermissions, this.buildProgrammePermissions, this.currentUser));

    private readonly $subscription = new Subscription();
    private $activitySub = new Subscription();

    constructor(
        private readonly purchaseOrderLogic: PurchaseOrdersLogicService,
        private readonly lotBuildPermissions: LotBuildPermissions,
        private readonly cbDialog: CbDialogService,
        private readonly ssrLogic: SsrsLogicService,
        private readonly lotBuildProgrammeEvents: LotBuildProgrammeEventService,
        public readonly currentUser: CurrentUserService,
        public readonly buildProgrammePermissions: BuildProgrammePermissions
    ) {
        this.$subscription.add(
            this.lotBuildPermissions.observable.subscribe((result) => {
                this.computedPermissions.recompute();
            })
        );
    }

    public ngOnDestroy(): void {
        this.$subscription.unsubscribe();
        this.$activitySub.unsubscribe();
    }

    public toggleControl(): void {
        if (!this.mappedItem?.rules?.canEditIsControlledByParentLot) {
            return;
        }
        this.cbDialog.confirm({
            dialogHeading: this.toggleControlData.value.buttonLabel,
            message: 'Are you sure you want to move this Activity?',
            confirmed: () => {
                this.mappedItem
                    .toggleControl()
                    .subOnce((result) => {
                        if (result?.length < 1) {
                            return;
                        }
                        if (!result.find(x => x.id === this.mappedItem.id)) {
                            this.lotBuildProgrammeEvents.removeBuildProgrammeActivity(this.mappedItem.id);
                        }
                        this.lotBuildProgrammeEvents.ACTIVITY_RECEIVED.next(result);
                    });
            }
        });
    }

    public viewManualOrder(): void {
        if (!this.mappedItem.isFromManualPurchaseOrder) {
            return;
        }

        this.purchaseOrderLogic
            .$getMappedItem(this.mappedItem.manualOrderId)
            .subOnce(purchaseOrder => {
                this.cbDialog.open(
                    ViewManualOrderDialogComponent,
                    {
                        data: {
                            mappedItem: purchaseOrder,
                            origin: MANUAL_PURCHASE_ORDER_ORIGIN_ENUM.LotBuild,
                            lotDto: this.lotDto,
                            permissions: this.lotBuildPermissions,
                            downloadOnly: this.downloadOnly && this.mappedItem.lotId !== this.lotDto.id,
                            isTransformedToManualOrder: this.mappedItem.isTransformedToManualOrder
                        },
                        minWidth: '66%',
                    }
                )
                    .afterClosed()
                    .subOnce(() => this.mappedItem.$reload().subOnce());
            });
    }

    public redraftManualOrder(): void {
        if (!this.mappedItem.rules.canRedraftSsr) {
            return;
        }

        this.cbDialog.confirm({
            dialogHeading: 'Redraft Manual Purchase Order',
            message: 'Are you sure you want to Redraft this Manual Purchase Order?',
            confirmed: () => {
                this.purchaseOrderLogic
                    .redraftPurchaseOrder(this.mappedItem.manualOrderId)
                    .subOnce(() => this.subAndBlock(this.mappedItem.$reload()));
            }
        });
    }

    public setToAbandoned(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Abandon Activity',
            message: `Are you sure you want to Abandon ${this.mappedItem?.activity?.name}?`,
            confirmed: () => {
                this.subAndBlock(
                    this.mappedItem
                        .setToAbandoned()
                );
            }
        });
    }

    public completeManualOrder(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Set Manual Order to Completed',
            message: 'Are you sure you want to set this Manual Order to Completed?',
            confirmed: () => {
                this.subAndBlock(
                    this.mappedItem
                        .completeManualOrder()
                );
            }
        });
    }

    public convertToManualOrder(): void {
        this.cbDialog.open(
            ConvertToManualOrderDialogComponent,
            {
                minWidth: '33%',
            }
        )
            .afterClosed()
            .subOnce({
                next: (result) => {
                    if (result) {
                        this.subAndBlock(
                            this.mappedItem
                                .convertToManualOrder(result)
                        );
                    }
                }
            });
    }

    public cancelSsr(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Set SSR State to Cancelled',
            message: 'Are you sure you want to Set this SSR State to Cancelled?',
            confirmed: () => {
                this.subAndBlock(
                    this.mappedItem
                        .cancelSsr()
                );
            }
        });
    }

    public completeSsr(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Set SSR State to Completed',
            message: 'Are you sure you want to Set this SSR State to Completed?',
            confirmed: () => {
                this.subAndBlock(
                    this.mappedItem
                        .completeSsr()
                );
            }
        });
    }

    public confirmSsr(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Set SSR State to Confirmed',
            message: 'Are you sure you want to Set this SSR State to Confirmed?',
            confirmed: () => {
                this.subAndBlock(
                    this.mappedItem
                        .confirmSsr()
                );
            }
        });
    }

    public redraftSsr(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Set SSR State to Draft',
            message: 'Are you sure you want to Set this SSR State to Draft?',
            confirmed: () => {
                this.subAndBlock(
                    this.mappedItem
                        .redraftSsr()
                );
            }
        });
    }

    public splitActivity(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Split Activity',
            message: `Are you sure you want to split ${this.mappedItem?.activity?.name ?? 'this activity'}?`,
            confirmed: () => {
                this.subAndBlock(
                    this.mappedItem
                        .splitActivity()
                );
            }
        });
    }

    public editBuildProgrammeActivity(): void {
        if (!this.mappedItem.rules.canEditActivity) {
            return;
        }
        this.cbDialog
            .open(EditBuildProgrammeActivityDialogComponent, {
                data: {
                    buildProgrammeActivity: this.mappedItem.$clone()
                }
            });
    }

    public editActivity(): void {
        this.ssrLogic.$getMappedItem(this.mappedItem.ssrId).subOnce((ssr) => {
            this.cbDialog.open(
                EditBuildProgrammeSsrDialogComponent,
                {
                    data: {
                        mappedItem: ssr,
                        buildProgrammeActivity: this.mappedItem.$clone(),
                        lotType: this.lotDto.lotType,
                        lotDto: this.lotDto,
                        canSetSupplier: !this.downloadOnly && this.permissions.canSetSupplier
                    },
                    minWidth: '90%',
                }
            )
                .afterClosed()
                .subOnce(() => {
                    this.mappedItem
                        .$reload()
                        .subOnce();
                });
        });
    }

    public createSsr(): void {
        this.subAndBlock(
            this.mappedItem
                .createSsr()
        );
    }

    public setSupplier(): void {
        this.cbDialog
            .open(
                SetBuildProgrammeActivitySupplierDialogComponent,
                {
                    data: {
                        regionId: this.lotDto?.location?.region?.id,
                        buildProgrammeActivity: this.mappedItem.$clone(),
                        lotIsPrimaryUnit: this.lotDto?.isPrimaryUnit,
                        lotId: this.lotDto?.id
                    },
                    minWidth: '33%',
                }
            )
    }

    private readonly subAndBlock = (ob: Observable<any>): void => {
        const blockRef = this.cbDialog.block('Please wait, loading...');
        ob.subOnce({ complete: () => blockRef?.close(), error: () => blockRef?.close() });
    };


    public viewHistory(buildProgrammeActivityMappedItem: IBuildProgrammeActivityMappedItem): void {
        this.purchaseOrderLogic.getPurchaseOrderHistory(buildProgrammeActivityMappedItem.lotId, buildProgrammeActivityMappedItem.id)
            .subOnce((x) => {
                this.cbDialog.open(
                    PoDocumentHistoryComponent,
                    {
                        data: {
                            purchaseOrderHistories: x,
                            title: 'Purchase Order History: ' + buildProgrammeActivityMappedItem.activity.name
                        },
                        minWidth: PoDocumentHistoryComponent.MIN_WIDTH,
                    }
                );

            });


    }
}
