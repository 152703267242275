import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NotificationsDialogComponent } from '../notifications-dialog/notifications-dialog.component';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { SignalrService } from '@app/core/services/signalr/signalr.service';

const DIALOG_PADDING_BOTTOM = 360;

@Component({
    selector: 'cb-topnav-title',
    templateUrl: './topnav-title.component.html',
    styleUrls: ['./topnav-title.component.scss']
})
export class TopnavTitleComponent {

    @Input() public sidenav: MatSidenav;
    public notificationsOpen = false;
    public get unreadNotificationsCount(): number {
        return (this.signalRService.unreadNotifications || []).length;
    }

    private dialogRef: MatDialogRef<NotificationsDialogComponent>;

    constructor(
        public readonly dialog: MatDialog,
        public readonly navigationService: NavigationService,
        private readonly signalRService: SignalrService,
    ) { }

    public openNotifications(event: MouseEvent): void {
        if (this.notificationsOpen) {
            return this.dialogRef.close();
        }
        this.dialogRef = this.dialog.open(
            NotificationsDialogComponent,
            {
                width: '360px',
                height: `${window.innerHeight - DIALOG_PADDING_BOTTOM}px`,
                position: { top: '104px', right: '8px' },
                hasBackdrop: false,
                data: {
                    navigationService: this.navigationService,
                    notificationHubService: this.signalRService
                }
            }
        );
        this.dialogRef.afterOpened()
            .subOnce(() => {
                this.notificationsOpen = true;
            });
        this.dialogRef.afterClosed()
            .subOnce(() => {
                this.notificationsOpen = false;
            });
    }

    public goToHome(): void {
        this.navigationService.navigate(['/']);
    }
}
