<div class="flex-row flex"
     style="overflow-x: auto">
    <div class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex-100">
        <div class="start-center flex-row">
            <h3 class="mat-title flex">
                Build Types
            </h3>
            <button class="header-button"
                    mat-raised-button
                    (click)="newItemClicked();"
                    color="primary">
                Add Build Type
            </button>
        </div>
        <div>
            <cb-select-list label="Filter by Lot Types"
                            [panelClass]="'build-type-filter'"
                            [allOptions]="lotTypes"
                            [multiple]="true"
                            [(value)]="filterLotTypes"
                            (selectChanged)="onLotTypesChanged()">
            </cb-select-list>
        </div>
        <div class="cb-table cb-margin-top">
            <mat-table [dataSource]="searchResults">
                <ng-container matColumnDef="code">
                    <mat-header-cell *matHeaderCellDef>
                        Code
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.code}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="label">
                    <mat-header-cell *matHeaderCellDef>
                        Build Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.label}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="paymentTemplates">
                    <mat-header-cell *matHeaderCellDef>
                        PaymentTemplates
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{getDefaultPaymentTemplate(element.paymentTemplates)}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="lotTypes">
                    <mat-header-cell *matHeaderCellDef>
                        Lot Types
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{getLotTypeLabelList(element.lotType)}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="isActive">
                    <mat-header-cell *matHeaderCellDef>
                        Active
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-icon>
                            {{element.isActive ? "check_box" : "check_box_outline_blank"}}
                        </mat-icon>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions"
                              stickyEnd>
                    <mat-header-cell *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-raised-button
                                mat-icon-button
                                (click)="editItemClicked(element)">
                            <mat-icon>
                                edit
                            </mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <cb-info-message *ngIf="searchResultsLoaded && searchResults?.length === 0"
                             [message]="'There are no Build Type to display'"
                             [icon]="'info_circle'"></cb-info-message>
            <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
        </div>
    </div>
</div>
