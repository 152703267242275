<button mat-icon-button
        mat-raised-button
        color="accent"
        class="collapse-button"
        (click)="collapsed = !collapsed"
        [matTooltip]="collapsed ? 'Expand' : 'Collapse'">
    <mat-icon class="collapse-icon">
        {{ collapsed ? 'chevron_right' : 'chevron_left '}}
    </mat-icon>
</button>
<mat-card *ngIf="collapsed"
          class="collapsed-card flex-row">
    <span class="mat-title collapsed-title">Changes List</span>
</mat-card>
<ng-container *ngIf="!collapsed">
    <mat-card class="filter-card flex-col">
        <form class="cb-margin-right-3x start-center flex-row flex">
            <mat-icon class="cb-margin-right-2x">search</mat-icon>
            <cb-input class="flex-100"
                      name="searchText"
                      label="Type in Change Details"
                      [(ngModel)]="searchQuery"
                      (change)="setFilteredChangeRecords()">
            </cb-input>
        </form>
        <div class="flex-row">
            <mat-chip-list #chipList
                           aria-label="Fruit selection">
                <mat-chip *ngFor="let id of filterredChangeIds"
                          (removed)="removeFilterChip(id)">
                    #{{id}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div class="flex-row">
            <cb-select-list class="cb-margin-right flex-33"
                            label="Change Type"
                            [allOptions]="costNatures"
                            [multiple]="true"
                            [value]="userCacheItem?.silentData?.selectedCostNatures"
                            (selectChanged)="selectedCostNaturesChanged($event)">
            </cb-select-list>
            <cb-select-list class="cb-margin-right flex-33"
                            label="Status"
                            [allOptions]="changeStatus"
                            [multiple]="true"
                            [value]="userCacheItem?.silentData?.selectedStatus"
                            (selectChanged)="selectedStatusChanged($event)">
            </cb-select-list>
            <cb-select-list class="cb-margin-right flex-33"
                            label="Variation"
                            [allOptions]="variationsSelectList"
                            [multiple]="true"
                            [value]="userCacheItem?.silentData?.selectedVariations"
                            (selectChanged)="selectedVariationsChanged($event)">
            </cb-select-list>
        </div>
    </mat-card>
    <mat-card class="list-card scroll-container flex-col">
        <cb-info-message *ngIf="changeRecordMappedItems.length < 1"
                         message="There were no Changes found"
                         iconClass="cb-accent"></cb-info-message>
        <div class="list-container flex"
             #listContainer>
            <mat-list class="flex flex-col">
                <mat-list-item *ngFor="let changeRecord of filteredchangeRecords;"
                               [ngClass]="{'selected-change-record': currentChangeId === changeRecord.id, 'change-record-list-item': true}">
                    <div class="flex-row flex">
                        <div class="cb-margin-right center-center flex-col">
                            <mat-icon [ngClass]="getStatusIconClass(changeRecord)"
                                      class="help-cursor"
                                      [title]="getStatusIconTitle(changeRecord)">
                                {{getStatusIconIcon(changeRecord)}}
                            </mat-icon>
                        </div>
                        <div class="cb-margin-left cb-margin-right flex-col flex">
                            <div matLine
                                 class="mat-body-2 item-line">
                                #{{changeRecord.changeNumber}} -
                                {{ changeRecord.changeDetails}}
                            </div>
                            <div matLine
                                 class="item-line">
                                {{changeRecord.assignedTeam ? changeRecord.assignedTeam + " Team" : "No Team"}}
                                <mat-icon class="cb-grey">chevron_right</mat-icon>
                                {{changeRecord.changeStatus | cbEnumToLabel: CHANGE_STATUS_ENUM }}
                                <span *ngIf="showVariationNumber(changeRecord)">
                                    &nbsp;&nbsp;(VO#{{changeRecord.variationNumber}})
                                </span>
                            </div>
                            <div matLine
                                 class="item-line">
                                <mat-icon class="help-cursor cb-grey cb-margin-right"
                                          [title]="getCostNatureIconTitle(changeRecord.costNature)">
                                    {{getCostNatureIcon(changeRecord.costNature)}}
                                </mat-icon>
                                <span>
                                    {{changeRecord.costNature | cbEnumToLabel: COST_NATURE_ENUM }}
                                </span>
                                <span *ngIf="showCostChangeAmountGST(changeRecord)"
                                      [class.is-credit]="changeRecord.costChangeAmount < 0">
                                    &nbsp;&nbsp;{{changeRecord.costChangeAmount | cbCurrency:'addGst'}}
                                    (Inc Gst)
                                </span>
                                <span *ngIf="showCostChangeAmount(changeRecord)"
                                      [class.is-credit]="changeRecord.costChangeAmount < 0">
                                    &nbsp;&nbsp;{{changeRecord.costChangeAmount | cbCurrency}}
                                </span>
                            </div>
                        </div>
                        <div class="center-center flex-row">
                            <mat-icon class="help-cursor mat-warn cb-margin-right"
                                      matTooltip="Has unanswered question"
                                      svgIcon="comment-question-outline"
                                      *ngIf="changeRecord.hasUnansweredQuestion">
                            </mat-icon>
                            <button mat-icon-button
                                    mat-raised-button
                                    (click)="changeRecordClicked(changeRecord)">
                                <mat-icon>exit_to_app</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </mat-card>
</ng-container>
