<div class="flex-col cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x">
    <div class="flex-row start-center">
        <h3 class="flex mat-title">Build Overheads</h3>
        <button class="header-button"
                mat-raised-button
                [disabled]="!lotBuildOverheadCostsPermissions.canCreate()"
                (click)="newItemClicked();"
                color="primary">
            Add Overhead(s)
        </button>
    </div>
    <cb-table class="cb-margin-top"
              [columns]="displayedColumns"
              [loaded]="searchResultsLoaded"
              [minWidth]="1000"
              [maxHeightOffset]="186">
        <tbody cbTableBody>
        <tr *ngFor="let ele of searchResults | cbSortByImpurePipe: 'buildOverheadCost.name' : 'asc'">
            <td>
                {{ele.buildOverheadCost.name}}
                <cb-info-popup [tooltip]="ele.buildOverheadCost.description">
                </cb-info-popup>
            </td>
            <td>{{ele.notes}}</td>
            <td>{{ele.buildOverheadCost.financeCode}}</td>
            <td>{{ele.valueGstIncl | cbCurrency}}</td>
            <td>{{ele.value | cbCurrency}}</td>
            <td>
                <cb-user-date-time-new [userFullName]="ele.updatedByName"
                                       [date]="ele.updatedDate"
                                       [displayTime]="true">
                </cb-user-date-time-new>
            </td>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-raised-button
                        mat-icon-button
                        [disabled]="!lotBuildOverheadCostsPermissions.canEdit()"
                        class="cb-margin-left cb-margin-right"
                        (click)="editItemClicked(ele);">
                    <mat-icon>edit</mat-icon>
                </button>

                <button mat-raised-button
                        mat-icon-button
                        [disabled]="!lotBuildOverheadCostsPermissions.canEdit()"
                        class="cb-margin-right"
                        (click)="deleteItem(ele)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </cb-table>
</div>
