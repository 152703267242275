<ng-container>
    <cb-table class="cb-margin-top"
              label="Schedule Items"
              [loaded]="true"
              [minWidth]="750"
              [hideDefaultTable]="true">
        <table cbTable>
            <thead>
                <tr>
                    <td [width]="48"></td>
                    <td [width]="48"></td>
                    <td>Name</td>
                    <td>Cost Type</td>
                </tr>
            </thead>
            <tbody class="parent-slot"
                   *ngFor="let parentSlot of parentSlots">
                <tr *cbVar="shouldDisableParentItem(parentSlot.id) as shouldDisableRow"
                    [ngClass]="shouldDisableRow  ? 'disabledRow' : '' ">
                    <td>
                        <button mat-raised-button
                                mat-icon-button
                                [disabled]="!featureToggle.isScheduleItemSpecTemplatesEnabled || shouldDisableRow"
                                (click)="addItemToSpecificationTemplate(this.houseAreaIdWithSkinnyItems.houseAreaId, parentSlot, true)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </td>

                    <td>
                        <button mat-raised-button
                                mat-icon-button
                                [disabled]="!childSlots[parentSlot.id]?.length"
                                (click)="expandedParents[parentSlot.id] = !expandedParents[parentSlot.id]">
                            <mat-icon>{{ expandedParents[parentSlot.id] &&
                                childSlots[parentSlot.id]?.length ?
                                'arrow_drop_up' : 'arrow_drop_down'}}
                            </mat-icon>
                        </button>
                    </td>

                    <td>
                        <span>
                            <cb-td-text [value]="parentSlot.name"></cb-td-text>
                            <cb-info-popup [tooltip]="parentSlot.description"></cb-info-popup>
                        </span>
                    </td>
                    <cb-td-enum [value]="parentSlot.costType"
                                [enum]="COST_TYPE_ENUM">
                    </cb-td-enum>

                </tr>
                <ng-container *ngIf="expandedParents[parentSlot.id]">
                    <ng-container *ngFor="let childSlot of childSlots[parentSlot.id]">
                        <tr *cbVar="shouldDisableChildItem(parentSlot.id, childSlot.id) as shouldDisableRow"
                            [ngClass]="shouldDisableRow ? 'disabledRow' : '' ">
                            <td [width]="48"></td>
                            <td>
                                <button *ngIf="!childSlot.isRequired"
                                        mat-raised-button
                                        mat-icon-button
                                        [disabled]="!featureToggle.isScheduleItemSpecTemplatesEnabled || shouldDisableRow"
                                        (click)="addItemToSpecificationTemplate(this.houseAreaIdWithSkinnyItems.houseAreaId, childSlot, false)">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </td>
                            <td class="childLabel">
                                <span>
                                    <cb-td-text [value]="childSlot.name"></cb-td-text>
                                    <cb-info-popup [tooltip]="childSlot.description"></cb-info-popup>
                                </span>
                            </td>
                            <cb-td-enum [value]="childSlot.costType"
                                        [enum]="COST_TYPE_ENUM">
                            </cb-td-enum>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
            <tbody *ngIf="parentSlots?.length < 1">
                <tr class="cb-table-footer-row">
                    <td colspan="100%">
                        <cb-info-message message="There are no Schedule Items to display for this House Area"
                                         icon="info_circle"></cb-info-message>
                    </td>
                </tr>
            </tbody>
        </table>
    </cb-table>
</ng-container>