import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbTasksModule } from '@app/shared/components/tasks/tasks.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CouncilRfiListComponent } from './council-rfi-list/council-rfi-list.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ManageCouncilRfiComponent } from './manage-council-rfi/manage-council-rfi.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { CouncilRfiActionBarComponent } from './council-rfi-action-bar/council-rfi-action-bar.component';
import { CouncilRfiDetailsComponent } from './council-rfi-details/council-rfi-details.component';
import { CouncilRfiMainComponent } from './council-rfi-main/council-rfi-main.component';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { EditCouncilRfiDialogComponent } from './edit-council-rfi-dialog/edit-council-rfi-dialog.component';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CouncilRfiChangeRecordsComponent } from './council-rfi-change-records/council-rfi-change-records.component';
import { CbDasherModule } from 'cb-hub-lib';
import { ReassignCouncilRfiDialogComponent } from './reassign-council-rfi-dialog/reassign-council-rfi-dialog.component';
import { CbGenericQuestionsModule } from '@app/shared/components/generic-questions/generic-questions.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbTableModule } from 'cb-hub-lib';
import { CouncilRfiListCardModule } from './council-rfi-list-card/council-rfi-list-card.module';



@NgModule({
    declarations: [
        CouncilRfiListComponent,
        ManageCouncilRfiComponent,
        CouncilRfiActionBarComponent,
        CouncilRfiDetailsComponent,
        CouncilRfiMainComponent,
        EditCouncilRfiDialogComponent,
        CouncilRfiChangeRecordsComponent,
        ReassignCouncilRfiDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        CbFormsModule,
        MatIconModule,
        MatCardModule,
        InfiniteScrollModule,
        MatButtonModule,
        MatDividerModule,
        MatCardModule,
        CbDisplayValueModule,
        MatTooltipModule,
        MatTabsModule,
        CbTasksModule,
        CbWaitForModule,
        CbDocumentsModule,
        MatMenuModule,
        CbUserDateTimeModule,
        CbDialogModule,
        CbClickModule,
        CbDasherModule,
        CbGenericQuestionsModule,
        CbHubTableModule,
        CbTableModule,
        CouncilRfiListCardModule,
    ],
    exports: [
        CouncilRfiMainComponent
    ]
})

export class LotDesignCouncilRfiModule { }
