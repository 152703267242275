<div class="flex-col flex">
    <div class="mat-subheading-2 widget-name">Documents Requiring Action</div>
    <div class="widget-container flex-col">
        <div class="widget-list-item flex-col"
             *ngFor="let item of data; let i = index">
            <div class="cb-padding-top cb-padding-bottom start-start flex-row flex">
                <div class="flex-col flex">
                    <span class="mat-body-1">{{item.documentType.label}}</span>
                    <span class="mat-caption">{{item.info}}</span>
                    <span class="mat-caption">{{item.statusLabel}}</span>
                </div>
                <button mat-raised-button
                        mat-icon-button
                        class="cb-margin-right"
                        [disabled]="documentEntityViewUrl(item)?.url =='#'"
                        (click)="goToItem(documentEntityViewUrl(item), $event)">
                    <mat-icon>pageview</mat-icon>
                </button>
            </div>
            <hr *ngIf="i < data.length-1"
                class="mat-divider"/>
        </div>
        <span class="start-center flex layoutGap-6"
              *ngIf="!data || !data.length">
            <mat-icon class="mat-accent">info_circle</mat-icon>
            <div>
                There are no Documents requiring action
            </div>
        </span>
    </div>
</div>
