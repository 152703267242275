<cb-lot-spec-item-icons [mappedItem]="mappedItem"></cb-lot-spec-item-icons>
<cb-td-text #codeCol
            [value]="mappedItem.productCode"></cb-td-text>
<span *ngIf="!(mappedItem.isManualQuote && mappedItem.isQuoteCost)">
    <cb-td-text [value]="mappedItem.productDisplay"></cb-td-text>
</span>
<span *ngIf="mappedItem.isManualQuote && mappedItem.isQuoteCost">
    <cb-td-text [value]="mappedItem.quoteLineDescription"></cb-td-text>
</span>
<cb-td-text #categoryCol
            [value]="mappedItem.productCategory"></cb-td-text>
<cb-td-enum [value]="mappedItem.costType"
            [enum]="COST_TYPE_ENUM"></cb-td-enum>
<cb-td-text #supplierCol
            [value]="mappedItem.supplierDisplay"></cb-td-text>
<td [delayed]="false"
    (cbAfterInit)="colWidthSyncService.update(mappedItem.id, codeCol?.elementRef?.nativeElement, categoryCol?.elementRef?.nativeElement, supplierCol?.elementRef?.nativeElement)"
    (cbOnResize)="colWidthSyncService.update(mappedItem.id, codeCol?.elementRef?.nativeElement, categoryCol?.elementRef?.nativeElement, supplierCol?.elementRef?.nativeElement)"
    (cbOnDestroy)="colWidthSyncService.delete(mappedItem.id)"
    cbTableShrinkCol
    cbTableStickyCol="end">
    <button mat-raised-button
            mat-icon-button
            [matMenuTriggerFor]="menu">
        <mat-icon mat-menu-origin>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item
                *ngIf="!uiActions.hideQrefsButton()"
                (click)="uiActions.quoteRefsDialog()"
                [disabled]="!mappedItem.hasQuoteLines">Q Refs</button>
        <button mat-menu-item
                (click)="uiActions.showPurchaseOrders()"
                *ngIf="mappedItem.isSsrConfirmed">
            PO's
        </button>
        <button mat-menu-item
                (click)="uiActions.splitProduct()"
                *ngIf="uiActions.showSplitSpecItem()"
                [disabled]="!mappedItem.canSplit">Split</button>
        <button mat-menu-item
                (click)="uiActions.editLotSpecItem()"
                [disabled]="!mappedItem.canEdit"
                *ngIf="uiActions.canChangeSpecification()">Edit</button>
        <button mat-menu-item
                (click)="uiActions.removeLotSpecItem()"
                [disabled]="lotSpec?.isLocked"
                *ngIf="uiActions.canChangeSpecification()">Remove</button>
    </mat-menu>
</td>
