import { MatDividerModule } from '@angular/material/divider';
import { CbHouseLandModule } from '@app/views/house-land/house-land.module';
import { HouseAndLandSearchFilterComponent } from './house-and-land-search-filter/house-and-land-search-filter.component';
import { HouseAndLandSearchComponent } from './house-and-land-search/house-and-land-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { HouseAndLandComponent } from './house-and-land/house-and-land.component';
import { HouseAndLandCardComponent } from './house-and-land-card/house-and-land-card.component';
import { CbAmenitiesModule } from '@app/shared/components/amenities/amenities.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbHandleInvalidImageModule } from '@app/shared/directives/handle-invalid-image/handle-invalid-image.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {CbDasherModule, CbInfoMessageModule, CbLoadingSpinnerModule} from 'cb-hub-lib';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';

@NgModule({
    declarations: [
        HouseAndLandComponent,
        HouseAndLandSearchComponent,
        HouseAndLandSearchFilterComponent,
        HouseAndLandCardComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        InfiniteScrollModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatInputModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatOptionModule,
        MatSelectModule,
        NgxSliderModule,
        ReactiveFormsModule,
        MatTooltipModule,
        CbCurrencyModule,
        CbAmenitiesModule,
        CbDisplayValueModule,
        CbHouseLandModule,
        CbHandleInvalidImageModule,
        CbLoadingSpinnerModule,
        CbDasherModule,
        CbInfoMessageModule,
        RouterModule.forRoot([
            {
                component: HouseAndLandComponent,
                // angular 7+ root routes must start with 'ng'
                path: 'house-and-land',
                data: {
                    breadcrumb: 'House & Land'
                }
            }
        ], {}),

    ],
    exports: [
        HouseAndLandComponent,
        HouseAndLandSearchComponent,
        HouseAndLandSearchFilterComponent,
    ]
})
export class HouseAndLandModule { }
