<div #infiniteScrollContainer
     class="cb-padding-left-2x cb-padding-right-2x cb-padding-bottom-2x cb-padding-top flex-row flex">
    <mat-card class="flex-row flex-100">
        <div class="cb-margin-right-3x flex-col flex-33">
            <h4 class="cb-title cb-margin-top-2x cb-margin-bottom-2x">Contact Info</h4>
            <cb-select [required]="true"
                       name="title"
                       label="Title"
                       [options]="salutationLookup"
                       [(ngModel)]="contact.title">
            </cb-select>
            <cb-input type="text"
                      name="firstName"
                      label="First Name"
                      maxlength="100"
                      [required]="true"
                      [(ngModel)]="contact.firstName"></cb-input>
            <cb-input type="text"
                      name="lastName"
                      label="Last Name"
                      maxlength="100"
                      [required]="true"
                      [(ngModel)]="contact.lastName"></cb-input>
            <cb-select [required]="true"
                       name="status"
                       label="Contact Status"
                       [options]="contactStatusLookup"
                       [(ngModel)]="contact.status">
            </cb-select>
            <div class="start-center flex-row">
                <cb-input class="flex"
                          type="text"
                          name="email"
                          label="Email"
                          maxlength="100"
                          [required]="true"
                          [(ngModel)]="contact.email"
                          (change)="emailChanged(emailTooltip)"></cb-input>
                <mat-icon *ngIf="contact.preferredContact === CONTACT_METHOD_ENUM.Email"
                          class="cb-margin-left"
                          color="accent"
                          title="Preferred Contact Method">check_circle
                </mat-icon>
                <button [hidden]="contactsMatching.emailAddress.length < 1"
                        class="cb-margin-left"
                        mat-icon-button
                        mat-raised-button
                        #emailTooltip="matTooltip"
                        matTooltip>
                    <mat-icon>info_circle</mat-icon>
                </button>
            </div>
            <div class="flex-row">
                <cb-input type="text"
                          name="phoneHome"
                          label="Home Phone"
                          maxlength="100"
                          [(ngModel)]="contact.phoneHome"
                          (change)="addRemoveContactMethod(CONTACT_METHOD_ENUM.HomePhone)"
                          style="width: 100%;"></cb-input>
                <span class="flex"></span>
                <mat-icon *ngIf="contact.preferredContact === CONTACT_METHOD_ENUM.HomePhone"
                          color="accent"
                          title="Preferred Contact Method">check_circle</mat-icon>
            </div>
            <div class="flex-row">
                <cb-input type="text"
                          name="phoneWork"
                          label="Work Phone"
                          maxlength="100"
                          [(ngModel)]="contact.phoneWork"
                          (change)="addRemoveContactMethod(CONTACT_METHOD_ENUM.WorkPhone)"
                          style="width: 100%;"></cb-input>
                <span class="flex"></span>
                <mat-icon *ngIf="contact.preferredContact === CONTACT_METHOD_ENUM.WorkPhone"
                          color="accent"
                          title="Preferred Contact Method">check_circle</mat-icon>
            </div>
            <div class="flex-row">
                <cb-input type="text"
                          name="phoneMobile"
                          label="Mobile Phone"
                          maxlength="100"
                          [(ngModel)]="contact.phoneMobile"
                          (change)="addRemoveContactMethod(CONTACT_METHOD_ENUM.MobilePhone)"
                          style="width: 100%;"></cb-input>
                <span class="flex"></span>
                <mat-icon *ngIf="contact.preferredContact === CONTACT_METHOD_ENUM.MobilePhone"
                          color="accent"
                          title="Preferred Contact Method">check_circle</mat-icon>
            </div>
            <cb-select [required]="true"
                       name="contactMethods"
                       label="Preferred Contact Method"
                       [options]="contactMethods"
                       [required]="true"
                       [(ngModel)]="contact.preferredContact">
            </cb-select>
        </div>
        <div class="cb-margin-right-3x flex-col flex-33">
            <cb-address-new [(ngModel)]="contact.physicalAddress"
                            addressTitle="Physical Address"
                            name="physicalAddress">
            </cb-address-new>
            <h4 class="cb-title cb-padding-top-3x">Marketing</h4>
            <div class="cb-margin-top-3x">
                <label class="cb-margin-right-2x">Gender</label>
                <mat-radio-group name="gender"
                                 [(ngModel)]="contact.gender"
                                 [required]="true">
                    <mat-radio-button [value]="'M'"
                                      class="cb-margin-right-3x">Male</mat-radio-button>
                    <mat-radio-button [value]="'F'">Female</mat-radio-button>
                </mat-radio-group>
            </div>
            <cb-select name="ageRange"
                       label="Age range"
                       [options]="ageRangeLookup"
                       [(ngModel)]="contact.ageRange">
            </cb-select>
            <mat-checkbox name="getsSurveys"
                          style="height: 25px;"
                          [(ngModel)]="contact.getsSurveys">Participate in surveys</mat-checkbox>
            <mat-checkbox name="getsNewsletter"
                          style="height: 25px;"
                          [(ngModel)]="contact.getsNewsletter">Receive e-newsletter</mat-checkbox>
            <mat-checkbox name="isStaffMember"
                          style="height: 25px;"
                          [(ngModel)]="contact.isStaffMember">Staff Member</mat-checkbox>
        </div>
        <div class="cb-margin-right-3x flex-col flex-33">
            <cb-address-new [(ngModel)]="contact.postalAddress"
                            addressTitle="Postal Address"
                            name="postalAddress">
            </cb-address-new>
        </div>
    </mat-card>
</div>
