import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import { CbInfoMessageModule, CbLoadingSpinnerModule } from 'cb-hub-lib';
import { NonWorkingDayDialogComponent } from './non-working-day-dialog/non-working-day-dialog.component';
import { NonWorkingDayLocationDialogComponent } from './non-working-day-location-dialog/non-working-day-location-dialog.component';
import { NonWorkingDaysComponent } from './non-working-days.component';

@NgModule({
    declarations: [
        NonWorkingDaysComponent,
        NonWorkingDayDialogComponent,
        NonWorkingDayLocationDialogComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbDatePipeModule,
    ],
    exports: [
        NonWorkingDaysComponent
    ]
})
export class NonWorkingDaysSettingsModule { }
