/* eslint-disable max-len */
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButton as MatButton, MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { RouterModule } from '@angular/router';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbHistoryModule } from '@app/shared/components/history/history.module';
import { CbImageButtonModule } from '@app/shared/components/image-handlers/image-button/image-button.module';
import { CbImageGalleryModule } from '@app/shared/components/image-handlers/image-gallery/image-gallery.module';
import { CbLightboxModule } from '@app/shared/components/image-handlers/lightbox/lightbox.module';
import { CbSideBarModule } from '@app/shared/components/side-bar/side-bar.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbInfiniteScrollModule, CbTableModule, CbDasherModule, CbInfoMessageModule } from 'cb-hub-lib';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbButtonControlModule } from '@app/shared/directives/button-control/button-control.module';
import { CbTabRouteModule } from '@app/shared/directives/tab-route/tab-route.module';
import { CbCallbackModule } from '@app/shared/pipe/callback/callback.module';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { CbPrettyBoolModule } from '@app/shared/pipe/pretty-bool/pretty-bool.module';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { DragulaModule } from 'ng2-dragula';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProjectSearchComponent } from './project-search/project-search.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CbProjectCardModule } from '@app/shared/components/search-results/cards/project-card/project-card.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbNotesModule } from '@app/shared/components/notes/notes.module';
import { CbPhoneNumberModule } from '@app/shared/pipe/phone-number/phone-number.module';
import { CbUsersModule } from '../users/users.module';
import { ProjectReleasesListComponent } from './releases-list/project-releases-list.component';
import { ProjectTeamTableComponent } from './shared-components/project-team-table/project-team-table.component';
import { ProjectReleaseStagePermissions } from '@app/core/permissions/project-release-stage.permissions';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ManageProjectComponent } from './project/manage-project.component';
import { ManageProjectDetailsDialogComponent } from './project/project-details/manage-project-details-dialog/manage-project-details-dialog.component';
import { UniqueProjectNameValidatorDirective } from './project/project-details/manage-project-details-dialog/project-name-validator.directive';
import { TotalNoStagesValidatorDirective } from './project/project-details/manage-project-details-dialog/total-no-stages-validator.directive';
import { ManageProjectDetailsComponent } from './project/project-details/manage-project-details-view.component';
import { ManageProjectSideBarCardComponent } from './project/project-side-bar-card/manage-project-side-bar-card.component';
import { ManageProjectReleaseStageComponent } from './release/manage-release.component';
import { ManageProjectReleaseStageDetailsDialogComponent } from './release/details/manage-release-details-dialog/manage-release-details-dialog.component';
import { ManageProjectReleaseStageDetailsComponent } from './release/details/manage-release-details.component';
import { ManageProjectReleaseStageSideBarCardComponent } from './release/side-bar-card/release-side-bar-card.component';
import { ManageReleaseLotsDesignTabComponent } from './release/lots/design/release-lots-design.component';
import { ManageProjectReleaseStageLotsComponent } from './release/lots/release-lots.component';
import { ManageProjectReleaseStageLotsSummaryTabComponent } from './release/lots/summary/release-lots-summary.component';
import { SelectLotTemplateDialogComponent } from './release/lots/design/dialogs/select-lot-template-dialog/select-lot-template-dialog.component';
import { ImportLotsDialogComponent } from './release/lots/summary/import/import-lots-dialog.component';
import { ProjectReleaseStageEditLotDialogComponent } from './release/lots/summary/edit/edit-lot-dialog.component';
import { ProjectReleaseStageReleaseLotsToSalesDialogComponent } from './release/lots/summary/sales/release-lots-to-sales-dialog.component';
import { RequestSchemesDialogComponent } from './release/lots/design/dialogs/request-schemes-dialog/request-schemes-dialog.component';
import { RequestConceptImagesDialogComponent } from './release/lots/design/dialogs/request-concept-images/request-concept-images.component';
import { RequestPricingDialogComponent } from './release/lots/design/dialogs/request-pricing-dialog/request-pricing-dialog.component';
import { ReleaseDefaultInstallerDialogComponent } from './release/release-default-installer/release-default-installer-dialog/release-default-installer-dialog.component';
import { ReleaseDefaultSupplierDialogComponent } from './release/release-default-supplier/release-default-supplier-dialog/release-default-supplier-dialog.component';
import { ReleaseDefaultInstallerComponent } from './release/release-default-installer/release-default-installer.component';
import { ReleaseDefaultSupplierComponent } from './release/release-default-supplier/release-default-supplier.component';
import { CbTasksModule } from '@app/shared/components/tasks/tasks.module';
import { ReleaseLotsFinancialsComponent } from './release/lots/financials/release-lots-financials.component';
import { ViewReleaseBreadcrumbResolver } from './view-release-breadcrumb.resolver';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { LotSharedModule } from '@app/views/lot/shared/shared.module';

const MAT_MODULES = [
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    MatTooltipModule,
    MatMenuModule,
    MatRadioModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule
];

@NgModule({
    declarations: [
        ProjectSearchComponent,
        ReleaseDefaultInstallerComponent,
        ReleaseDefaultSupplierComponent,
        ImportLotsDialogComponent,
        ManageProjectComponent,
        ManageProjectDetailsDialogComponent,
        ManageProjectDetailsComponent,
        ManageProjectReleaseStageSideBarCardComponent,
        ManageProjectReleaseStageDetailsDialogComponent,
        UniqueProjectNameValidatorDirective,
        TotalNoStagesValidatorDirective,
        ManageProjectSideBarCardComponent,
        ProjectReleasesListComponent,
        ManageProjectReleaseStageComponent,
        ManageProjectReleaseStageDetailsComponent,
        ProjectTeamTableComponent,
        ManageProjectReleaseStageLotsComponent,
        ManageProjectReleaseStageLotsSummaryTabComponent,
        ManageReleaseLotsDesignTabComponent,
        ProjectReleaseStageEditLotDialogComponent,
        ProjectReleaseStageReleaseLotsToSalesDialogComponent,
        SelectLotTemplateDialogComponent,
        RequestSchemesDialogComponent,
        RequestConceptImagesDialogComponent,
        RequestPricingDialogComponent,
        ReleaseDefaultInstallerDialogComponent,
        ReleaseDefaultSupplierDialogComponent,
        ReleaseLotsFinancialsComponent,
    ],
    providers: [
        MatButton,
        ProjectReleaseStagePermissions
    ],
    imports: [
        RouterModule.forRoot(
            [
                {
                    component: ManageProjectReleaseStageComponent,
                    path: 'projects/:id/releases/:releaseId/tasks',
                    resolve: {
                        breadcrumb: ViewReleaseBreadcrumbResolver
                    }
                }, {
                    component: ManageProjectReleaseStageComponent,
                    path: 'projects/:id/releases/:releaseId/notes',
                    resolve: {
                        breadcrumb: ViewReleaseBreadcrumbResolver
                    }
                },
                {
                    component: ManageProjectReleaseStageComponent,
                    path: 'projects/:id/releases/:releaseId/history',
                    resolve: {
                        breadcrumb: ViewReleaseBreadcrumbResolver
                    }
                },
                {
                    component: ManageProjectReleaseStageComponent,
                    path: 'projects/:id/releases/:releaseId/documents',
                    resolve: {
                        breadcrumb: ViewReleaseBreadcrumbResolver
                    }
                },
                {
                    component: ManageProjectReleaseStageComponent,
                    path: 'projects/:id/releases/:releaseId/default-installers',
                    resolve: {
                        breadcrumb: ViewReleaseBreadcrumbResolver
                    }
                },
                {
                    component: ManageProjectReleaseStageComponent,
                    path: 'projects/:id/releases/:releaseId/default-suppliers',
                    resolve: {
                        breadcrumb: ViewReleaseBreadcrumbResolver
                    }
                },
                {
                    component: ManageProjectReleaseStageComponent,
                    path: 'projects/:id/releases/:releaseId/lots',
                    resolve: {
                        breadcrumb: ViewReleaseBreadcrumbResolver
                    },
                }, {
                    component: ManageProjectReleaseStageComponent,
                    path: 'projects/:id/releases/:releaseId/lots/summary',
                    resolve: {
                        breadcrumb: ViewReleaseBreadcrumbResolver
                    },
                }, {
                    component: ManageProjectReleaseStageComponent,
                    path: 'projects/:id/releases/:releaseId/lots/design-summary',
                    resolve: {
                        breadcrumb: ViewReleaseBreadcrumbResolver
                    }
                }, {
                    component: ManageProjectReleaseStageComponent,
                    path: 'projects/:id/releases/:releaseId/lots/cost-summary',
                    resolve: {
                        breadcrumb: ViewReleaseBreadcrumbResolver
                    }
                },
                {
                    component: ManageProjectReleaseStageComponent,
                    path: 'projects/:id/releases/:releaseId/details',
                    resolve: {
                        breadcrumb: ViewReleaseBreadcrumbResolver
                    }
                },
                {
                    component: ManageProjectComponent,
                    path: 'projects/:id/details',
                    data: {
                        breadcrumb: 'View Project',
                        breadcrumbPredecessors: [
                            { label: 'Project Search', url: '/projects/search' }
                        ]
                    }
                },
                {
                    component: ManageProjectComponent,
                    path: 'projects/:id/view',
                    data: {
                        breadcrumb: 'View Project',
                        breadcrumbPredecessors: [
                            { label: 'Project Search', url: '/projects/search' }
                        ]
                    }
                },
                {
                    component: ManageProjectComponent,
                    path: 'projects/:id/release-stages',
                    data: {
                        breadcrumb: 'View Project',
                        breadcrumbPredecessors: [
                            { label: 'Project Search', url: '/projects/search' }
                        ]
                    }
                },
                {
                    component: ManageProjectComponent,
                    path: 'projects/:id/notes',
                    data: {
                        breadcrumb: 'View Project',
                        breadcrumbPredecessors: [
                            { label: 'Project Search', url: '/projects/search' }
                        ]
                    }
                },
                {
                    component: ManageProjectComponent,
                    path: 'projects/:id/documents',
                    data: {
                        breadcrumb: 'View Project',
                        breadcrumbPredecessors: [
                            { label: 'Project Search', url: '/projects/search' }
                        ]
                    }
                },
                {
                    component: ManageProjectComponent,
                    path: 'projects/:id/history',
                    data: {
                        breadcrumb: 'View Project',
                        breadcrumbPredecessors: [
                            { label: 'Project Search', url: '/projects/search' }
                        ]
                    }
                },
                {
                    component: ProjectSearchComponent,
                    path: 'projects/search',
                    data: {
                        breadcrumb: 'Project Search'

                    }
                }
            ],
            {
                onSameUrlNavigation: 'reload'
            }
        ),
        CommonModule,
        FormsModule,
        DragulaModule,
        ReactiveFormsModule,
        BrowserModule,
        CbFormsModule,
        CbTabRouteModule,
        CbTasksModule,
        CbWaitForModule,
        InfiniteScrollModule,
        CbInfiniteScrollModule,
        CbInfoMessageModule,
        CbUsersModule,
        CbDialogModule,
        LotSharedModule,
        CbPhoneNumberModule,
        CbProjectCardModule,
        CbCurrencyModule,
        CbPrettyBoolModule,
        CbSortByModule,
        CbDocumentsModule,
        CbHubTableModule,
        CbTableModule,
        CbButtonControlModule,
        CbNotesModule,
        CbDasherModule,
        CbSideBarModule,
        CbEnumToLabelModule,
        CommonModule,
        BrowserModule,
        CbHistoryModule,
        CbLightboxModule,
        CbImageGalleryModule,
        CbImageButtonModule,
        CbCallbackModule,
        CbDisplayValueModule,
        CbClickModule,
        CbUserDateTimeModule,
        ...MAT_MODULES
    ]
})

export class ProjectsModule { }
