import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { CbUserDateTimeModule } from '../user-date-time/user-date-time.module';
import { CbDialogModule } from '../dialog/dialog.module';
import { CbFormsModule } from '../forms/forms.module';
import { FormsModule } from '@angular/forms';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbDisplayValueModule } from '../display-value/display-value.module';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbInfoMessageModule } from 'cb-hub-lib';
import { AiRateResponseDialogComponent } from '@app/shared/components/ai-rate/ai-rate-response-dialog/ai-rate-response-dialog.component';



@NgModule({
    declarations: [AiRateResponseDialogComponent],
    exports: [],
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatDividerModule,
        CbUserDateTimeModule,
        CbDialogModule,
        CbFormsModule,
        CbClickModule,
        CbDisplayValueModule,
        CbSortByModule,
        CbCurrencyModule,
        MatMenuModule,
        MatLegacyCardModule,
        MatTooltipModule,
        CbInfoMessageModule,
    ]
})
export class CbAiRateModule { }
