<div class="cb-margin flex-col flex">
    <div class="flex-row flex"
         *ngIf="showHeaderAndAddButton">
        <span class="mat-body-2">
            Specific Items that the Designer needs to complete as part of this Scheme
        </span>
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                (click)="addEditItem(FormMode.Add, null)">
            Add Item
        </button>
    </div>
    <mat-divider *ngIf="showHeaderAndAddButton"
                 class="cb-margin"></mat-divider>
    <cb-table class="flex"
              label="Scheme Items"
              [loaded]="this.mappedItem.designRequirementItems"
              emptyMessage="No Items have been added for this Scheme">
        <tbody cbTableBody>
            <ng-container *ngFor="let item of this.mappedItem.designRequirementItems">
                <tr *ngIf="!item.isDeleted">
                    <td width="15%">
                        <mat-checkbox class="mat-option all-option"
                                      [indeterminate]="item.designSchemeLineStatus === DESIGN_SCHEME_LINE_STATUS_ENUM.NotDoing"
                                      [checked]="item.designSchemeLineStatus === DESIGN_SCHEME_LINE_STATUS_ENUM.Completed"
                                      (click)="$event.stopPropagation()"
                                      [disabled]="true"
                                      [labelPosition]="'before'">
                            Item #{{item.itemId}}
                        </mat-checkbox>
                    </td>
                    <td width="80%">
                        <div class="cb-margin flex-col flex">
                            <div class="flex-row flex">{{ item.description }}</div>
                            <div *ngIf="item.comments"
                                 class="cb-margin flex-row flex">
                                <mat-icon class="cb-margin-right">message</mat-icon>
                                <mat-divider [vertical]="true"
                                             class="cb-margin-left cb-margin-right"></mat-divider>
                                {{item.comments}}
                            </div>
                        </div>
                    </td>
                    <td width="5%"
                        cbTableShrinkCol
                        cbTableStickyCol="end">
                        <button mat-raised-button
                                mat-icon-button
                                matTooltip="Edit Item"
                                class="cb-margin-right"
                                (click)="addEditItem(FormMode.Edit, item)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button *ngIf="showDeleteButton"
                                mat-raised-button
                                mat-icon-button
                                matTooltip="Delete Item"
                                class="cb-margin-right"
                                (click)="deleteItem(item)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </cb-table>
</div>
