<cb-dialog dialogHeading="Finalise Pricing Revision - {{currentMappedItem.revisionNumber}}">
    <form class="flex-col flex"
          #finaliseForm="ngForm">
        <div class="flex-row">
            <div class="cb-margin-right-2x flex-col flex-50">
                <cb-display-value-new label="Lot"
                                      [value]="currentMappedItem.lotDescription">
                </cb-display-value-new>
                <cb-display-value-new label="Design Scheme"
                                      [value]="currentMappedItem.revisionNumber">
                </cb-display-value-new>
            </div>
            <div class="flex-col flex-50">
                <cb-display-value-new label="Concept M2"
                                      [value]="currentMappedItem.amenities?.floorArea">
                </cb-display-value-new>
                <cb-display-value-new label="Job #"
                                      [value]="currentMappedItem.jobNumber">
                </cb-display-value-new>
            </div>
        </div>
        <mat-divider class="cb-margin-bottom-2x"></mat-divider>
        <div class="flex-row flex">
            <div class="cb-margin-right-2x flex-col flex">
                <cb-display-value-new label="Previous Pricing Revision"
                                      *ngIf="previousMappedItem !== undefined"
                                      [value]="previousMappedItem?.revisionNumber">
                </cb-display-value-new>
                <cb-display-value-new label="Margin %"
                                      *ngIf="previousMappedItem !== undefined"
                                      [value]="previousMappedItem?.margin">
                </cb-display-value-new>
                <cb-currency *ngIf="previousMappedItem !== undefined"
                             label="Land Price"
                             [required]="(canEditLandPrice$ | async)"
                             [readonly]="!(canEditLandPrice$ | async)"
                             name="previousDesignAndLandLotDeposit"
                             [(ngModel)]="previousMappedItem.landPrice">
                </cb-currency>
                <cb-currency *ngIf="previousMappedItem !== undefined"
                             label="Build Cost"
                             [readonly]="true"
                             name="previousBuildCost"
                             [(ngModel)]="previousMappedItem.buildCost">
                </cb-currency>
                <cb-currency *ngIf="previousMappedItem !== undefined"
                             label="Build Price"
                             [readonly]="true"
                             name="previousBuildPrice"
                             [(ngModel)]="previousMappedItem.buildPrice">
                </cb-currency>
            </div>
            <div class="flex-col flex">
                <cb-display-value-new label="Current Pricing Revision"
                                      [value]="currentMappedItem.revisionNumber">
                </cb-display-value-new>
                <cb-input name="targetWorkHours"
                          label="Margin %"
                          [required]="true"
                          [(ngModel)]="currentMappedItem.margin">
                </cb-input>
                <cb-currency name="landPrice"
                             label="Land Price"
                             [required]="true"
                             [readonly]="!(canEditLandPrice$ | async)"
                             [(ngModel)]="currentMappedItem.landPrice">
                </cb-currency>
                <cb-currency name="buildCost"
                             label="Build Cost"
                             [required]="true"
                             [readonly]="false"
                             (ngModelChange)="updateBuildCost($event)"
                             [(ngModel)]="currentMappedItem.buildCost">
                </cb-currency>
                <cb-currency name="grossMargin"
                             label="Gross Margin"
                             [required]="false"
                             [readonly]="false"
                             (ngModelChange)="updateGrossMargin($event)"
                             [(ngModel)]="grossMargin">
                </cb-currency>
                <cb-input name="grossMarginPercent"
                          label="Gross Margin %"
                          [required]="true"
                          [readonly]="true"
                          [(ngModel)]="grossMarginPercent">
                </cb-input>
                <cb-currency name="buildPrice"
                             label="Build Price"
                             [required]="true"
                             [(ngModel)]="currentMappedItem.buildPrice">
                </cb-currency>
                <cb-currency name="buildPrice"
                             label="Build Price"
                             [required]="true"
                             [inclGst]="true"
                             [readonly]="true"
                             [(ngModel)]="currentMappedItem.buildPrice">
                </cb-currency>
            </div>
        </div>
        <mat-divider class="cb-margin-bottom"></mat-divider>
        <div class="cb-margin flex-col flex">
            <cb-text-area label="Comments and Assumptions"
                          [(ngModel)]="currentMappedItem.qsNotes"
                          name="qsNotes"
                          [maxlength]="1024">
            </cb-text-area>
            <cb-checkbox name="isEstimatedPrice"
                         label="Price is an Estimate"
                         [(ngModel)]="currentMappedItem.isEstimatedPrice">
            </cb-checkbox>
            <cb-datepicker name="pricingExpiryDate"
                           [(ngModel)]="currentMappedItem.pricingExpiryDate"
                           [minDate]="minDate"
                           pickerType="calendar"
                           label="Price Expiry Date">
            </cb-datepicker>
        </div>
        <footer class="cb-margin-top-2x end-center flex-row">
            <cb-button *ngIf="canSubmitForReview$ | async"
                       [clickLimit]="1"
                       [disabled]="isSaveDisabled$ | async"
                       class="cb-margin-right"
                       (clicked)="submitForReview()">
                Submit for Review
            </cb-button>
            <cb-button *ngIf="canFinalise$ | async"
                       [disabled]="isSaveDisabled$ | async"
                       class="cb-margin-right"
                       (clicked)="finaliseClicked()">
                Finalise
            </cb-button>
            <cb-button (clicked)="cancel()"
                       color>
                Cancel
            </cb-button>
        </footer>
    </form>
</cb-dialog>
