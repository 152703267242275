<cb-dialog>
    <form #form="ngForm">
        <cb-input label="Bank Name"
                  name="bankName"
                  [required]="true"
                  [(ngModel)]="bankName">
        </cb-input>
        <cb-input label="Bank Account Number"
                  name="bankAccountNumber"
                  [required]="true"
                  [maskType]="'BANK'"
                  [(ngModel)]="bankAccountNumber">
        </cb-input>
        <cb-input label="ABM Code"
                  name="abmCode"
                  [required]="true"
                  [(ngModel)]="abmCode">
        </cb-input>
    </form>
    <footer class="center-center flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                [disabled]="!form.dirty || !form.valid || !canSave()"
                (click)="onSaveClicked()">Save</button>
        <button mat-raised-button
                (click)="dialogRef.close()">Cancel</button>
    </footer>
</cb-dialog>
