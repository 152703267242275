<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon"
             [matTooltip]="tooltipMessage.value">
            <mat-icon [ngClass]="cardIconClass.value">{{cardIcon.value}}</mat-icon>
        </div>
        <mat-card-title [ngClass]="cardIconClass.value">{{stepNumber}}. Design</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex flex-col">
        <cb-display-value-new label="Scheme Status"
                              [value]="clientSale?.lot?.schemeStatusLabel"></cb-display-value-new>
        <cb-display-value-new label="Applied Concept"
                              [value]="clientSale?.lot?.appliedConceptLabel"></cb-display-value-new>
    </mat-card-content>
    <mat-card-actions class="end-center flex-row">
        <button [matMenuTriggerFor]="menu"
                mat-stroked-button>
            <mat-icon>arrow_drop_down</mat-icon>
            Reports
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item
                    (click)="downloadPricingTemplate()">
                Pricing Template
            </button>
            <button mat-menu-item
                    (click)="generateSiteEvaluation()">
                Generate Site Evaluation
            </button>
        </mat-menu>
        <button mat-stroked-button
                *ngIf="permissions?.canEdit()"
                [disabled]="!clientSale?.canChangeConcept"
                (click)="changeConcept()"
                color="accent">
            Change Concept
        </button>
    </mat-card-actions>
</mat-card>
