<cb-dialog>
    <form class="flex-col flex"
          #buildOverheadCostForm="ngForm">
        <mat-dialog-content>
            <cb-input type="text"
                      name="name"
                      label="Name"
                      [required]="true"
                      [(ngModel)]="mappedItem.name"></cb-input>
            <cb-input type="text"
                      name="description"
                      label="Description"
                      [required]="true"
                      [(ngModel)]="mappedItem.description"></cb-input>
            <cb-input type="text"
                      name="financeCode"
                      label="Finance Code"
                      [(ngModel)]="mappedItem.financeCode"></cb-input>
            <cb-checkbox name="isActive"
                         label="Is Active"
                         [(ngModel)]="mappedItem.isActive"></cb-checkbox>
        </mat-dialog-content>
        <footer class="cb-margin-top-2x end-center flex-row">
            <button mat-raised-button
                    class="save-button"
                    color="primary"
                    (click)="save()"
                    [disabled]="!buildOverheadCostForm.dirty || !buildOverheadCostForm.valid || saveInProgress">
                Save
            </button>
            <button mat-raised-button
                    (click)="cancel()">
                Cancel
            </button>
        </footer>
    </form>
</cb-dialog>
