import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ImageGalleryComponent } from './image-gallery.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CbImageButtonModule } from '../image-button/image-button.module';

const COMPONENTS = [
    ImageGalleryComponent,
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        CbImageButtonModule
    ],
    exports: COMPONENTS
})

export class CbImageGalleryModule { }
