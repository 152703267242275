import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {DesignComplexityDialogComponent} from './design-complexity-dialog/design-complexity-dialog.component';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule} from 'cb-hub-lib';
import {DesignWorkflowComponent} from './design-workflow/design-workflow.component';
import {DefaultSystemAreaDocumentsComponent} from './default-system-area-documents/default-system-area-documents.component';
import {DesignComplexityComponent} from './design-complexity/design-complexity.component';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {DesignSchemeNotificationsComponent} from './design-scheme-notifications/design-scheme-notifications.component';
import {DesignSchemeNotificationsDialogComponent} from './design-scheme-notifications-dialog/design-scheme-notifications-dialog.component';

@NgModule({
    declarations: [
        DesignComplexityComponent,
        DesignComplexityDialogComponent,
        DesignWorkflowComponent,
        DefaultSystemAreaDocumentsComponent,
        DesignSchemeNotificationsComponent,
        DesignSchemeNotificationsDialogComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbHubTableModule,
        CbTableModule,
        MatTabsModule,
    ],
    exports: [
        DesignWorkflowComponent
    ]
})
export class DesignWorkflowSettingsModule { }
