import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LotArComponent } from './lot-ar.component';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { FormsModule } from '@angular/forms';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbTableModule } from 'cb-hub-lib';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { AREditVariationDialogComponent } from '@app/views/lot/lot-ar/ar-edit-variation-dialog.component/ar-edit-variation-dialog.component';
import { AREditProgressPaymentsDialogComponent } from '@app/views/lot/lot-ar/ar-edit-progresspayments-dialog.component/ar-edit-progresspayments-dialog.component';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { ARViewProgressPaymentHistoryDialogComponent }
    from '@app/views/lot/lot-ar/ar-view-progresspayment-history-dialog.component/ar-view-progresspayment-history-dialog.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatTooltipModule,
        CbDisplayValueModule,
        CbWaitForModule,
        CbDialogModule,
        CbClickModule,
        CbHubTableModule,
        CbTableModule,
        CbFormsModule,
        CbCurrencyModule,
        CbSortByModule
    ],
    exports: [
        LotArComponent
    ],
    declarations: [
        LotArComponent,
        AREditVariationDialogComponent,
        AREditProgressPaymentsDialogComponent,
        ARViewProgressPaymentHistoryDialogComponent
    ]
})
export class LotArModule { }
