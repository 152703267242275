<button mat-raised-button
        mat-icon-button
        *ngIf="!readonly && isCompletedChange()"
        color="primary"
        (click)="completeChangeClicked()">
    <mat-icon>check_box</mat-icon>
</button>

<button mat-raised-button
        mat-icon-button
        *ngIf="!readonly && isNotStartedChange()"
        color="primary"
        (click)="notStartedChangeClicked()">
    <mat-icon>check_box_outline_blank</mat-icon>
</button>

<button mat-raised-button
        mat-icon-button
        *ngIf="!readonly && isNotDoingChange()"
        color="primary"
        (click)="notDoingChangeClicked()">
    <mat-icon>indeterminate_check_box</mat-icon>
</button>

<mat-icon color="primary"
          *ngIf="readonly && isCompletedChange()">check_box</mat-icon>
<mat-icon *ngIf="readonly && isNotStartedChange()">check_box_outline_blank</mat-icon>
<mat-icon color="warn"
          *ngIf="readonly && isNotDoingChange()">indeterminate_check_box</mat-icon>