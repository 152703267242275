import { CommonModule } from '@angular/common';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RootViewsModule } from '../root-views.module';
import { AuthLoadingComponent } from './components/auth-loading/auth-loading.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { EnvDisplayComponent } from './components/env-display/env-display.component';
import { NotificationsDialogComponent } from './components/notifications-dialog/notifications-dialog.component';
import { SidenavMenuComponent } from './components/sidenav-menu/sidenav-menu.component';
import { ToastsModule } from './components/toasts/toasts.module';
import { TopnavTitleComponent } from './components/topnav-title/topnav-title.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AppRootComponent } from './pages/app-root/app-root.component';

const MATERIAL_MODULES = [
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule
];

const DIALOG_COMPONENTS = [
    NotificationsDialogComponent,
];

@NgModule({
    declarations: [
        // Pages
        AppRootComponent,
        // Dialog Components
        ...DIALOG_COMPONENTS,
        // Components
        SidenavMenuComponent,
        TopnavTitleComponent,
        BreadcrumbsComponent,
        EnvDisplayComponent,
        UserProfileComponent,
        AuthLoadingComponent
    ],
    imports: [
        ...MATERIAL_MODULES,
        CommonModule,
        ScrollingModule,
        ToastsModule,
        RootViewsModule,
        RouterModule.forRoot(
            [
                {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: '',
                    data: {
                        breadcrumb: 'Home'
                    },
                    canActivate: [MsalGuard]
                }
            ],
            {
    enableTracing: false,
    useHash: false
}
        ),
    ],
})
export class AppRootModule { }
