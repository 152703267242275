import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CbSideBarModule } from '@app/shared/components/side-bar/side-bar.module';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CbEnumAsLookupModule } from '@app/shared/pipe/enum-as-lookup/enum-as-lookup.module';
import { BusinessEntitiesComponent } from './business-entities.component';
import { BusinessEntityDetailsComponent } from './tabs/business-entity-details/business-entity-details.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule } from 'cb-hub-lib';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { DefaultInstallerDialogComponent } from './tabs/default-installers/default-installer-dialog/default-installer-dialog.component';
import { DefaultSupplierDialogComponent } from './tabs/default-suppliers/default-supplier-dialog/default-supplier-dialog.component';
import { BusinessEntityDefaultInstallersComponent } from './tabs/default-installers/default-installers.component';
import { BusinessEntityDefaultSuppliersComponent } from './tabs/default-suppliers/default-suppliers.component';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { BuildContingencyRecordDialogComponent } from './tabs/business-entity-contingencies/business-entity-contingency-dialog/business-entity-contingency-dialog.component';
import { BusinessEntityBuildContingencyRecordsComponent } from './tabs/business-entity-contingencies/business-entity-contingencies.component';

@NgModule({
    declarations: [
        BusinessEntitiesComponent,
        BusinessEntityDetailsComponent,
        DefaultInstallerDialogComponent,
        DefaultSupplierDialogComponent,
        BuildContingencyRecordDialogComponent,
        BusinessEntityBuildContingencyRecordsComponent,
        BusinessEntityDefaultInstallersComponent,
        BusinessEntityDefaultSuppliersComponent
    ],
    imports: [
        CbWaitForModule,
        CbDisplayValueModule,
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        MatTooltipModule,
        CbSideBarModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatDividerModule,
        CbEnumAsLookupModule,
        InfiniteScrollModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        MatAutocompleteModule,
        CbFormsModule,
        CbHubTableModule,
        CbTableModule
    ],
    exports: [
        BusinessEntitiesComponent,
        DefaultInstallerDialogComponent,
        DefaultSupplierDialogComponent,
        BusinessEntityDefaultInstallersComponent,
        BusinessEntityDefaultSuppliersComponent,
        BuildContingencyRecordDialogComponent,
        BusinessEntityBuildContingencyRecordsComponent
    ]
})
export class BusinessEntitiesSettingsModule { }
