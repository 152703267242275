<td>

    <!-- Included in Flyer / Create Schedule -->
    <mat-icon class="cb-grey cb-help-cursor"
              *ngIf="item.showInClientSpecification"
              aria-hidden="false"
              matTooltip="Included in Flyer / Create Schedule">
        style
    </mat-icon>

    <!-- Included in Colour Schedule -->
    <mat-icon class="cb-grey cb-help-cursor"
              *ngIf="item.showInColourYourDreams"
              svgIcon="book-open-page-variant"
              matTooltip="Included in Colour Schedule">
    </mat-icon>

    <!-- Descriptive -->
    <mat-icon class="cb-grey cb-help-cursor"
              *ngIf="item.costType === costTypeEnum.DescriptiveOnly"
              aria-hidden="false"
              matTooltip="Descriptive Only">
        notes
    </mat-icon>

    <!-- Offering Status -->
    <mat-icon class="mat-primary cb-help-cursor"
              *ngIf="item?.product?.id > 0 && item?.product?.offeringStatus === offeringStatusEnum.Active && item.costType === costTypeEnum.Actual"
              aria-hidden="false"
              matTooltip="Available">
        check_circle
    </mat-icon>
    <mat-icon class="mat-warn cb-help-cursor"
              *ngIf="item?.product?.offeringStatus === offeringStatusEnum.TemporarilyUnavailable && item.costType === costTypeEnum.Actual"
              aria-hidden="false"
              matTooltip="Temporarily Unavailable">
        warning
    </mat-icon>
    <mat-icon class="cb-error cb-help-cursor"
              *ngIf="item?.product?.offeringStatus === offeringStatusEnum.PermanentlyUnavailable && item.costType === costTypeEnum.Actual"
              aria-hidden="false"
              matTooltip="Permanently Unavailable">
        error
    </mat-icon>
</td>
<td>
    <button mat-raised-button
            mat-icon-button
            *ngIf="item?.childItems?.length > 0"
            (click)="expandedParents[item.id] = !expandedParents[item.id]">
        <mat-icon>
            {{ expandedParents[item.id] &&
            item.childItems?.length ?
            'arrow_drop_up' : 'arrow_drop_down'}}
        </mat-icon>
    </button>
</td>
<cb-td-text [value]="item.slotName"></cb-td-text>
<cb-td-list [list]="item.tags"></cb-td-list>
<cb-td-text *ngIf="!lotSpecificationService.hasQuoteLineId(item)"
            [value]="item?.product?.productCode">
</cb-td-text>
<cb-td-text *ngIf="lotSpecificationService.hasQuoteLineId(item)"
            [value]="getQRef()">
</cb-td-text>
<td class="start-center">
    <span class="start-center">
        <cb-td-text [value]="lotSpecificationService.getProductName(item)"></cb-td-text>
        <cb-info-popup [tooltip]="item?.clientFriendlyDescription"></cb-info-popup>
        <span *ngIf="item?.generatedByVariationNumber"
              id="variation"
              class="help-cursor"
              [matTooltip]="'Variation Number: ' + item?.generatedByVariationNumber">VO
            {{item?.generatedByVariationNumber}}
        </span>
    </span>
</td>
<td>
    <cb-td-enum [value]="item.costType"
                [enum]="costTypeEnum">
    </cb-td-enum>
</td>
<td>
    <cb-td-text [value]="item.quantity"></cb-td-text>
</td>
<td *ngIf="!isSkinnyView">
    <button mat-raised-button
            mat-icon-button
            [matMenuTriggerFor]="optionMenu">
        <mat-icon mat-menu-origin>more_horiz</mat-icon>
    </button>
    <mat-menu #optionMenu="matMenu">
        <button mat-menu-item
                *ngIf="lotSpecificationService.hasQuoteLineId(item)"
                (click)="quoteRefsDialog()">
            <mat-icon>pageview</mat-icon>
            Q Refs
        </button>
        <button mat-menu-item
                (click)="editItem()">
            <mat-icon>edit</mat-icon>
            <span>Edit Item</span>
        </button>
        <button mat-menu-item
                *ngIf="lotSpecificationService.hasQuoteLineId(item)"
                (click)="abandonQuoteLine()">
            <mat-icon>remove_circle</mat-icon>
            <span>Abandon Quote Line</span>
        </button>
        <button mat-menu-item
                (click)="removeItem()"
                [disabled]="!item.isDeleteAllowed"
                *ngIf="uiActions.canChangeSpecification()">
            <mat-icon>remove_circle</mat-icon>
            <span>Remove Item</span>
        </button>
    </mat-menu>
</td>
