<cb-table class="cb-margin-2x flex"
          label="Change Records"
          [columns]="displayedColumns"
          [loaded]="changeRecords"
          emptyMessage="There are no Changes related to this RFI">
    <tr cbTableRow
        *ngFor="let changeRecord of changeRecords">
        <td>{{changeRecord.changeNumber}}</td>
        <td>
            {{ COST_NATURE_ENUM[changeRecord.costNature] }}
        </td>
        <td>{{changeRecord.changeDetails | cbDasher}}</td>
        <td>{{changeRecord.assignedTo | cbDasher}}</td>
        <td>
            {{ CHANGE_STATUS_ENUM[changeRecord.changeStatus]}}
        </td>
        <td cbTableShrinkCol
            cbTableStickyCol="end">
            <button mat-icon-button
                    mat-raised-button
                    (click)="viewChangeRecord($event, changeRecord)">
                <mat-icon>pageview</mat-icon>
            </button>
        </td>
    </tr>
</cb-table>
