<div class="flex-col flex-fill"
     style="position: relative; max-width: 280px">
    <button mat-icon-button
            mat-raised-button
            color="accent"
            class="collapse-button"
            (click)="collapsed$.next(!collapsed$.value)"
            [matTooltip]="collapsed$.value ? 'Expand' : 'Collapse'">
        <mat-icon class="collapse-icon">
            {{ collapsed$.value ? 'chevron_left' : 'chevron_right '}}
        </mat-icon>
    </button>
    <mat-card class="collapsible-card flex-col">
        <div class="flex-row"
             *ngIf="(collapsed$ | async)">
            <h1 class="mat-title collapsed-title">Lead Information</h1>
        </div>
        <div class="flex-col flex"
             *ngIf="!(collapsed$ | async)">
            <div class="flex-col">
                <h1 class="mat-title">{{lead?.name}}</h1>
                <div position="relative">
                    <mat-divider></mat-divider>
                </div>
                <div class="search-card-fields cb-margin-top-2x flex-col">
                    <cb-display-value-new label="Status">
                        {{LEAD_STATUS_ENUM[this.lead?.status]}}
                    </cb-display-value-new>
                    <cb-display-value-new label="Reason"
                                          *ngIf="lead?.status === LEAD_STATUS_ENUM.Lost">
                        {{LEAD_LOST_REASON_ENUM[lead?.lostReason]}}
                    </cb-display-value-new>
                    <cb-display-value-new label="Building Consultant">
                        {{lead?.buildingConsultantName}}
                    </cb-display-value-new>
                </div>
            </div>
            <h1 class="mat-title">Main Contact</h1>
            <div position="relative">
                <mat-divider></mat-divider>
            </div>
            <cb-contact-right-side-panel *ngIf="lead?.mainContact"
                                         [contact]="lead?.mainContact"
                                         class="cb-margin-top-2x">
            </cb-contact-right-side-panel>
        </div>
    </mat-card>
</div>
