import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import {
    AutocompleteScrollerComponent,
    BuildProgrammeActivityAutocompleteScrollerComponent,
    CouncilAutocompleteScrollerComponent,
    DocumentTypeAutocompleteScrollerComponent,
    LocationAutocompleteScrollerComponent,
    ProductAutocompleteScrollerComponent,
    TradeTypeAutocompleteScrollerComponent,
    UserAutocompleteScrollerComponent
} from './autocomplete-scroller';
import { ClientAccountAutocompleteScrollerComponent } from './autocomplete-scroller/extensions/client-account/client-account-autocomplete-scroller.component';
import {
    BusinessAccountsInputFindDialogCardsComponent
} from './input-find/dialog/extensions/business-accounts-input-find-dialogs/business-accounts-input-find-dialog-cards.component';
import { ClientAccountsInputFindDialogCardsComponent } from './input-find/dialog/extensions/client-accounts-input-find-dialogs/client-accounts-input-find-dialog-cards.component';
import { ContactsInputFindDialogCardsComponent } from './input-find/dialog/extensions/contacts-input-find-dialogs/contacts-input-find-dialog-cards.component';
import { LeadsInputFindDialogCardsComponent } from './input-find/dialog/extensions/leads-input-find-dialogs/leads-input-find-dialog-cards.component';
import { UsersInputFindDialogTableComponent } from './input-find/dialog/extensions/users-input-find-dialogs/users-input-find-dialog-table.component';
import { UsersInputFindTableComponent } from './input-find/extensions/users-input-find-components/users-input-find-table.component';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { BrowserModule } from '@angular/platform-browser';
import { IgnoreDirtyDirective } from '@app/shared/components/forms/ignore-dirty.directive';
import { NZ_LOCALE } from '@app/shared/declarations/app.constants';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbFillHeightModule } from '@app/shared/directives/fill-height/fill-height.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import { IsInvalidValidatorDirective } from '@app/shared/validators/is-invalid.validator';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { CbLoadingSpinnerModule, CbTableModule } from 'cb-hub-lib';
import { DragulaModule } from 'ng2-dragula';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CbDialogModule } from '../dialog/dialog.module';
import { CbDisplayValueModule } from '../display-value/display-value.module';
import { CbBusinessAccountCardModule } from '../search-results/cards/business-account-card/business-account-card.module';
import { CbClientAccountInputFindCardModule } from '../search-results/cards/client-account-input-find-card/client-account-input-find-card.module';
import { CbContactCardModule } from '../search-results/cards/contact-card/contact-card.module';
import { CbLeadCardModule } from '../search-results/cards/lead-card/lead-card.module';
import { CbSellingAgentInputFindCardModule } from '../search-results/cards/sellingagent-input-find-card/sellingagent-input-find-card.module';
import { CbVendorInputFindCardModule } from '../search-results/cards/vendor-input-find-card/vendor-input-find-card.module';
import { CbHubTableModule } from '../table/table.module';
import { AddressComponent } from './address/address.component';
import { AgreeToConditionComponent } from './agree-to-condition/agree-to-condition.component';
import { BuildActivityAutocompleteScrollerComponent } from './autocomplete-scroller/extensions/build-acitivity/build-activity-autocomplete-scroller.component';
import { BusinessAccountAutocompleteScrollerComponent } from './autocomplete-scroller/extensions/business-account/business-account-autocomplete-scroller.component';
import { ContactAutocompleteScrollerComponent } from './autocomplete-scroller/extensions/contact/contact-autocomplete-scroller.component';
import { LotAutocompleteScrollerComponent } from './autocomplete-scroller/extensions/lot/lot-autocomplete-scroller.component';
import { ProductCategoryAutocompleteScrollerComponent } from './autocomplete-scroller/extensions/product-category/product-category-autocomplete-scroller.component';
import { SlotAutocompleteScrollerComponent } from './autocomplete-scroller/extensions/slot/slot-autocomplete-scroller.component';
import { StandardPlantAutocompleteScrollerComponent } from './autocomplete-scroller/extensions/standard-plan/standard-plan-autocomplete-scroller.component';
import { SupplyTypeAutocompleteScrollerComponent } from './autocomplete-scroller/extensions/supply-type/supply-type-autocomplete-scroller.component';
import { OptionsScrollDirective } from './autocomplete-scroller/options-scroll.directive';
import { RequireMatchValidatorDirective } from './autocomplete-scroller/require-match-validator.directive';
import { CategoryFilterComponent } from './category-filter/category-filter.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CollectionBuilderComponent } from './collection-builder/collection-builder.component';
import { CurrencyComponent } from './currency/currency.component';
import { TwoDpDirective } from './currency/two-dp.directive';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DesignSchemeLineStatusCheckboxComponent } from './design-scheme-line-status-checkbox/design-scheme-line-status-checkbox.component';
import { DisplayPillComponent } from './display-pill/display-pill.component';
import { InlineTextAreaComponent } from './inline-text-area/inline-text-area.component';
import { SellingAgentsInputFindDialogCardsComponent } from './input-find/dialog/extensions/selling-agents/sellingagents-input-find-dialog-cards.component';
import { VendorsInputFindDialogCardsComponent } from './input-find/dialog/extensions/vendors/vendors-input-find-dialog-cards.component';
import { BusinessAccountsInputFindCardsComponent } from './input-find/extensions/business-accounts-input-find-components/business-accounts-input-find-cards.component';
import { ClientAccountsInputFindCardsComponent } from './input-find/extensions/client-accounts-input-find-components/client-accounts-input-find-cards.component';
import { ContactsInputFindCardsComponent } from './input-find/extensions/contacts-input-find-components/contacts-input-find-cards.component';
import { LeadsInputFindCardsComponent } from './input-find/extensions/leads-input-find-components/leads-input-find-cards.component';
import { SellingAgentsInputFindCardsComponent } from './input-find/extensions/sellingagents-input-find-components/sellingagents-input-find-cards.component';
import { VendorsInputFindCardsComponent } from './input-find/extensions/vendors-input-find-components/vendors-input-find-cards.component';
import { IconInputComponent } from './input/icon-input/icon-input.component';
import { InputComponent } from './input/input.component';
import { MaskTypeDirective } from './input/mask-type.directive';
import { NullableBooleanComponent } from './nullable-boolean/nullable-boolean.component';
import { SelectItemsAutocompleteScrollerComponent } from './select-items-autocomplete-scroller/select-items-autocomplete-scroller.component';
import { SelectItemsDialogComponent } from './select-items-dialog/select-items-dialog.component';
import { SelectItemsStandardSelectComponent } from './select-items-standard-select/select-items-standard-select.component';
import { GarageSelectComponent } from './select/extensions/garage-select.component';
import { RequireSelectionValidatorDirective } from './select/require-selection-validator.directive';
import { SelectComponent } from './select/select.component';
import { RequireSelectionListCheckedValidatorDirective } from './selection-list/require-selection-list-checked-validator.directive';
import { SelectionListComponent } from './selection-list/selection-list.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { ThreeStateCheckboxComponent } from './three-state-checkbox/three-state-checkbox.component';
import { YearMonthPickerComponent } from './year-month-picker/year-month-picker.component';

const EXPORTED_COMPONENTS = [
    IconInputComponent,
    CollectionBuilderComponent,
    BusinessAccountsInputFindCardsComponent,
    ClientAccountsInputFindCardsComponent,
    LeadsInputFindCardsComponent,
    ContactsInputFindDialogCardsComponent,
    VendorsInputFindDialogCardsComponent,
    SellingAgentsInputFindDialogCardsComponent,
    UsersInputFindDialogTableComponent,
    UsersInputFindTableComponent,
    ContactsInputFindCardsComponent,
    VendorsInputFindCardsComponent,
    SellingAgentsInputFindCardsComponent,
    InputComponent,
    TextAreaComponent,
    AddressComponent,
    SelectComponent,
    SelectItemsDialogComponent,
    SelectItemsStandardSelectComponent,
    DatepickerComponent,
    CheckboxComponent,
    ThreeStateCheckboxComponent,
    CurrencyComponent,
    SelectionListComponent,
    AutocompleteScrollerComponent,
    SelectItemsAutocompleteScrollerComponent,
    ProductCategoryAutocompleteScrollerComponent,
    SlotAutocompleteScrollerComponent,
    DocumentTypeAutocompleteScrollerComponent,
    UserAutocompleteScrollerComponent,
    ContactAutocompleteScrollerComponent,
    BuildProgrammeActivityAutocompleteScrollerComponent,
    ProductAutocompleteScrollerComponent,
    TradeTypeAutocompleteScrollerComponent,
    SupplyTypeAutocompleteScrollerComponent,
    RequireMatchValidatorDirective,
    IsInvalidValidatorDirective,
    CouncilAutocompleteScrollerComponent,
    LocationAutocompleteScrollerComponent,
    IgnoreDirtyDirective,
    MaskTypeDirective,
    BusinessAccountAutocompleteScrollerComponent,
    BuildActivityAutocompleteScrollerComponent,
    BusinessAccountsInputFindDialogCardsComponent,
    ClientAccountsInputFindDialogCardsComponent,
    LeadsInputFindDialogCardsComponent,
    InlineTextAreaComponent,
    CategoryFilterComponent,
    YearMonthPickerComponent,
    LotAutocompleteScrollerComponent,
    ClientAccountAutocompleteScrollerComponent,
    GarageSelectComponent,
    NullableBooleanComponent,
    StandardPlantAutocompleteScrollerComponent,
    DesignSchemeLineStatusCheckboxComponent,
    AgreeToConditionComponent
];

@NgModule({
    declarations: [
        ...EXPORTED_COMPONENTS,
        TwoDpDirective,
        RequireSelectionValidatorDirective,
        RequireSelectionListCheckedValidatorDirective,
        OptionsScrollDirective,
        NullableBooleanComponent,
        IconInputComponent,
        ThreeStateCheckboxComponent,
        DisplayPillComponent
    ],
    imports: [
        CbDisplayValueModule,
        CbLoadingSpinnerModule,
        CommonModule,
        BrowserModule,
        DragulaModule,
        CbBusinessAccountCardModule,
        CbClientAccountInputFindCardModule,
        CbVendorInputFindCardModule,
        CbSellingAgentInputFindCardModule,
        CbLeadCardModule,
        CbContactCardModule,
        CbClickModule,
        FormsModule,
        MatInputModule,
        MatTooltipModule,
        MatListModule,
        CbWaitForModule,
        MatSelectModule,
        MatDatepickerModule,
        MatIconModule,
        MatCheckboxModule,
        InfiniteScrollModule,
        MatTableModule,
        MatButtonModule,
        CbDialogModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        CbHubTableModule,
        CbTableModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        CbCurrencyModule,
        CbFillHeightModule,
        CbDatePipeModule
    ],
    providers: [
        { provide: OWL_DATE_TIME_LOCALE, useValue: NZ_LOCALE },
        IconInputComponent,
        InputComponent
    ],
    exports: [
        ...EXPORTED_COMPONENTS,
        DisplayPillComponent
    ]
})

export class CbFormsModule { }
