<button mat-button
        class="mat-icon-button mat-raised"
        *ngIf="edit && isCompletedChange()"
        (click)="completeChangeClicked()">
    <mat-icon class="mat-primary">check_box</mat-icon>
</button>
<button mat-button
        class="mat-icon-button mat-raised"
        *ngIf="edit && isNotStartedChange()"
        (click)="notStartedChangeClicked()">
    <mat-icon>check_box_outline_blank</mat-icon>
</button>
<mat-icon class="mat-primary"
          *ngIf="!edit && isCompletedChange()">check_box</mat-icon>
<mat-icon *ngIf="!edit && isNotStartedChange()">check_box_outline_blank</mat-icon>
