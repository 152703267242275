<div class="flex-row flex"
     style="overflow-x: auto">
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x flex-col flex">
        <div class="start-center flex-row">
            <h3 class="mat-title flex">
                Schedule Item External References
            </h3>
            <button class="header-button"
                    mat-raised-button
                    (click)="addItem()"
                    color="primary">
                Add External Field Value
            </button>
        </div>
        <form #slotExternalSystemFieldValueForm="ngForm">
            <div class="search-filter-row flex-row flex">
                <div class="cb-margin-top cb-margin-bottom cb-margin-right flex-row flex">
                    <mat-icon class="cb-margin-top-2x cb-margin-right">
                        search
                    </mat-icon>
                    <mat-form-field class="flex">
                        <input matInput
                               placeholder="Type in External Field Value or SIM name to search"
                               name="query"
                               [(ngModel)]="userCacheItem.data.query"/>
                    </mat-form-field>
                </div>
                <div class="cb-margin-top cb-margin-bottom cb-margin-right flex">
                    <cb-select name="externalSystemId"
                               label="External System"
                               [options]="externalSystemTypes"
                               [(ngModel)]="userCacheItem.data.externalSystemId"
                               (change)="loadExternalSystemFields()">
                    </cb-select>
                </div>
                <div class="cb-margin-top cb-margin-bottom cb-margin-right flex">
                    <cb-select name="externalFieldId"
                               label="External Field Name"
                               [options]="externalFieldOptions"
                               [(ngModel)]="userCacheItem.data.externalFieldId">
                    </cb-select>
                </div>
                <div class="cb-margin-top cb-margin-bottom cb-margin-right flex">
                    <mat-form-field>
                        <mat-label>
                            SIM Status
                        </mat-label>
                        <mat-select [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="userCacheItem.data.activeSlotsOnly">
                            <mat-option [value]>
                                Any
                            </mat-option>
                            <mat-option [value]="true">
                                Active
                            </mat-option>
                            <mat-option [value]="false">
                                Inactive
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <cb-table label="Schedule Item External References"
                  maxHeightOffset="250"
                  [columns]="['House Area','Schedule Item Name', 'SIM Active', 'External System', 'External Field Name', 'External Field Value', '']"
                  [loaded]="true"
                  [infiniteScrollEnabled]="true"
                  [fetch]="fetchResults()"
                  [queryUpdated]="queryUpdate"
                  [(currentPage)]="currentPage"
                  [(results)]="searchResults">
            <tbody cbTableBody>
                <tr *ngFor="let item of searchResults">
                    <cb-td-text [value]="item?.houseArea"></cb-td-text>
                    <cb-td-text [value]="item?.slotName"></cb-td-text>
                    <cb-td-checkbox [value]="item?.slotIsActive"></cb-td-checkbox>
                    <cb-td-enum [value]="item?.externalSystem"
                                [enum]="EXTERNAL_SYSTEM_ENUM"></cb-td-enum>
                    <cb-td-text [value]="item?.externalFieldName"></cb-td-text>
                    <cb-td-text [value]="item?.fieldValue"></cb-td-text>
                    <td class="layoutGap-10"
                        cbTableShrinkCol
                        cbTableStickyCol="end">
                        <button mat-raised-button
                                mat-icon-button
                                (click)="editItem(item)">
                            <mat-icon>
                                edit
                            </mat-icon>
                        </button>
                        <button mat-raised-button
                                mat-icon-button
                                (click)="deleteItem(item)">
                            <mat-icon>
                                delete
                            </mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </cb-table>
    </div>
</div>
