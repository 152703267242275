import {Component, Inject, OnInit} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BUILD_STAGE_EVENT_ENUM, ILotProgressPaymentHistoryDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ARProgressPaymentsLogicService } from '@app/logic/ar-progress-payments/ar-progress-payments.logic-service';

interface IData {
    mappedItem: number;
}

@Component({
    selector: 'ar-view-progresspayment-history-dialog',
    templateUrl: './ar-view-progresspayment-history-dialog.component.html',
    styleUrls: ['./ar-view-progresspayment-history-dialog.component.scss'],
})

export class ARViewProgressPaymentHistoryDialogComponent implements OnInit{
    public static readonly MIN_WIDTH = '75%';
    public static readonly MAX_HEIGHT = '90vh';
    public BUILD_STAGE_EVENT_ENUM = BUILD_STAGE_EVENT_ENUM;
    public readonly displayedColumns = [
        'Event/Stage',
        'Previous Amount',
        'New Amount',
        'Date of Change',
        'Updated By',
        'Reason for Change'
    ];
    public paymentHistoryItems: ILotProgressPaymentHistoryDto[];
    public get lotId(): number {
        return this.data.mappedItem;
    }
    public ngOnInit(): void {
        this.progressPaymentsLogicService.getProgressPaymentHistory(this.lotId)
            .subOnce(x => this.paymentHistoryItems = x);
    }
    constructor(
        public readonly dialogRef: MatDialogRef<ARViewProgressPaymentHistoryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly progressPaymentsLogicService: ARProgressPaymentsLogicService
    ) {
    }
    public closeDialog(): void {
        return this.dialogRef.close();
    }

}
