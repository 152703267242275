import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductInputFindDialogComponent } from './product-input-find-dialog/product-input-find-dialog.component';
import { ProductInputFindComponent } from './product-input-find.component';
import { FormsModule } from '@angular/forms';
import { ProductsModule } from '@app/views/products/products.module';
import { CbFormsModule } from '../../forms.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';



@NgModule({
    declarations: [
        ProductInputFindDialogComponent,
        ProductInputFindComponent,
    ],
    exports: [
        ProductInputFindComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ProductsModule,
        CbFormsModule,
        MatButtonModule,
        CbDialogModule,
        MatIconModule,
        MatFormFieldModule,
    ]
})
export class CbProductInputFindModule { }
