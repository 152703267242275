<div class="cb-margin">
    <mat-card class="cb-margin-bottom flex-col">
        <form #form="ngForm">
            <div class="start-center flex-row flex">
                <mat-icon>search</mat-icon>
                <cb-input type="text"
                          label="Search"
                          [(ngModel)]="searchFilters.data.query"
                          (ngModelChange)="this.searchFiltersChanged$.next($event)"
                          name="search"
                          class="cb-margin-right-2x flex">
                </cb-input>
                <cb-select label="Assigned To"
                           name="userId"
                           *ngIf="qsTeamPermissions.canSeeAll()"
                           [(ngModel)]="searchFilters.data.userId"
                           [options]="teamUsersOptions$ | async"
                           class="cb-margin-right-2x">
                </cb-select>
                <cb-select label="Order"
                           name="resultOrders"
                           [(ngModel)]="searchFilters.data.order"
                           [options]="resultOrders$ | async"
                           class="cb-margin-right-2x">
                </cb-select>
                <cb-select class="cb-margin-right"
                           label="Direction"
                           [(ngModel)]="searchFilters.data.direction"
                           name="direction"
                           [options]="resultDirections$ | async">
                </cb-select>
                <cb-checkbox label="Awaiting Response Only"
                             name="awaitingResponseOnly"
                             class="cb-margin-top-2x"
                             [(ngModel)]="searchFilters.data.awaitingResponseOnly">
                </cb-checkbox>
            </div>
        </form>
        <cb-table class="cb-margin-top flex"
                  *ngIf="searchFiltersLoaded && searchEnabled"
                  label="Questions"
                  maxHeightOffset="280"
                  [infiniteScrollEnabled]="true"
                  [fetch]="fetchResults()"
                  [loaded]="loaded"
                  [queryUpdated]="searchFiltersChanged$"
                  [(results)]="results"
                  [(currentPage)]="currentPage">
            <thead cbTableHead>
                <tr>
                    <td>System Area</td>
                    <td>Name</td>
                    <td>Job #</td>
                    <td>lot #</td>
                    <td>Project</td>
                    <td>Address</td>
                    <td>Client Account Name</td>
                    <td>Related</td>
                    <td>Question #</td>
                    <td>Created</td>
                    <td>Created By</td>
                    <td>Sent To</td>
                    <td>Response Date</td>
                    <td></td>
                </tr>
            </thead>
            <tbody cbTableBody>
                <tr *ngFor="let question of results">
                    <cb-td-text [value]="question.systemAreaName"></cb-td-text>
                    <cb-td-text [value]="question.entityName"></cb-td-text>
                    <cb-td-text [value]="question.jobNumber"></cb-td-text>
                    <cb-td-text [value]="question.lotNumber"></cb-td-text>
                    <cb-td-text [value]="question.projectName"></cb-td-text>
                    <cb-td-text value="{{question.addressStreet}}, {{question.addressCity}}"></cb-td-text>
                    <cb-td-text [value]="question.clientAccountName"></cb-td-text>
                    <cb-td-text [value]="question.childEntityName"></cb-td-text>
                    <cb-td-text [value]="question.questionNumber"></cb-td-text>
                    <cb-td-date [value]="question.createdDate"></cb-td-date>
                    <cb-td-text [value]="question.createdByUser"></cb-td-text>
                    <cb-td-text [value]="question.assignedToUserName"></cb-td-text>
                    <cb-td-date [value]="question.responseDate"></cb-td-date>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <cb-button [menu]="menu"></cb-button>
                        <mat-menu #menu="matMenu">
                            <cb-button [isMenuItem]="true"
                                       icon="edit"
                                       menuItemLabel="Edit"
                                       [disabled]="!(canEdit$(question) | async)"
                                       (clicked)="editQuestion(question)">
                            </cb-button>
                            <cb-button [isMenuItem]="true"
                                       icon="reply"
                                       menuItemLabel="Respond"
                                       [disabled]="!(canRespond$(question) | async)"
                                       (clicked)="respondQuestion(question)">
                            </cb-button>
                            <cb-button [isMenuItem]="true"
                                       icon="cancel"
                                       menuItemLabel="Cancel Question"
                                       [disabled]="!(canEdit$(question) | async)"
                                       (clicked)="cancelQuestion(question)">
                            </cb-button>
                            <cb-button [isMenuItem]="true"
                                       icon="pageview"
                                       menuItemLabel="View Lot"
                                       (clicked)="viewLot(question.lotId)">
                            </cb-button>
                        </mat-menu>
                    </td>
                </tr>
            </tbody>
        </cb-table>
    </mat-card>
</div>
