<cb-table class="cb-margin-top"
          label="Supply Types"
          [columns]="columns"
          [loaded]="loaded"
          [minWidth]="1000"
          [maxHeightOffset]="271">
    <tbody cbTableBody>
    <ng-container *ngFor="let supplyType of orderSupplyTypes(supplyTypes)">
        <tr>
            <td>
                <button mat-raised-button
                        mat-icon-button
                        (click)="expanded[supplyType.id] = !expanded[supplyType.id]"
                        *ngIf="supplyType.mappedChildren?.length > 0">
                    <mat-icon>
                        {{expanded[supplyType.id] ? 'arrow_drop_up' :
                        'arrow_drop_down'}}
                    </mat-icon>
                </button>
            </td>
            <td>
                {{supplyType.label}}
                <span *ngIf="!supplyType.isActive">
                        &nbsp;(Inactive)
                    </span>
            </td>
            <td>
                -
            </td>
            <cb-td-checkbox [value]="supplyType.isActive"></cb-td-checkbox>
            <td cbTableStickyCol="end"
                cbTableShrinkCol>
                <button mat-raised-button
                        mat-icon-button
                        (click)="editSupplyType(supplyType)"
                        [disabled]="!permissions?.canEdit()">
                    <mat-icon>
                        edit
                    </mat-icon>
                </button>
            </td>
        </tr>
        <ng-container *ngIf="expanded[supplyType.id]">
            <ng-container *ngFor="let child of orderSupplyTypes(supplyType?.filteredChildren)">
                <tr class="child">
                    <td></td>
                    <td class="childLabel">
                        {{child.label}}
                    </td>
                    <td *ngIf="child.requiredComplianceRegistrations.length > 0">
                        <ul class="inline-list">
                            <li *ngFor="let complianceReg of child.requiredComplianceRegistrations">
                                {{complianceReg.label}}
                            </li>
                        </ul>
                    </td>
                    <td *ngIf="child.requiredComplianceRegistrations.length < 1">
                        -
                    </td>
                    <cb-td-checkbox [value]="child.isActive"></cb-td-checkbox>
                    <td cbTableStickyCol="end"
                        cbTableShrinkCol>
                        <button mat-icon-button
                                mat-raised-button
                                (click)="editSupplyType(child)">
                            <mat-icon>
                                edit
                            </mat-icon>
                        </button>
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </ng-container>
    </tbody>
</cb-table>
