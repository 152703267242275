<mat-card class="cb-margin scroll-container flex-col flex">
    <form class="flex-row flex">
        <div class="flex-wrap flex flex-66"
             #col12>
            <div class="flex flex-50"
                 #col1>
                <div class="flex-col"
                     *ngIf="account.id && !isEditing$.value">
                    <div class="cb-title">{{account.tradingName}}</div>
                    <hr class="mat-divider"/>
                </div>
                <cb-input *ngIf="!account.id || isEditing$.value"
                          label="Trading Name"
                          name="tradingName"
                          [required]="true"
                          [maxlength]="150"
                          [(ngModel)]="account.tradingName"></cb-input>
                <div class="cb-margin-top flex-col">
                    <cb-input label="Legal Name"
                              name="businessName"
                              [required]="true"
                              [maxlength]="150"
                              [readonly]="account.id && !isEditing$.value"
                              [(ngModel)]="account.businessName"></cb-input>
                    <cb-input label="Phone Number"
                              name="phoneNumber"
                              [required]="true"
                              [maskType]="'LANDLINE'"
                              [readonly]="account.id && !isEditing$.value"
                              [(ngModel)]="account.phoneNumber"></cb-input>
                    <cb-input label="Email"
                              name="email"
                              [required]="true"
                              [maxlength]="200"
                              [readonly]="account.id && !isEditing$.value"
                              [(ngModel)]="account.email"></cb-input>
                </div>
                <div class="cb-margin-top-2x flex-col">
                    <cb-checkbox *ngIf="isCreate$.value || (account.id && account.isParentAccount)"
                                 label="Parent Account"
                                 name="isParentAccount"
                                 [readonly]="!isCreate$.value"
                                 [(ngModel)]="account.isParentAccount"
                                 (change)="account.parentAccountId=null;account.isRatesOnly=null;"></cb-checkbox>
                    <cb-checkbox
                            *ngIf="((isCreate$.value || isEditing$.value) && account.isParentAccount ) || account.isRatesOnly"
                            label="Rates Only"
                            name="isRatesOnly"
                            [readonly]="account.id && !isEditing$.value"
                            [(ngModel)]="account.isRatesOnly"></cb-checkbox>
                    <cb-select
                            *ngIf="(isCreate$.value && !account.isParentAccount) || (account.id && account.parentAccountId)"
                            label="Parent Business Account"
                            name="parentAccountId"
                            [required]="true"
                            [readonly]="account.id && !isEditing$.value"
                            [(ngModel)]="account.parentAccountId"
                            [options]="availableParentAccounts"></cb-select>
                </div>
                <div *ngIf="(isCreate$.value || isEditing$.value) && account.isParentAccount"
                     class="cb-margin-top-2x flex-col">
                    <div class="mat-subheading-2 required">Required Documents will be maintained on:</div>
                    <mat-radio-group class="flex-col"
                                     name="parentDocsMaintainedOn"
                                     [required]="true"
                                     [(ngModel)]="account.parentDocsMaintainedOn">
                        <mat-radio-button [value]="1">Both Parent and Child Accounts</mat-radio-button>
                        <mat-radio-button [value]="2">Parent Account Only</mat-radio-button>
                        <mat-radio-button [value]="3">Child Accounts Only</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="flex-col"
                     *ngIf="account.id && !isEditing$.value && account.isParentAccount">
                    <p>
                        <mat-icon class="mat-accent">info</mat-icon>
                        &nbsp;Required documents will be maintained on
                        <span *ngIf="account.shouldMaintainRequiredDocumentsOnChildAccounts && account.shouldMaintainRequiredDocumentsOnParentAccount">
                            both Parent and Child Accounts.</span>
                        <span *ngIf="!account.shouldMaintainRequiredDocumentsOnChildAccounts && account.shouldMaintainRequiredDocumentsOnParentAccount">
                            Parent Account only.</span>
                        <span *ngIf="account.shouldMaintainRequiredDocumentsOnChildAccounts && !account.shouldMaintainRequiredDocumentsOnParentAccount">
                            Child Account only.</span>
                        <span class="cb-warn"
                              *ngIf="!account.shouldMaintainRequiredDocumentsOnChildAccounts && !account.shouldMaintainRequiredDocumentsOnParentAccount">
                            : data is invalid as neither flag is set.</span>
                    </p>
                </div>
                <div class="cb-margin-top-2x flex-col">
                    <mat-label>Account Type</mat-label>
                    <cb-checkbox *ngIf="!account.id || account.canBeSupplier()"
                                 label="Supplier"
                                 name="isSupplier"
                                 [readonly]="account.id && !isEditing$.value"
                                 [(ngModel)]="account.isSupplier"></cb-checkbox>
                    <cb-checkbox *ngIf="!account.id || account.canBeInstaller()"
                                 label="Installer"
                                 name="isInstaller"
                                 [readonly]="account.id && !isEditing$.value"
                                 [(ngModel)]="account.isInstaller"
                                 (change)="account.tradeTypes=[]"></cb-checkbox>
                </div>
                <div class="cb-margin-top-2x flex-col">
                    <span class="mat-title">Payment Details</span>
                    <cb-display-value-new *ngIf="account.id && !isEditing$.value"
                                          label="Payment Frequency"
                                          class="cb-margin-top"
                                          [value]="account.paymentFrequency | cbEnumToLabel: PAYMENT_FREQUENCY_ENUM">
                    </cb-display-value-new>
                    <cb-user-date-time-new
                            *ngIf="account.id && !isEditing$.value && account.sevenDayPaymentRequest && account.paymentFrequency === PAYMENT_FREQUENCY_ENUM.SevenDays"
                            [label]="'Approved by'"
                            [userFullName]="account.sevenDayPaymentRequest.processedBy"
                            [date]="account.sevenDayPaymentRequest.processedDate"
                            [displayTime]="true"
                            [floatingLabel]="true">
                    </cb-user-date-time-new>
                    <cb-input label="Bank Name"
                              name="bankName"
                              [required]="true"
                              [maxlength]="50"
                              [readonly]="account.id"
                              [(ngModel)]="account.bankName">
                    </cb-input>
                    <cb-input label="Bank Account Number"
                              name="bankAccountNumber"
                              [required]="true"
                              [maskType]="'BANK'"
                              [minlength]="18"
                              [maxlength]="19"
                              [readonly]="account.id"
                              [(ngModel)]="account.bankAccountNumber">
                    </cb-input>
                    <cb-input *ngIf="account.id"
                              label="ABM Code"
                              name="abmCode"
                              [required]="true"
                              [maxlength]="50"
                              [readonly]="true"
                              [(ngModel)]="account.abmCode">
                    </cb-input>
                    <mat-radio-group *ngIf="!account.id"
                                     name="gstNumber"
                                     class="gst-number"
                                     [(ngModel)]="account.hasGstNumber">
                        <mat-radio-button [value]="true">
                            <cb-input label="GST Number"
                                      name="gstNumber"
                                      [maxlength]="11"
                                      [maskType]="'GST'"
                                      [(ngModel)]="account.gstNumber"
                                      (click)="account.hasGstNumber=true"></cb-input>
                        </mat-radio-button>
                        <br/>
                        <mat-radio-button [value]="false"
                                          (click)="account.gstNumber=undefined">Not GST Registered
                        </mat-radio-button>
                    </mat-radio-group>
                    <cb-input *ngIf="(account.id && account.gstNumber) || isEditing$.value"
                              label="GST Number"
                              name="gstNumber"
                              [required]="true"
                              [maxlength]="11"
                              [maskType]="'GST'"
                              [readonly]="account.id && !isEditing$.value"
                              [(ngModel)]="account.gstNumber">
                    </cb-input>
                    <cb-display-value-new *ngIf="account.id && !isEditing$.value && !account.gstNumber"
                                          label="GST Number"
                                          class="cb-margin-top"
                                          [value]="'Not Gst Registered'">
                    </cb-display-value-new>
                    <cb-select *ngIf="!account.id && isCreate$.value"
                               label="Requested by"
                               name="requestedById"
                               class="cb-margin-top"
                               [required]="true"
                               [(ngModel)]="account.requestedById"
                               [options]="availableRequestUsers"></cb-select>
                </div>
                <div class="flex-col"
                     *ngIf="account.id && !isEditing$.value && account.sevenDayPaymentRequest && account.sevenDayPaymentRequest.outcome != BUSINESS_ACCOUNT_SEVEN_DAY_PAYMENT_REQUEST_OUTCOME_ENUM.Approved">
                    <div class="flex-row">
                        <mat-icon class="mat-warn cb-margin-right">warning</mat-icon>
                        <span class="mat-subheading-2 cb-no-margin-bottom cb-warn flex">Seven day Payment Requested</span>
                    </div>
                    <hr class="mat-divider cb-margin-bottom"/>
                    <cb-user-date-time-new [label]="'Requested by'"
                                           [userFullName]="account.sevenDayPaymentRequest.requestedBy"
                                           [date]="account.sevenDayPaymentRequest.requestedDate"
                                           [displayTime]="true"
                                           [floatingLabel]="true"></cb-user-date-time-new>
                    <p class="cb-padding-left cb-margin-bottom">{{account.sevenDayPaymentRequest.description}}</p>
                    <div class="flex-row"
                         *ngIf="!account.sevenDayPaymentRequest.outcome">
                        <span class="flex">
                            <button mat-stroked-button
                                    color="warn"
                                    (click)="onApproveSevenDaysClicked()">
                                Approve
                            </button>
                        </span>
                        <span>
                            <button mat-stroked-button
                                    color="warn"
                                    (click)="onApproveSevenDaysClicked(true)">
                                Decline
                            </button>
                        </span>
                    </div>
                    <cb-user-date-time-new *ngIf="account.sevenDayPaymentRequest.outcome"
                                           class="cb-warn"
                                           [label]="'Declined by'"
                                           [userFullName]="account.sevenDayPaymentRequest.processedBy"
                                           [date]="account.sevenDayPaymentRequest.processedDate"
                                           [displayTime]="true"></cb-user-date-time-new>
                </div>
            </div>
            <div #col2
                 class="cb-padding-left-2x flex">
                <cb-address-new name="physicalAddress"
                                [addressTitle]="'Physical Address'"
                                [readonly]="account.id && !isEditing$.value"
                                [required]="true"
                                [(ngModel)]="account.physicalAddress"></cb-address-new>
                <div class="cb-margin-top-2x">
                    <cb-address-new *ngIf="account.postalAddress?.id && account.id && !isEditing$.value"
                                    name="postalAddressView"
                                    [addressTitle]="'Postal Address'"
                                    [readonly]="true"
                                    [ngModel]="account.postalAddress"></cb-address-new>
                    <cb-address-new *ngIf="!account.postalAddress?.id && account.id && !isEditing$.value"
                                    name="postalAddressPlaceholder"
                                    [addressTitle]="'Postal Address'"
                                    [readonly]="true"
                                    [ngModel]="account.physicalAddress"></cb-address-new>
                </div>
                <div class="cb-margin-top-2x">
                    <span class="mat-title">Account Trade Types</span>
                    <div *ngIf="(!account.id || isEditing$.value) && account.isInstaller">
                        <div *ngFor="let parent of availableTradeTypes"
                             class="cb-margin-top flex-col">
                            <button class="trade-type-heading center-center flex-row flex"
                                    (click)="parent.isOpen=!parent.isOpen">
                                <span class="mat-subheading-2 cb-no-margin-bottom cb-margin-right-2x">{{parent.label}}</span>
                                <hr class="mat-divider flex"/>
                                <mat-icon>{{parent.isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
                            </button>
                            <div *ngIf="parent.isOpen"
                                 class="list cb-padding-left cb-padding-top">
                                <cb-checkbox *ngFor="let child of parent.children"
                                             [label]="child.label"
                                             [name]="'tradeType'+child.id"
                                             [ngModel]="isTradeTypeRegionChecked(child)"
                                             (change)="onTradeTypeChanged(child, $event)"></cb-checkbox>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="account.id && !isEditing$.value && account.isInstaller">
                        <div *ngFor="let parent of readonlyTradeTypes"
                             class="cb-margin-top flex-col">
                            <button class="trade-type-heading center-center flex-row flex"
                                    (click)="parent.isOpen=!parent.isOpen">
                                <span class="mat-subheading-2 cb-no-margin-bottom cb-margin-right-2x">{{parent.label}}</span>
                                <hr class="mat-divider flex"/>
                                <mat-icon>{{parent.isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
                            </button>
                            <ul *ngIf="parent.isOpen"
                                class="list">
                                <li *ngFor="let child of parent.children"
                                    class="list cb-padding-left cb-padding-top">
                                    {{child.label}} -
                                    {{child.status | cbEnumToLabel: BUSINESS_ACCOUNT_TRADE_TYPE_STATUS_ENUM}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <cb-info-message *ngIf="!account.isInstaller">
                        [message]="'Not an installer'"
                        [icon]="'info'"></cb-info-message>
                </div>

                <div *ngIf="isSupplierTypesEnabled && !isEditing$.value" class="cb-margin-top-2x">
                    <span class="mat-title">Account Supply Types</span>
                    <div *ngIf="account.id && account.isSupplier">
                        <div *ngFor="let parent of readonlySupplyTypes"
                             class="cb-margin-top flex-col">
                            <button class="trade-type-heading center-center flex-row flex"
                                    (click)="parent.isOpen=!parent.isOpen">
                                <span class="mat-subheading-2 cb-no-margin-bottom cb-margin-right-2x">{{parent.label}}</span>
                                <hr class="mat-divider flex"/>
                                <mat-icon>{{parent.isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
                            </button>
                            <ul *ngIf="parent.isOpen"
                                class="list">
                                <li *ngFor="let child of parent.children"
                                    class="list cb-padding-left cb-padding-top">
                                    {{child.label}} -
                                    {{child.status | cbEnumToLabel: BUSINESS_ACCOUNT_SUPPLY_TYPE_STATUS_ENUM}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div *ngIf="!account.id && account.isSupplier">
                        <div *ngFor="let parent of availableSupplyTypes"
                             class="cb-margin-top flex-col">
                            <button class="trade-type-heading center-center flex-row flex"
                                    (click)="parent.isOpen=!parent.isOpen">
                                <span class="mat-subheading-2 cb-no-margin-bottom cb-margin-right-2x">{{parent.label}}</span>
                                <hr class="mat-divider flex"/>
                                <mat-icon>{{parent.isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
                            </button>
                            <div *ngIf="parent.isOpen"
                                 class="list cb-padding-left cb-padding-top">
                                <cb-checkbox *ngFor="let child of parent.children"
                                             [label]="child.label"
                                             [name]="'supplyType'+child.id"
                                             [ngModel]="isSupplyTypeRegionChecked(child)"
                                             (change)="onSupplyTypeChanged(child, $event)"></cb-checkbox>
                            </div>
                        </div>
                    </div>
                    <cb-info-message *ngIf="!account.isSupplier">
                        [message]="'Not a supplier'"
                        [icon]="'info'"></cb-info-message>
                </div>
            </div>
            <div *ngIf="account.id && !isEditing$.value && account.holds?.length"
                 class="cb-margin-top-2x flex-col flex">
                <div class="flex-row">
                    <mat-icon class="cb-error cb-margin-right">cancel</mat-icon>
                    <span class="mat-subheading-2 cb-no-margin-bottom cb-error flex">Account on Hold</span>
                </div>
                <hr class="mat-divider cb-margin-bottom"/>
                <cb-table [loaded]="true">
                    <thead cbTableHead>
                    <tr>
                        <td>Hold</td>
                        <td>Hold Type</td>
                        <td>Comments</td>
                        <td>Placed By</td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody cbTableBody>
                        <tr *ngFor="let hold of account.holds">
                            <cb-td-enum [value]="hold.holdAction"
                                        [enum]="BUSINESS_ACCOUNT_HOLD_ACTION_ENUM"></cb-td-enum>
                            <cb-td-enum [value]="hold.holdType"
                                        [enum]="BUSINESS_ACCOUNT_HOLD_TYPE_ENUM"></cb-td-enum>
                            <td style="white-space: pre-wrap;">{{hold.comments}}</td>
                            <td>
                                <cb-user-date-time-new [userFullName]="hold.holdPlaceBy"
                                                       [date]="hold.holdDate"
                                                       [displayTime]="true"></cb-user-date-time-new>
                            </td>
                            <td class="actions">
                                <button mat-raised-button
                                        mat-icon-button
                                        [disabled]="account.isClosedOrDeclined() || !permissions.canReleaseHold()"
                                        (click)="onRemoveAccountHoldClicked(hold)">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </cb-table>
            </div>
        </div>
        <div #col3
             class="cb-padding-left flex-col flex flex-34">
            <cb-address-new *ngIf="!account.id || isEditing$.value"
                            name="postalAddress"
                            [addressTitle]="'Postal Address'"
                            [(ngModel)]="account.postalAddress"></cb-address-new>
            <cb-business-account-status *ngIf="account.id && !isEditing$.value"
                                        [(account)]="account"
                                        (editFinanceInformationClicked)="editFinanceInformationClicked.emit()"
                                        (accountUpdated)="emitAccountUpdated()">
            </cb-business-account-status>
            <div *ngIf="!account.id || (isEditing$.value && showLocations)"
                 class="cb-margin-top-2x flex-col flex">
                <span class="mat-title required">Locations</span>
                <cb-checkbox *ngFor="let region of availabelRegions"
                             [label]="region.name"
                             [name]="'region'+region.id"
                             [ngModel]="isRegionChecked(region.id)"
                             (change)="onRegionChanged(region.id, $event)"></cb-checkbox>
            </div>
            <div *ngIf="isSupplierTypesEnabled && isEditing$.value"
                 class="cb-margin-top-2x">
                <span class="mat-title">Account Supply Types</span>
                <div *ngIf="account.isSupplier">
                    <div *ngFor="let parent of availableSupplyTypes"
                         class="cb-margin-top flex-col">
                        <button class="trade-type-heading center-center flex-row flex"
                                (click)="parent.isOpen=!parent.isOpen">
                            <span class="mat-subheading-2 cb-no-margin-bottom cb-margin-right-2x">{{parent.label}}</span>
                            <hr class="mat-divider flex"/>
                            <mat-icon>{{parent.isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
                        </button>
                        <div *ngIf="parent.isOpen"
                             class="list cb-padding-left cb-padding-top">
                            <cb-checkbox *ngFor="let child of parent.children"
                                         [label]="child.label"
                                         [name]="'supplyType'+child.id"
                                         [ngModel]="isSupplyTypeRegionChecked(child)"
                                         (change)="onSupplyTypeChanged(child, $event)"></cb-checkbox>
                        </div>
                    </div>
                </div>
                <cb-info-message *ngIf="!account.isSupplier">
                    [message]="'Not a supplier'"
                    [icon]="'info'"></cb-info-message>
            </div>
        </div>

    </form>
</mat-card>
