import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';

import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {ScheduleTemplatePermissionService} from '@app/logic/schedule-templates/schedule-template.permission.service';
import {initOnStartupProvider} from '@app/shared/providers/init-on-startup.provider';
import {CbDialogLoadingSpinnerModule} from 'cb-hub-lib';
import {AuthService} from './authentication/auth.service';
import {CurrentUserService} from './authentication/current.user';
import {HttpErrorInterceptor} from './interceptors/http-error.interceptor';
import {AddHeaderInterceptor} from './interceptors/http.interceptor';
import {PermissionsModule} from './permissions/permissions.module';
import {CurrentHttpRequestsService} from './services/current-http-requests/current-http-requests.service';
import {CbEnumService} from './services/enum/enum.service';
import {FeatureToggleStatesService} from './services/feature-toggle-states/feature-toggle-states.service';
import {LotFieldValidatorService} from './services/field-validation/lot-field-validator/lot-field-validator-service';
import {LotFieldRulesService} from './services/field-validation/lot-field-validator/lot.field.rules.service';
import {ProjectReleaseLotFieldRulesService} from './services/field-validation/project-release-lot-field-validator/project-release-lot-field-rules.service';
import {ProjectReleaseLotFieldValidatorService} from './services/field-validation/project-release-lot-field-validator/project-release-lot-field-validator.service';
import {GstService} from './services/gst/gst.service';
import {NewHttpErrorsService} from './services/http-errors/http-errors.service';
import {HttpWrapperService} from './services/http-wrapper/http-wrapper.service';
import {LookupService} from './services/lookup/lookup.service';
import {NavigationService} from './services/navigation/navigation.service';
import {SignalrService} from './services/signalr/signalr.service';
import {ToastService} from './services/toast/toast.service';
import {UserCacheService} from './services/user-cache/user-cache.service';
import {PriceOptionsService} from "@app/logic/price-options/priceoptions.service";

@NgModule({
    providers: [
        {
            deps: [
                AuthService,
                CurrentHttpRequestsService
            ],
            multi: true,
            provide: HTTP_INTERCEPTORS,
            useClass: AddHeaderInterceptor,
        },
        {
            deps: [
                NewHttpErrorsService
            ],
            multi: true,
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
        },
        CurrentUserService,
        GstService,
        NewHttpErrorsService,
        LookupService,
        initOnStartupProvider(AuthService),
        initOnStartupProvider(CurrentUserService),
        initOnStartupProvider(GstService),
        initOnStartupProvider(NewHttpErrorsService),
        initOnStartupProvider(LookupService),
        UserCacheService,
        NavigationService,
        PriceOptionsService,
        SignalrService,
        LotFieldValidatorService,
        LotFieldRulesService,
        ProjectReleaseLotFieldRulesService,
        ProjectReleaseLotFieldValidatorService,
        HttpWrapperService,
        CurrentHttpRequestsService,
        ToastService,
        CbEnumService,
        FeatureToggleStatesService,
        ScheduleTemplatePermissionService
    ],
    imports: [
        PermissionsModule,
        HttpClientModule,
        MatSnackBarModule,
        CbDialogLoadingSpinnerModule
    ],
    exports: [PermissionsModule]
})
export class CoreModule { }

