import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule} from 'cb-hub-lib';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {CbDisplayValueModule} from '@app/shared/components/display-value/display-value.module';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {CbClickModule} from '@app/shared/directives/click/click.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {SlotExternalSystemFieldValueDialogComponent} from './slot-external-system-field-value-dialog/slot-external-system-field-value-dialog.component';
import {SlotExternalSystemFieldValueComponent} from './slot-external-system-field-value-search/slot-external-system-field-value.component';
import {MatLegacyOptionModule as MatOptionModule} from '@angular/material/legacy-core';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';

@NgModule({
    declarations: [
        SlotExternalSystemFieldValueComponent,
        SlotExternalSystemFieldValueDialogComponent
    ],
    imports: [
        MatTabsModule,
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbDisplayValueModule,
        CbClickModule,
        CbHubTableModule,
        CbTableModule,
        InfiniteScrollModule,
        MatOptionModule,
        MatSelectModule,
    ],
    exports: [
        SlotExternalSystemFieldValueComponent,
        SlotExternalSystemFieldValueDialogComponent
    ]
})
export class SlotExternalSystemFieldValueComponentModule { }
