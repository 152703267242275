import {
    BuildProgrammePermissions,
    BuildProgrammeTemplatePermissions,
    BusinessAccountPermissions,
    ClientAccountPermissions,
    ClientSalePermissions,
    ContactPermissions,
    DesignTeamPermissions,
    HelpPermissions,
    HouseLandPermissions,
    LeadPermissions,
    LotPermissions,
    PermissionsPermissions,
    ProductPermissions,
    ProjectPermissions,
    QSTeamPermissions,
    SpecificationTemplatePermissions,
    StandardPlanPermissions
} from '../../../../core/permissions';
import { Component, Input } from '@angular/core';

import { CurrentUserService } from '@app/core/authentication/current.user';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { Router } from '@angular/router';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';

@Component({
    selector: 'cb-sidenav-menu',
    templateUrl: './sidenav-menu.component.html',
    styleUrls: ['./sidenav-menu.component.scss']
})
export class SidenavMenuComponent {

    @Input() public sidenav: MatSidenav;

    constructor(
        private readonly qsTeamPermissions: QSTeamPermissions,
        private readonly designTeamPermissions: DesignTeamPermissions,
        private readonly contactPermissions: ContactPermissions,
        private readonly leadPermissions: LeadPermissions,
        private readonly clientAccountPermissions: ClientAccountPermissions,
        private readonly clientSalePermissions: ClientSalePermissions,
        private readonly houseLandPermissions: HouseLandPermissions,
        private readonly standardPlanPermissions: StandardPlanPermissions,
        private readonly lotPermissions: LotPermissions,
        private readonly projectPermissions: ProjectPermissions,
        private readonly specificationTemplatePermissions: SpecificationTemplatePermissions,
        private readonly buildProgrammeTemplatePermissions: BuildProgrammeTemplatePermissions,
        private readonly buildProgrammePermissions: BuildProgrammePermissions,
        private readonly businessAccountPermissions: BusinessAccountPermissions,
        private readonly productPermissions: ProductPermissions,
        private readonly permissionsPermissions: PermissionsPermissions,
        private readonly helpPermissions: HelpPermissions,
        private readonly navigationService: NavigationService,
        private readonly router: Router,
        private readonly currentUser: CurrentUserService,
        private readonly featureToggleService: FeatureToggleStatesService
    ) {
        // Load the feature toggle states whenever the side bar is created the first time
        // Without this you run into issues where the feature toggles aren't loaded
        // by the time you need to check them
        this.featureToggleService.init();
    }

    public navigate(path: string, event: MouseEvent): void {
        this.navigationService
            .navigate([path], event)
            .then((result) => {
                if (result.success && !result.newTab) {
                    this.sidenav.close();
                }
            });
    }

    public navigateHome(): void {
        this.router.navigateByUrl('/').then(() => this.sidenav.close());
    }

    // Main
    public canSeeDesignTeamComponent = (): boolean => this.designTeamPermissions && this.designTeamPermissions.canSeeComponent();
    public canSeeQSTeamComponent = (): boolean => this.qsTeamPermissions && this.qsTeamPermissions.canSeeComponent();
    public canSeeAllTeamsComponent = (): boolean => this.buildProgrammePermissions && this.buildProgrammePermissions.canSearch();
    // Sales Dashboard
    public canSeeSalesDashboard = (): boolean => this.currentUser.hasBuildingConsultant();
    // CRM
    public canSeeContactComponent = (): boolean => this.contactPermissions && this.contactPermissions.canSeeComponent();
    public canSeeLeadComponent = (): boolean => this.leadPermissions && this.leadPermissions.canSeeComponent();
    public canSeeMyLeadsComponent = (): boolean => this.leadPermissions && this.leadPermissions.canSeeComponent() && this.currentUser.hasBuildingConsultant();
    public canSeeClientAccountComponent = (): boolean => this.clientAccountPermissions && this.clientAccountPermissions.canSeeComponent();
    public canSeeClientSaleComponent = (): boolean => this.clientSalePermissions && this.clientSalePermissions.canSeeComponent();
    public canSeeMyClientSalesComponent = (): boolean => this.clientSalePermissions && this.clientSalePermissions.canSeeComponent() && this.currentUser.hasBuildingConsultant();
    public canSeeHouseLandComponent = (): boolean => this.houseLandPermissions && this.houseLandPermissions.canView();
    public canSeeStandardPlanComponent = (): boolean => this.standardPlanPermissions && this.standardPlanPermissions.canView();
    // Build
    public canSeeLotComponent = (): boolean => this.lotPermissions && this.lotPermissions.canSearch();
    public canSeeProjectComponent = (): boolean => this.projectPermissions && this.projectPermissions.canSeeComponent();
    public canSeeSpecificationTemplateComponent = (): boolean => this.specificationTemplatePermissions && this.specificationTemplatePermissions.canView();
    public canSeeBuildingProgrammeTemplateComponent = (): boolean => this.buildProgrammeTemplatePermissions && this.buildProgrammeTemplatePermissions.canView();
    public canGenerateBusinessAccountContract = (): boolean => this.businessAccountPermissions && this.businessAccountPermissions.canGenerateContracts();
    // Products
    public canSeeBusinessAccountComponent = (): boolean => this.businessAccountPermissions && this.businessAccountPermissions.canSearch();
    public canSeeProductComponent = (): boolean => this.productPermissions && this.productPermissions.canSeeComponent();
    // Security & Settings
    public canSeeSettingsComponent = (): boolean => this.permissionsPermissions && this.permissionsPermissions.canViewSettings();
    public canSeeUsersComponent = (): boolean => this.permissionsPermissions && this.permissionsPermissions.canViewUsers();
    public canSeeRolesComponent = (): boolean => this.permissionsPermissions && this.permissionsPermissions.canViewRoles();
    public canSeeConnectionsComponent = (): boolean => this.permissionsPermissions && this.permissionsPermissions.canViewConnections();
    public canSeeHelpComponent = (): boolean => this.helpPermissions && this.helpPermissions.canView();
}
