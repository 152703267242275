<div class="flex-row flex">
    <div class="flex">
        <mat-tab-group [selectedIndex]="currentTabIndex"
                       (selectedIndexChange)="tabIndexChanged($event)">
            <mat-tab label="Specification Template">
                <ng-template [matTabContent]>
                    <div *ngIf="template"
                         class="cb-padding flex-row flex">
                        <div class="scrollable-container cb-padding-right flex">
                            <mat-card class="cb-margin-bottom-2x cb-no-padding cb-padding-top cb-padding-right"
                                      style="text-align: right;">
                                <button class="cb-margin-right-2x"
                                        mat-raised-button
                                        color="primary"
                                        [disabled]="!canEdit()"
                                        [matMenuTriggerFor]="addItemmenu">
                                    Add Item
                                </button>
                                <mat-menu #addItemmenu="matMenu"
                                          xPosition="before">
                                    <button mat-menu-item
                                            (click)="addLotSpecItemClicked()">
                                        Lot Spec Item
                                    </button>
                                    <button mat-menu-item
                                            (click)="addColourItemClicked()">
                                        Colour Item
                                    </button>
                                </mat-menu>
                                <button mat-raised-button
                                        class="cb-margin-bottom"
                                        (click)="expandOrCollapseAll()">
                                    <span *ngIf="!isAllExpanded()">Expand All&nbsp;</span>
                                    <span *ngIf="isAllExpanded()">Collapse All&nbsp;</span>
                                    <mat-icon *ngIf="!isAllExpanded()">keyboard_arrow_down</mat-icon>
                                    <mat-icon *ngIf="isAllExpanded()">keyboard_arrow_up</mat-icon>
                                </button>
                            </mat-card>
                            <mat-expansion-panel class="panel cb-margin-bottom"
                                                 *ngFor="let houseArea of houseAreas"
                                                 [(expanded)]="expansionPanelStates[houseArea.id]">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="cb-margin-left-2x">
                                            {{houseArea.houseArea.label}}
                                            ({{houseArea.items ? houseArea.items.length : 0}})
                                        </span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="cb-margin-bottom-2x"
                                     style="text-align: right;">
                                    <button mat-raised-button
                                            color="primary"
                                            [disabled]="!canEdit()"
                                            [matMenuTriggerFor]="addItemmenu">
                                        Add Item
                                    </button>
                                    <mat-menu #addItemmenu="matMenu"
                                              xPosition="before">
                                        <button mat-menu-item
                                                (click)="addLotSpecItemClicked(houseArea)">
                                            Lot Spec Item
                                        </button>
                                        <button mat-menu-item
                                                (click)="addColourItemClicked(houseArea)">
                                            Colour Item
                                        </button>
                                    </mat-menu>
                                </div>
                                <cb-table [loaded]="true">
                                    <thead cbTableHead>
                                        <td></td>
                                        <td>Cost Type</td>
                                        <td>Code</td>
                                        <td>Product</td>
                                        <td>Options</td>
                                        <td>Quantity</td>
                                        <td>Amount</td>
                                        <td>Supplier</td>
                                        <td></td>
                                    </thead>
                                    <tbody cbTableBody>
                                        <tr *ngFor="let item of houseArea.items"
                                            cbTableRow>
                                            <td [ngSwitch]="!item.colourItem"
                                                style="color: grey;">
                                                <span *ngSwitchCase="true">
                                                    <mat-icon *ngIf="isBundle(item)">
                                                        folder</mat-icon>
                                                    <mat-icon *ngIf="item.bundleId">
                                                        attach_file</mat-icon>
                                                    <mat-icon *ngIf="item.hasImage">
                                                        photo</mat-icon>
                                                    <mat-icon *ngIf="item.showInClientSpecification || item.inSpec">
                                                        style</mat-icon>
                                                    <mat-icon *ngIf="item.showInColourYourDreams"
                                                              title="Included in Colour Schedule">
                                                        import_contacts</mat-icon>
                                                    <mat-icon *ngIf="isDescriptiveOnly(item.costType)">
                                                        notes</mat-icon>
                                                </span>
                                                <span *ngSwitchDefault>
                                                    <mat-icon>format_paint</mat-icon>
                                                    <mat-icon *ngIf="item.colourItem.image">
                                                        photo</mat-icon>
                                                </span>
                                            </td>
                                            <td [ngSwitch]="!item.colourItem">
                                                <span *ngSwitchCase="true">
                                                    {{item.costType ? COST_TYPE_ENUM[item.costType] : ''}}</span>
                                                <span *ngSwitchDefault>Colour</span>
                                            </td>
                                            <td [ngSwitch]="!item.colourItem">
                                                <span *ngSwitchCase="true">{{item.product
                                                    ? item.product.code : ''}}</span>
                                                <span *ngSwitchDefault>{{item.colourItem.code}}</span>
                                            </td>
                                            <td [ngSwitch]="!item.colourItem"
                                                class="truncate-td">
                                                <span *ngSwitchCase="true">{{item.product
                                                    ? item.product.name : item.productOther}}</span>
                                                <span *ngSwitchDefault>{{item.colourItem.name}}</span>
                                            </td>
                                            <td [ngSwitch]="!item.colourItem">
                                                <span *ngSwitchCase="true">
                                                    <ul class="inline-list">
                                                        <li *ngFor="let option of item.options">{{option.name}}</li>
                                                    </ul>
                                                </span>
                                                <span *ngSwitchDefault></span>
                                            </td>
                                            <td [ngSwitch]="!item.colourItem">
                                                <span *ngSwitchCase="true">
                                                    {{!isDescriptiveOnly(item.costType) && !isProvisional(item.costType)
                                                    ? item.quantity : '-' }}</span>
                                                <span *ngSwitchDefault>-</span>
                                            </td>
                                            <td [ngSwitch]="!item.colourItem">
                                                <span *ngSwitchCase="true">
                                                    <span *ngIf="isEstimate(item.costType)">
                                                        {{item.rateSnapshot | currency}}</span>
                                                    <span *ngIf="isProvisional(item.costType)">
                                                        {{item.rateSnapshot}}</span>
                                                    <span *ngIf="!isProvisional(item.costType) && !isEstimate(item.costType)">-</span>
                                                </span>
                                                <span *ngSwitchDefault>-</span>
                                            </td>
                                            <td [ngSwitch]="!item.colourItem">
                                                <span *ngSwitchCase="true">{{item.supplier && item.supplier.tradingName
                                                    ? item.supplier.tradingName:'-'}}</span>
                                                <span *ngSwitchDefault>-</span>
                                            </td>
                                            <td cbTableStickyCol="end"
                                                cbTableShrinkCol>
                                                <button mat-raised-button
                                                        mat-icon-button
                                                        [matMenuTriggerFor]="addItemmenu">
                                                    <mat-icon>more_horiz</mat-icon>
                                                </button>
                                                <mat-menu #addItemmenu="matMenu"
                                                          xPosition="before">
                                                    <button mat-menu-item
                                                            [disabled]="!canEdit()"
                                                            (click)="editItemClicked(item, houseArea.houseArea.id)">
                                                        <mat-icon class="cb-margin-right-3x">mode_edit</mat-icon>
                                                        Edit Item
                                                    </button>
                                                    <button mat-menu-item
                                                            [disabled]="!canEdit()"
                                                            (click)="removeItemClicked(item)">
                                                        <mat-icon class="cb-margin-right-3x">remove_circle</mat-icon>
                                                        Remove Item
                                                    </button>
                                                    <button *ngIf="item.offeringId"
                                                            mat-menu-item
                                                            (click)="viewItemClicked(item)">
                                                        <mat-icon class="cb-margin-right-3x">pageview</mat-icon>
                                                        View Item
                                                    </button>
                                                </mat-menu>
                                            </td>
                                        </tr>
                                        <tr *ngIf="!houseArea.items || !houseArea.items.length"
                                            cbTableRow>
                                            <td colspan="9">
                                                <mat-icon class="mat-accent">info</mat-icon>
                                                &nbsp;
                                                <span class="mat-body-1"> There are no Items in this House Area</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </cb-table>
                            </mat-expansion-panel>
                            <mat-card *ngIf="!houseAreas.length">There are no items to display
                            </mat-card>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab label="History">
                <ng-template [matTabContent]>
                    <cb-history-list [entityId]="templateId"
                                     entity="spectemplate">
                    </cb-history-list>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
    <cb-spec-template-side-panel *ngIf="template"
                                 class="cb-margin"
                                 [template]="template"
                                 [canEdit]="canEdit()"
                                 [refresh$]="refresh$">
    </cb-spec-template-side-panel>
</div>
