import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { SpecificationTemplatePermissions } from '@app/core/permissions';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { ISpecTemplateDto } from '@app/logic/spec-template';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { includes } from 'lodash';

enum Columns {
    TemplateName = 'templateName',
    Visibility = 'visibility',
    CreatedBy = 'createdBy',
    UpdatedBy = 'updatedBy',
    LastUpdated = 'lastUpdated',
    IsActive = 'isActive',
    Actions = 'actions',
}

@Component({
    selector: 'cb-spec-template-table',
    templateUrl: './spec-template-table.component.html',
    styleUrls: ['./spec-template-table.component.scss']
})
export class SpecTemplateTableComponent {
    @ViewChild(MatSort, { static: true }) public sort: MatSort;

    private _templates: ISpecTemplateDto[] = [];
    @Input() public set templates(items: ISpecTemplateDto[]) {
        this._templates = items;
        this.sortTemplates();
    }
    public get templates(): ISpecTemplateDto[] {
        return this._templates;
    }

    @Output() public readonly viewTemplate = new EventEmitter<ISpecTemplateDto>();
    @Output() public readonly editTemplate = new EventEmitter<ISpecTemplateDto>();
    @Output() public readonly duplicateTemplate = new EventEmitter<ISpecTemplateDto>();

    public readonly columnsEnum = Columns;
    public readonly displayedColumns = Object.values(Columns);
    public sortedTemplates: ISpecTemplateDto[] = [];

    constructor(
        private readonly specTemplatePermissions: SpecificationTemplatePermissions,
        private readonly featureToggle: FeatureToggleStatesService,
        private readonly currentUserService: CurrentUserService,
    ) {
    }

    public sortTemplates(): void {
        const templates = this._templates.slice() ?? [];
        if (!this.sort?.active || isNullOrWhiteSpace(this.sort?.direction)) {
            this.sortedTemplates = templates;
            return;
        }
        this.sortedTemplates = templates.slice().sort((a, b) => {
            const isAsc = this.sort?.direction === 'asc';
            switch (this.sort.active) {
                case Columns.TemplateName: return this.compare(a.name, b.name, isAsc);
                case Columns.Visibility: return this.compare(this.getVisibility(a), this.getVisibility(b), isAsc);
                case Columns.CreatedBy: return this.compare(a.createdByName, b.createdByName, isAsc);
                case Columns.UpdatedBy: return this.compare(a.updatedByName, b.updatedByName, isAsc);
                case Columns.LastUpdated: return this.compare(a.updatedDate, b.updatedDate, isAsc);
                default: return 0;
            }
        });
    }

    public canEdit(specTemplate: ISpecTemplateDto): boolean {
        let canEdit = false;
        if (this.specTemplatePermissions.canEditAll()) {
            canEdit = true;
        } else {
            canEdit = specTemplate.regionIds.some(i => {
                return includes(this.currentUserService.regionsids ?? [], i) && this.specTemplatePermissions.canEdit();
            });
        }
        return canEdit
            && this.canEditSpecTemplateForFeatureToggle(specTemplate);
    }

    public canDuplicate(specTemplate: ISpecTemplateDto): boolean {
        return this.specTemplatePermissions.canDuplicate()
            && this.canEditSpecTemplateForFeatureToggle(specTemplate);
    }

    public getVisibility(specTemplate: ISpecTemplateDto): string {
        if (specTemplate.project) {
            return `Project ( ${specTemplate.project.projectName} )`;
        }
        return specTemplate.isRegional ? 'Regional' : 'National';
    }

    private compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    private canEditSpecTemplateForFeatureToggle(specTemplate: ISpecTemplateDto): boolean {
        return (this.featureToggle.isScheduleItemSpecTemplatesEnabled && specTemplate.isScheduleSpecTemplate)
            || (!this.featureToggle.isScheduleItemSpecTemplatesEnabled && !specTemplate.isScheduleSpecTemplate);
    }
}
