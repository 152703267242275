<div class="flex-col cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x">
    <div class="flex-row start-center">
        <h3 class="flex mat-title">Address Regions</h3>
        <button class="header-button"
                mat-raised-button
                (click)="newItemClicked();"
                [disabled]="!canEdit()"
                color="primary">
            New Address Region
        </button>
        <button class="header-button"
                mat-raised-button
                (cbClick)="saveOrder()"
                [throttleTime]="2000"
                [disabled]="!sortOrderChanged || !canEdit()"
                color="primary">
            Save Region Order
        </button>
    </div>
    <cb-table class="cb-margin-top"
              label="Address Regions"
              [columns]="displayedColumns"
              [loaded]="searchResultsLoaded"
              [minWidth]="1000"
              [maxHeightOffset]="186">
        <tbody cbTableBody
               [dragula]="ADDRESS_REGIONS"
               [(dragulaModel)]="dragulaModel">
        <tr *ngFor="let ele of searchResults">
            <td>{{ele.name}}</td>
            <cb-td-checkbox [value]="ele.isActive"></cb-td-checkbox>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-raised-button
                        mat-icon-button
                        (click)="editItemClicked(ele);"
                        [disabled]="!canEdit()">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </cb-table>
</div>
