<h1 *ngIf="showTitle"
    class="mat-title cb-margin-left-2x cb-margin-top cb-no-margin-bottom">{{clientSale?.lot?.jobNumber}} -
    {{clientSale?.clientAccount?.name}} {{clientSale?.lot?.lotContractTypeLabel}}</h1>
<div class="flex-row flex">
    <mat-tab-group class="flex"
                   id="client-sale-main-container"
                   [cbTabRoute]="TABS_ROUTE"
                   defaultTabId="details">
        <mat-tab label="Details"
                 cbTabRouteId="details">
            <ng-template matTabContent>
                <div class="flex-col"
                     *cbWaitFor="clientSale">
                    <cb-client-sale-stepper [clientSale]="clientSale"></cb-client-sale-stepper>
                    <cb-client-sale-details [clientSale]="clientSale"></cb-client-sale-details>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Notes"
                 cbTabRouteId="notes">
            <ng-template matTabContent>
                <cb-notes-new *cbWaitFor="clientSale"
                              [entity]="clientSale"
                              [edit]="true"
                              [permissions]="permissions.getNotePermissions()">
                </cb-notes-new>
            </ng-template>
        </mat-tab>
        <mat-tab label="Tasks"
                 cbTabRouteId="tasks">
            <ng-template matTabContent>
                <cb-tasks *cbWaitFor="clientSale"
                          [entity]="clientSale"
                          [edit]="true"
                          [permissions]="permissions.getTaskPermissions()"></cb-tasks>
            </ng-template>
        </mat-tab>
        <mat-tab label="Documents"
                 cbTabRouteId="documents">
            <ng-template matTabContent>
                <cb-document *cbWaitFor="clientSale"
                             [entity]="clientSale"
                             [permissions]="permissions.getDocumentPermissions()"
                             [lockRequired]="clientSale?.isUnconditional"
                             [includeLinked]="false"
                             [systemArea]="systemArea"></cb-document>
            </ng-template>
        </mat-tab>
        <mat-tab label="History"
                 cbTabRouteId="history">
            <ng-template matTabContent>
                <cb-history-list *cbWaitFor="clientSale"
                                 [entityId]="clientSale?.id"
                                 entity="clientsale"></cb-history-list>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    <cb-side-bar class="flex-col flex-20"
                 [ngClass]="showTitle ? 'place-at-top': 'cb-margin'">
        <div class="flex-col flex"
             id="sidebar-container">
            <h1 class="mat-title">{{ clientSale?.lot?.lotContractTypeLabel }} </h1>
            <cb-display-value-new label="Sale Number"
                                  [value]="clientSale?.saleNumber"
                                  class="cb-margin-top-2x"></cb-display-value-new>
            <cb-display-value-new label="Client Account Legal Name"
                                  [value]="clientSale?.clientAccount?.legalName"
                                  value="2"></cb-display-value-new>
            <cb-display-value-new label="Lot Type"
                                  [value]="clientSale?.lot?.lotContractTypeLabel"></cb-display-value-new>
            <cb-display-value-new label="Build Type"
                                  [value]="clientSale?.lot?.buildTypeLabel"></cb-display-value-new>
            <cb-display-value-new label="Lot Address">
                {{clientSale?.lot?.address?.street}},
                <br/>
                {{clientSale?.lot?.address?.suburb}},
                <br/>
                {{clientSale?.lot?.address?.city}}
            </cb-display-value-new>
            <cb-amenities [amenities]="clientSale?.lot?.amenities"></cb-amenities>
            <cb-display-value-new *ngIf="clientSale?.isHouseAndLand"
                                  label="Project"
                                  [value]="clientSale?.lot?.projectLabel"></cb-display-value-new>
            <cb-display-value-new *ngIf="clientSale?.isHouseAndLand"
                                  label="Project Release Stage"
                                  [value]="clientSale?.lot?.projectReleaseStageLabel"></cb-display-value-new>
            <cb-display-value-new label="Lead"
                                  [value]="clientSale?.leadName"></cb-display-value-new>
            <cb-display-value-new label="Is Showhome"
                                  [value]="clientSale?.lot?.isShowHome | cbPrettyBool"></cb-display-value-new>
            <cb-display-value-new label="Created by">
                {{clientSale?.createdByName}} on {{clientSale?.createdDate | date}}
            </cb-display-value-new>
            <cb-display-value-new label="Updated by">
                {{clientSale?.updatedByName}} on {{clientSale?.updatedDate | date}}
            </cb-display-value-new>
            <button mat-raised-button
                    color="primary"
                    class="cb-margin-bottom cb-margin-top-3x"
                    (cbClick)="viewLot()">
                View Lot
            </button>
        </div>
    </cb-side-bar>
</div>
