<button mat-icon-button
        mat-raised-button
        color="accent"
        class="collapse-button"
        (click)="isCollapsed = !isCollapsed"
        [matTooltip]="isCollapsed ? 'Expand' : 'Collapse'">
    <mat-icon class="collapse-icon">
        {{ isCollapsed ? 'chevron_right' : 'chevron_left '}}
    </mat-icon>
</button>
<mat-card *ngIf="isCollapsed"
          class="collapsed-card">
    <span class="mat-title collapsed-title"> {{designConcept.name}} - {{designConcept.statusLabel}}</span>
</mat-card>
<ng-container *ngIf="!isCollapsed">
    <mat-card class="scroll-container flex-col">
        <mat-card-title class="cb-title">
            {{designConcept.name}} - {{designConcept.statusLabel}}
            <span class="flex"></span>
            <button [matMenuTriggerFor]="menu"
                    mat-raised-button
                    mat-icon-button>
                <mat-icon mat-menu-origin>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item
                        (click)="uploadImage()">
                    <mat-icon>file_upload</mat-icon>
                    &nbsp;
                    {{getUploadButtonText()}}
                </button>
                <button *ngIf="showDeleteButton()"
                        mat-menu-item
                        (click)="deleteImage()">
                    <mat-icon>delete</mat-icon>
                    &nbsp;
                    {{getDeleteButtonText()}}
                </button>
            </mat-menu>
        </mat-card-title>
        <mat-divider [inset]="true"></mat-divider>
        <mat-tab-group class="flex"
                       [(selectedIndex)]="selectedIndex">
            <mat-tab label="Exterior"
                     style="margin-top: 0 !important;">
                <div class="flex-row flex">
                    <cb-image-button class="flex"
                                     [baseUrl]="designConcept?.exteriorImageUrl">
                    </cb-image-button>
                </div>
            </mat-tab>
            <mat-tab label="Floor Plan"
                     style="margin-top: 0 !important;">
                <cb-image-button class="flex"
                                 [baseUrl]="designConcept?.floorPlanImageUrl">
                </cb-image-button>
            </mat-tab>
        </mat-tab-group>
        <cb-user-date-time-new label="Exterior Image Requested"
                               [floatingLabel]="true"
                               [date]="designConcept?.exteriorImageRequestedDate"
                               [userFullName]="designConcept?.exteriorImageRequesterName">
        </cb-user-date-time-new>
        <cb-user-date-time-new label="Exterior Image Uploaded"
                               [floatingLabel]="true"
                               [date]="designConcept?.exteriorImageUploadedDate"
                               [userFullName]="designConcept?.exteriorImageUploaderName">
        </cb-user-date-time-new>
        <cb-user-date-time-new label="Floor Plan Image Requested"
                               [floatingLabel]="true"
                               [date]="designConcept?.floorPlanImageRequestedDate"
                               [userFullName]="designConcept?.floorPlanImageRequesterName">
        </cb-user-date-time-new>
        <cb-user-date-time-new label="Floor Plan Image Uploaded"
                               [floatingLabel]="true"
                               [date]="designConcept?.floorPlanImageUploadedDate"
                               [userFullName]="designConcept?.floorPlanImageUploaderName">
        </cb-user-date-time-new>
        <cb-user-date-time-new label="Created By"
                               [floatingLabel]="true"
                               [date]="designConcept?.createdDate"
                               [userFullName]="designConcept?.createdByName">
        </cb-user-date-time-new>
    </mat-card>
</ng-container>
