import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CommonModule } from '@angular/common';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CbInfiniteScrollModule } from 'projects/cb-hub-lib/src/lib/directives/infinite-scroll/infinite-scroll.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { CbInfoMessageModule } from 'projects/cb-hub-lib/src/lib/components/info-message/info-message.module';
import { RouterModule } from '@angular/router';
import { CbDasherModule } from 'projects/cb-hub-lib/src/lib/pipe/dasher/dasher.module';
import { CbButtonModule } from '@app/shared/components/button/button.module';
import { CbTabRouteModule } from '@app/shared/directives/tab-route/tab-route.module';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { CbSortByImpureModule } from '@app/shared/pipe/sort-by-impure/sort-by-impure.module';
import { CbTableModule } from 'cb-hub-lib';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbLotCostsModule } from '@app/views/lot/costs/costs.module';
import { AllTeamsAreaComponent } from './all-teams-area/all-teams-area.component';
import { TeamBuildProgrammesComponent } from './team-build-programmes/team-build-programmes.component';
import { AllManualOrdersModule } from './all-manual-orders/all-manual-orders.module';
import { IncompleteBuildActivitiesModule } from './incomplete-build-activities/incomplete-build-activities.module';

const MATERIAL_MODULES = [
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatCheckboxModule,
    MatCardModule,
    MatDialogModule,
    MatTableModule,
    MatTooltipModule,
    MatMenuModule,
    MatChipsModule,
    MatRadioModule,
    MatTabsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
];

const CB_MODULES = [
    CbInfiniteScrollModule,
    CbDisplayValueModule,
    CbWaitForModule,
    CbDialogModule,
    CbFormsModule,
    CbInfoMessageModule,
    CbDasherModule,
    CbButtonModule,
    CbTabRouteModule,
    CbSortByModule,
    CbSortByImpureModule,
    CbInfiniteScrollModule,
    CbTableModule,
    CbHubTableModule,
    CbLotCostsModule,
    AllManualOrdersModule,
    IncompleteBuildActivitiesModule
];

const MISCELLANEOUS_MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
];

@NgModule({
    declarations: [
        AllTeamsAreaComponent,
        TeamBuildProgrammesComponent
    ],
    exports: [
    ],
    imports: [
        RouterModule.forRoot(
            [{
                component: AllTeamsAreaComponent,
                path: 'teams/all',
                data: { breadcrumb: 'All Teams' },
            },
            {
                component: AllTeamsAreaComponent,
                path: 'teams/all/build-programmes',
                data: {
                    breadcrumb: 'Build Programmes',
                }
            },
            {
                component: AllTeamsAreaComponent,
                path: 'teams/all/manual-orders',
                data: {
                    breadcrumb: 'Manual Orders',
                }
            },
            {
                component: AllTeamsAreaComponent,
                path: 'teams/all/incomplete-build-activities',
                data: {
                    breadcrumb: 'Incomplete Build Activities',
                }
            },],
            {
    onSameUrlNavigation: 'reload'
}
        ),
        ...MATERIAL_MODULES,
        ...MISCELLANEOUS_MODULES,
        ...CB_MODULES,
    ]
})
export class CbAllTeamsModule { }
