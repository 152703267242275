<div class="cb-margin-3x">
    <cb-table label="Confirmed Quantities"
              #infiniteScrollTable
              maxHeightOffset="200"
              [columns]="['Job Number', 'QS', 'Business Entity', 'Lot Status', 'Job Status', '']"
              [loaded]="true"
              infiniteScroll
              [immediateCheck]="false"
              [infiniteScrollContainer]="infiniteScrollTable?.infiniteScrollContainer"
              (scrolled)="doSearch()">
        <tbody cbTableBody>
            <tr *ngFor="let confirmedQuantity of searchResults">
                <cb-td-text [value]="confirmedQuantity?.jobNumber"></cb-td-text>
                <cb-td-text [value]="confirmedQuantity?.qsName"></cb-td-text>
                <cb-td-text [value]="confirmedQuantity?.businessEntityName"></cb-td-text>
                <cb-td-enum [value]="confirmedQuantity?.lotStatus"
                            [enum]="CONTRACT_STATUS_ENUM">
                </cb-td-enum>
                <cb-td-enum [value]="confirmedQuantity?.jobStatus"
                            [enum]="LOT_JOB_STATUS_ENUM">
                </cb-td-enum>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-raised-button
                            mat-icon-button
                            (click)="viewLot(confirmedQuantity)">
                        <mat-icon>pageview</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </cb-table>
</div>
