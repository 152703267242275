<div #questionSelector
     class="cb-margin cb-margin-left-2x cb-margin-right-2x flex-row flex"
     [ngClass]="{'cb-disabled-card': !genericQuestion.isActive}">
    <div class="center-start flex-col flex">
        <span class="start-center flex-row">
            <span class="mat-body-2">
                #{{genericQuestion.questionNumber}}&nbsp;
                Question to {{genericQuestion.assignedToUserName}}&nbsp;
            </span>
            <cb-user-date-time-new textClass="mat-body-2"
                                   label="from"
                                   [date]="genericQuestion.createdDate"
                                   [userFullName]="genericQuestion.createdByName"></cb-user-date-time-new>
        </span>
        <cb-display-value-new class="cb-margin"
                              [value]="genericQuestion.request"></cb-display-value-new>
        <br/>
        <span class="mat-body-2"
              *ngIf="genericQuestion.isAwaitingResponse && genericQuestion.isActive">Waiting for Response...</span>
        <div class="flex-col"
             *ngIf="!genericQuestion.isAwaitingResponse">
            <cb-user-date-time-new textClass="mat-body-2"
                                   label="Response from"
                                   [date]="genericQuestion.responseDate"
                                   [userFullName]="genericQuestion.assignedToUserName"></cb-user-date-time-new>
            <cb-display-value-new class="cb-margin"
                                  [value]="genericQuestion.response"></cb-display-value-new>
        </div>
        <cb-user-date-time-new textClass="mat-body-2"
                               *ngIf="!genericQuestion.isActive"
                               label="Cancelled by"
                               [date]="genericQuestion.updatedDate"
                               [userFullName]="genericQuestion.updatedByName"></cb-user-date-time-new>
    </div>
    <button mat-raised-button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            *ngIf="genericQuestion.isActive"
            [disabled]="!edit || (!genericQuestion.canView && !genericQuestion.canEdit && !genericQuestion.canRespond && !genericQuestion.canCancel)">
        <mat-icon mat-menu-origin>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item
                *ngIf="genericQuestion.canView"
                (click)="viewQuestion()">
            <mat-icon mat-menuAlign-target>pageview</mat-icon>
            View
        </button>
        <button mat-menu-item
                (click)="editQuestion()"
                [disabled]="!genericQuestion.canEdit">
            <mat-icon mat-menuAlign-target>edit</mat-icon>
            Edit
        </button>
        <button mat-menu-item
                (click)="respondQuestion()"
                [disabled]="!genericQuestion.canRespond">
            <mat-icon mat-menuAlign-target>reply</mat-icon>
            Respond
        </button>
        <button mat-menu-item
                (click)="cancelQuestion()"
                [disabled]="!genericQuestion.canCancel">
            <mat-icon mat-menuAlign-target>cancel</mat-icon>
            Cancel Question
        </button>
    </mat-menu>
</div>
<mat-divider></mat-divider>
