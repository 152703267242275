import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {DialogLoadingSpinnerComponent} from './dialog-loading-spinner.component';

const COMPONENTS = [
    DialogLoadingSpinnerComponent
];

@NgModule({
    declarations: COMPONENTS,
    exports: COMPONENTS,
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ]
})
export class CbDialogLoadingSpinnerModule {
}
