import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule} from 'cb-hub-lib';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {BundleTemplatesComponent} from '@app/views/settings/pages/bundle-templates/bundle-templates.component';
import {BundleTemplateDialogComponent} from './bundle-template-dialog/bundle-template-dialog.component';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {BundleTemplateItemDialogComponent} from './bundle-template-item-dialog/bundle-template-item-dialog.component';

@NgModule({
    declarations: [
        BundleTemplatesComponent,
        BundleTemplateDialogComponent,
        BundleTemplateItemDialogComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbHubTableModule,
        CbTableModule
    ],
    exports: [
        BundleTemplatesComponent,
        BundleTemplateDialogComponent,
        BundleTemplateItemDialogComponent
    ]
})
export class BundleTemplatesSettingsModule { }
