import { Component } from '@angular/core';
import { FeatureToggleLogicService } from '@app/logic/feature-toggle';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { toPromisedArray } from 'cb-hub-lib';
import { IFeatureToggleStateDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';

@Component({
    selector: 'cb-feature-toggle-view',
    templateUrl: './feature-toggle-view.component.html',
    styleUrls: ['./feature-toggle-view.component.scss']
})
export class FeatureToggleViewComponent {

    public featureToggleStates = toPromisedArray(this.featureToggleLogic.getDtoList());

    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly featureToggleLogic: FeatureToggleLogicService,
    ) { }

    public seedStates(): Observable<void> {
        return new Observable(subscriber => {
            this.cbDialog.confirm({
                dialogHeading: 'Confirm Seed Feature Toggles',
                // eslint-disable-next-line max-len
                message: 'Are you sure you want to seed ALL Feature Toggles? This will remove deleted Feature Toggles and add missing Feature Toggles. Existing in-use Feature Toggles will not be modified.',
                yesLabel: 'Yes',
                noLabel: 'No',
                confirmed: () => {
                    this.featureToggleStates = toPromisedArray(this.featureToggleLogic.seedStates());
                    this.featureToggleStates.$promise.then(() => {
                        subscriber.next();
                        subscriber.complete();
                    });
                },
                declined: () => {
                    subscriber.next();
                    subscriber.complete();
                }
            });
        });
    }

    public toggleFeature(feature: IFeatureToggleStateDto, event: MouseEvent): void {
        event.preventDefault();
        this.cbDialog.confirm({
            dialogHeading: 'Confirm Toggle Feature',
            message: `Are you sure you want to toggle "${feature.label}" to ${!feature.isEnabled ? 'ENABLED' : 'DISABLED'}?`,
            yesLabel: 'Yes',
            noLabel: 'No',
            confirmed: () => {
                this.featureToggleLogic
                    .updateFeatureToggleState(feature.enumId)
                    .subOnce((result) => {
                        if (result) {
                            Object.assign(feature, result);
                        }
                    });
            }
        });
    }
}
