import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ImageButtonComponent } from './image-button.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CbHandleInvalidImageModule } from '@app/shared/directives/handle-invalid-image/handle-invalid-image.module';

const COMPONENTS = [
    ImageButtonComponent,
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        CbHandleInvalidImageModule
    ],
    exports: COMPONENTS
})

export class CbImageButtonModule { }
