import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NotificationsComponent } from './notifications.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbInfiniteScrollModule } from 'cb-hub-lib';

@NgModule({
    declarations: [NotificationsComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        CbFormsModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatListModule,
        CbUserDateTimeModule,
        CbInfiniteScrollModule,
        RouterModule.forRoot([
            {
                component: NotificationsComponent,
                path: 'notifications',
                data: { breadcrumb: 'Notifications' },
            }
        ]),
    ]
})
export class NotificationsModule { }
