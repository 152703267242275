<div class="flex-row flex"
     style="overflow-x: auto">
    <div class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex-100">
        <div class="start-center flex-row">
            <h3 class="mat-title flex">
                House Areas
            </h3>
            <button class="header-button"
                    mat-raised-button
                    (click)="newItemClicked();"
                    color="primary">
                Add New House Area
            </button>
        </div>
        <div class="cb-table cb-margin-top">
            <mat-table [dataSource]="searchResults">
                <ng-container matColumnDef="specGroup">
                    <mat-header-cell *matHeaderCellDef>
                        House Area
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.label}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sortOrder">
                    <mat-header-cell *matHeaderCellDef>
                        Sort Order
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.sortOrder}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="isQsOnly">
                    <mat-header-cell *matHeaderCellDef>
                        QS Only
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-icon>
                            {{element.isQsOnly ? "check_box" : "check_box_outline_blank"}}
                        </mat-icon>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions"
                              stickyEnd>
                    <mat-header-cell *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-raised-button
                                mat-icon-button
                                (click)="editItemClicked(element)">
                            <mat-icon>
                                edit
                            </mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <cb-info-message *ngIf="searchResultsLoaded && searchResults.length === 0"
                             [message]="'There are no House Areas to display'"
                             [icon]="'info_circle'"></cb-info-message>
            <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
        </div>
    </div>
</div>
