import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericQuestionListComponent } from './generic-question-list/generic-question-list.component';
import { MatDividerModule } from '@angular/material/divider';
import { GenericQuestionItemComponent } from './generic-question-item/generic-question-item.component';
import { CbUserDateTimeModule } from '../user-date-time/user-date-time.module';
import { GenericQuestionEditDialogComponent } from './generic-question-edit-dialog/generic-question-edit-dialog.component';
import { CbDialogModule } from '../dialog/dialog.module';
import { CbFormsModule } from '../forms/forms.module';
import { FormsModule } from '@angular/forms';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { GenericQuestionResponseDialogComponent } from './generic-question-response-dialog/generic-question-response-dialog.component';
import { CbDisplayValueModule } from '../display-value/display-value.module';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';



@NgModule({
    declarations: [GenericQuestionListComponent, GenericQuestionItemComponent, GenericQuestionEditDialogComponent, GenericQuestionResponseDialogComponent],
    exports: [
        GenericQuestionListComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatDividerModule,
        CbUserDateTimeModule,
        CbDialogModule,
        CbFormsModule,
        CbClickModule,
        CbDisplayValueModule,
        CbSortByModule,
        MatMenuModule,
    ]
})
export class CbGenericQuestionsModule { }
