import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { BehaviorSubject } from 'rxjs';

export class Popup {
    public description: string;
    public disabled: boolean;
    public buttonText: string;
    public horizontalPosition: 'left' | 'right';
    public verticalPosition: 'top' | 'bottom';
    /** Should always be absolute unless element will never appear on top of the stack with absolute.
     *  This is an escape hatch for extremely hard to fix z-index issues.
     */
    public cssPosition: 'absolute' | 'fixed';
}
/**
 * Please use for basically all buttons.
 *
 *
 * Green Raised:
 *
        <cb-button [disabled]="!canRequestPricing()"
            (clicked)="requestPricing()">
            Request Pricing
        </cb-button>
 * Blue Stroked with Popup:

        <cb-button [disabled]="!canRequestPricing()"
            (clicked)="requestPricing()"
            (popupClick)="goToLotSpecification()"
            color="accent"
            [isStroked]="true"
            [popup]="{
                description: 'There are items on your Lot Specification that are missing information. Please check all your Lot Spec Items have a Cost Type and Product Name/Description',
                buttonText: 'Go to Lot Specification',
                horizontalPosition: 'right'
            }">
        Request Pricing
        </cb-button>

 * Icon button with tooltip:

        <cb-button [disabled]="!canRequestPricing()"
            (clicked)="requestPricing()"
            icon="info"
            tooltip="Some stuff and stuff">
        Request Pricing
        </cb-button>
 *
 * iconColor - can be primary, accent, warn or danger.
 * Note that danger is an extention. To add more colours please create an entry in colors.scss as such
   .mat-primary.cb-icon-color-[NAME] {
      color: [color];
   }
 */
@Component({
    selector: 'cb-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})

export class ButtonComponent {

    private static materialIconColors = { primary: true, accent: true, warn: true };
    /**
     * Warning - calling this "click" causes bug due to naming conflict
     */
    @Output() public readonly clicked = new EventEmitter<boolean>();
    @Output() public readonly popupClick = new EventEmitter();
    @Input() public color = 'primary';
    @Input() public iconColor;
    public disabled$ = new BehaviorSubject<boolean>(false);
    @Input() public set disabled(value: boolean) {
        this.disabled$.next(value);
    }

    public get disabled(): boolean {
        return this.disabled$.value;
    }

    @Input() public isStroked = false;
    @Input() public popup?: Popup;
    @Input() public icon?: string;
    @Input() public tooltip?: string;
    @Input() public clickLimit = 0;
    @Input() public menu?: MatMenu;
    @Input() public menuButtonLabel?: string;
    @Input() public buttonLabel?: string;
    @Input() public isMenuItem?: boolean;
    @Input() public menuItemLabel?: string;

    constructor(private readonly cdRef: ChangeDetectorRef) { }

    public readonly onClick = (event: MouseEvent): void => {
        this.clicked.next(true);
    };

    public readonly onPopupClick = (event: MouseEvent): void => {
        this.popupClick?.emit(event);
    };

    public getIconColor(): string {
        if (ButtonComponent.materialIconColors[this.iconColor]) {
            return this.iconColor;
        }
        return 'primary';
    }

    public getIconClass(): string {
        if (ButtonComponent.materialIconColors[this.iconColor]) {
            return '';
        }
        return 'cb-icon-color-' + this.iconColor;

    }
}
