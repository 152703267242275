import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cbTruncate'
})
export class TruncatePipe implements PipeTransform {
    public transform(value: string, maxLength: number): string {
        if (value != null && value.length > maxLength) {
            value = value.substr(0, maxLength) + '...';
        }
        return value;
    }
}
