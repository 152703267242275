import * as _ from 'lodash';

import {CurrentUserService} from '../authentication/current.user';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

export interface IMyClassicPermissions {
    myClassicCanView(): boolean;
    myClassicCanCreateAccount(): boolean;
    myClassicCanChangeUsername(): boolean;
    myClassicResetPassword(): boolean;
    myClassicCanChangeAccess(): boolean;
}

export interface IBaseLotPermissions {
    canApplyLotSpec(): boolean;
    canCreate(): boolean;
    canCreateLotSpec(): boolean;
    canEdit(): boolean;
    canImport(): boolean;
    canRelease(): boolean;
    canView(): boolean;
}

export interface IBaseDesignSchemePermissions {
    canCreate(): boolean;
    canEdit(): boolean;
    canView(): boolean;
}

export interface IBasePricingRevisionPermissions {
    canCreate(): boolean;
    canEdit(): boolean;
    canView(): boolean;
}

export interface IBaseWorkingDrawingPermissions {
    canCreate(): boolean;
    canEdit(): boolean;
    canView(): boolean;
}

export interface IBaseCouncilRfiPermissions {
    canCreate(): boolean;
    canEdit(): boolean;
    canView(): boolean;
}

export interface IBasePreConsentPermissions {
    canCreate(): boolean;
    canEdit(): boolean;
    canView(): boolean;
}

export interface IBaseLotChangePermissions {
    canCreate(): boolean;
    canEdit(): boolean;
    canView(): boolean;
}

export interface IContactPermissions {
    canAddContact(): boolean;
    canEditContact(): boolean;
    canChangeMainContact(): boolean;
    canRemoveContact(): boolean;
}

export interface IDocumentPermissions {
    canUploadDocument(): boolean;
    canViewDocuments(): boolean;
    canReviewDocuments(): boolean;
    canEditDocuments(): boolean;
    canDeleteDocuments(): boolean;
}

export interface ILotInterestPermissions {
    canAddLotInterest(): boolean;
    canRemoveLotInterest(): boolean;
    canSetLotInterestasMain(): boolean;
}

export interface INotePermissions {
    canCreateNote(): boolean;
    canEditNote(): boolean;
    canDeleteNote(): boolean;
    canViewNotes(): boolean;
}

export interface ITaskPermissions {
    canViewTask(): boolean;
    canEditTask(): boolean;
    canCompleteTask(): boolean;
    canAddTask(): boolean;
}

export interface IManualPurchaseOrderPermissions {
    canManageManualOrder(): boolean;
    canSubmitManualOrder(): boolean;
    canApproveManualOrder(): boolean;
}

export interface IhaveBaseLotPermissions {
    getLotPermissions(): IBaseLotPermissions;
}

export interface IhaveContactPermissions {
    getContactPermissions(): IContactPermissions;
}

export interface IhaveDocumentPermissions {
    getDocumentPermissions(): IDocumentPermissions;
}

export interface IhaveLotInterestPermissions {
    getLotInterestPermissions(): ILotInterestPermissions;
}

export interface IhaveNotePermissions {
    getNotePermissions(): INotePermissions;
}

export interface IhaveTaskPermissions {
    getTaskPermissions(): ITaskPermissions;
}
export interface IHaveManualPurchaseOrdersPermissions {
    getManualPurchaseOrderPermissions(): IManualPurchaseOrderPermissions;
}

export interface IBasePermissions {
    observable: Observable<void>;
    $resolved: boolean;
    loaded(): boolean;
    gotoHome(): void;
}

export interface IBaseSystemAreaPermissions extends IBasePermissions {
    canView(): boolean;
    canEdit(): boolean;
    canCreate(): boolean;
    gotoHome(): void;
}

export class BasePermissions<PermissionEnumType> implements IBasePermissions {
    // Might need to store/cache these permissions in a global cache instead of per permissions instance
    public permissionCache = {};
    public observable: Observable<void>;
    public $resolved = false;

    constructor(
        public readonly key: string,
        public readonly permission: any,
        public readonly currentUser: CurrentUserService,
        public readonly http: HttpClient,
        public readonly router: Router
    ) {
        this.buildPermissionCache();
    }

    public permissionObject = (permissionEnum: PermissionEnumType): boolean => {
        if (!this.permissionCache) {
            return false;
        }

        const permissionFn = this.getPermission(Math.abs(+permissionEnum) as any);

        if (permissionFn) {
            return permissionFn();
        }

        // console.warn(`Unknown permission ${permissionEnum} for key ${this.key}`);

        return false;
    };

    public gotoHome(): void {
        this.router.navigate(['/']);
    }

    public loaded(): boolean {
        return !!this.currentUser.claims;
    }

    private buildPermissionCache(): void {
        this.observable = new Observable((observer) => {
            this.currentUser.$promise.then(() => {
                const object = {};
                const permissions = Object.keys(this.permission);

                for (const permissionKey of permissions) {
                    if (isNaN(+permissionKey)) {
                        continue;
                    }

                    object[`permissionObject_${this.key}_${permissionKey}`] =
                        _.partial(this.canPerformAction, permissionKey); // form two
                }

                this.permissionCache = object;
                observer.next();
            });
        });
        this.observable.subOnce(() => {
            this.$resolved = true;
        });
    }

    private readonly canPerformAction = (action: number): boolean => this.currentUser.isAdmin || this.and(this.currentUser.claims[this.key], action);

    private and(a, b): boolean {
        let result = 0;
        let mult = 1;
        while (a !== 0 && b !== 0) {

            result += mult * (a % 2) * (b % 2);
            a = Math.floor(a / 2);
            b = Math.floor(b / 2);
            mult = mult * 2;
        }
        return result > 0;
    }

    private readonly getPermission = (permission: PermissionEnumType): Function => this.permissionCache[`permissionObject_${this.key}_${permission}`];
}


