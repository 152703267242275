import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeatureToggleViewComponent} from './feature-toggle-view/feature-toggle-view.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {CbTableModule} from 'cb-hub-lib';
import {FormsModule} from '@angular/forms';
import {CbClickModule} from '@app/shared/directives/click/click.module';


@NgModule({
    declarations: [FeatureToggleViewComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        CbFormsModule,
        CbHubTableModule,
        CbTableModule,
        FormsModule,
        CbClickModule,
    ],
    exports: [
        FeatureToggleViewComponent
    ]
})
export class FeatureTogglesModule { }
