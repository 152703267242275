<mat-card class="main-card flex-col flex"
          *ngIf="!mappedItem.isDeleted">
    <mat-card-header class="start-start flex-row">
        <div class="header-icons start-center flex-row">
            <cb-lot-spec-item-icons [mappedItem]="mappedItem"></cb-lot-spec-item-icons>
            <span class="flex"
                  layout-padding></span>
        </div>
        <div class="start-start flex-col flex">
            <span class="mat-body-2"
                  *ngIf="!(mappedItem.isManualQuote && mappedItem.isQuoteCost)">{{mappedItem.productDisplay |
                cbTruncate:50}}</span>
            <span class="mat-body-2"
                  *ngIf="mappedItem.isManualQuote && mappedItem.isQuoteCost">{{mappedItem.quoteLineDescription |
                cbTruncate:50}}</span>
            <span class="mat-caption">{{mappedItem.productCode}}</span>
        </div>
        <div class="start-center flex-row">
            <h3 [matTooltip]="COST_TYPE_ENUM[mappedItem.costType]">{{COST_TYPE_ENUM[mappedItem.costType]?.substr(0, 1)}}
            </h3>
            <mat-icon class="cb-grey"
                      *ngIf="lotSpec?.isLocked"
                      matTooltip="Locked">
                lock
            </mat-icon>
        </div>
    </mat-card-header>
    <mat-card-content class="card-content cb-padding-top flex-row flex">
        <div class="flex-col flex">

            <!-- only show qty if is actual cost -->
            <cb-display-value-new *ngIf="mappedItem.isActualCost"
                                  label="Qty"
                                  [value]="mappedItem.quantity"></cb-display-value-new>

            <!-- <cb-currency label="'Rate'"
                         entity="mappedItem"
                         prop="'rate'"
                         [readonly]="true"
                         *ngIf="false">
            </cb-currency> -->
            <cb-display-value-new *ngIf="mappedItem.isEstimatedCost"
                                  label="Estimated Cost"
                                  [value]="mappedItem.rate"></cb-display-value-new>
            <cb-currency label="Provisional Cost"
                         [ngModel]="mappedItem.rate"
                         [readonly]="true"
                         [inclGst]="true"
                         *ngIf="mappedItem.isProvisionalOrSpecificationQuoteItem"></cb-currency>
            <cb-currency label="Amount Spent"
                         [ngModel]="mappedItem.amountSpent"
                         [readonly]="true"
                         [inclGst]="true"
                         *ngIf="mappedItem.isProvisionalOrSpecificationQuoteItem"></cb-currency>
            <cb-currency label="No Charge Cost"
                         [ngModel]="mappedItem.noChargeCost"
                         [readonly]="true"
                         *ngIf="mappedItem.isNoChargeCost">
            </cb-currency>
            <cb-display-value-new *ngIf="!mappedItem.isDescriptiveOnlyCost"
                                  label="Supplier"
                                  [value]="mappedItem.supplierDisplay"></cb-display-value-new>
            <cb-display-value-new *ngIf="mappedItem.productCategoryId > 0"
                                  label="Category"
                                  [value]="mappedItem.productCategory"></cb-display-value-new>
            <cb-display-value-new *ngIf="mappedItem.manualColourEntryRequired"
                                  label="Manual Colour"
                                  [value]="mappedItem.colourName"></cb-display-value-new>
            <div class="flex-col flex"
                 *ngIf="mappedItem.offeringOptions?.length > 0">
                <cb-display-value-new *ngFor="let option of mappedItem.offeringOptions"
                                      [label]="option.category"
                                      [value]="option.name"></cb-display-value-new>
            </div>
            <cb-text-area [ngModel]="mappedItem.productDisplay"
                          [readonly]="true"
                          *ngIf="mappedItem.isDescriptiveOnlyCost"></cb-text-area>
        </div>
        <div class="images flex-col">
            <cb-image-button class="flex"
                             *ngIf="!mappedItem.specItemImage"
                             [baseUrl]="mappedItem.image">
            </cb-image-button>
            <cb-image-button class="flex"
                             *ngIf="mappedItem.specItemImage"
                             [baseUrl]="mappedItem.specItemImage">
            </cb-image-button>

            <!-- [resizerParams]="'?h=200&mode=max'" -->
        </div>
    </mat-card-content>
    <mat-card-actions class="with-divider cb-padding-left start-center flex-row">

        <!-- <button mat-stroked-button
                color="accent"
                (click)="viewBundleItems()"
                *ngIf="isBundle">View Items</button> -->
        <button mat-stroked-button
                color="accent"
                *ngIf="!uiActions.hideQrefsButton()"
                (click)="uiActions.quoteRefsDialog()"
                [disabled]="!mappedItem.hasQuoteLines">Q Refs</button>
        <button mat-stroked-button
                color="accent"
                (click)="uiActions.showPurchaseOrders()"
                *ngIf="mappedItem.isSsrConfirmed">
            PO's
        </button>
        <span class="flex"></span>
        <button mat-stroked-button
                color="accent"
                (click)="uiActions.splitProduct()"
                *ngIf="uiActions.showSplitSpecItem()"
                [disabled]="!mappedItem.canSplit">Split</button>
        <button mat-stroked-button
                color="accent"
                (click)="uiActions.editLotSpecItem()"
                [disabled]="!mappedItem.canEdit"
                *ngIf="uiActions.canChangeSpecification()">Edit</button>
        <button mat-stroked-button
                (click)="uiActions.removeLotSpecItem()"
                [disabled]="lotSpec?.isLocked"
                *ngIf="uiActions.canChangeSpecification()">Remove</button>
    </mat-card-actions>
</mat-card>
