import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IARProgressPaymentsMappedItem } from '@app/logic/ar-progress-payments/interfaces/i.ar.progress.payments.mapped';
import { IARProgressPaymentsLogicService } from '@app/logic/ar-progress-payments/interfaces/i.ar.progress.payments.logic-service';
import { IARPaymentScheduleDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/ARPaymentScheduleDto';
import { BUILD_STAGE_EVENT_ENUM, ILotProgressPaymentDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ILotMappedItem } from '@app/logic/lots';

interface IData {
    mappedItem: IARProgressPaymentsMappedItem;
    lotMappedItem: ILotMappedItem;
}

@Component({
    selector: 'ar-edit-progresspayments-dialog',
    templateUrl: './ar-edit-progresspayments-dialog.component.html',
    styleUrls: ['./ar-edit-progresspayments-dialog.component.scss'],
})
export class AREditProgressPaymentsDialogComponent extends BaseDialogFormViewDirective<IARPaymentScheduleDto, IARProgressPaymentsMappedItem, IARProgressPaymentsLogicService> {
    public static readonly MIN_WIDTH = '60%';
    private readonly percentageMultiplier = 100;
    public BUILD_STAGE_EVENT_ENUM = BUILD_STAGE_EVENT_ENUM;

    public get mappedItem(): IARProgressPaymentsMappedItem {
        return this.data.mappedItem;
    }

    public get lotMappedItem(): ILotMappedItem {
        return this.data.lotMappedItem;
    }

    public get amountsHaveBeenChanged(): boolean{
        const amountsHaveChanged = this.mappedItem
            .$original
            .payments
            .filter(x => this.mappedItem.payments.find(y => y.id === x.id && y.dollarValue !== x.dollarValue))
            .length > 0;

        if (!amountsHaveChanged){
            this.mappedItem.changeReasonDescription = '';
        }
        return amountsHaveChanged;
    }

    public getOriginalAmountById(paymentId: number): number{
        return this.mappedItem.$original.payments.find(x => x.id === paymentId).dollarValue;
    }

    public get balance(): number {
        return this._contractPrice - this.stagePaymentsAmountTotal;
    }

    public get stagePaymentsAmountTotal(): number {
        if (this.mappedItem.payments != null &&
            this.mappedItem.payments.length > 0) {
            return this.mappedItem.payments.reduce((acc, progressPayment) => {
                if (progressPayment.dollarValue && !isNaN(progressPayment.dollarValue)) {
                    return acc + progressPayment.dollarValue;
                } else {
                    return acc;
                }
            }, 0);
        } else {
            return 0;
        }
    }

    public setPercentageFromDollarValue(progressPayment: ILotProgressPaymentDto): void {
        if(!isNaN(progressPayment.dollarValue)) {
            progressPayment.percentage = (progressPayment.dollarValue / this._contractPrice) * this.percentageMultiplier;
        }
    }

    private get _contractPrice(): number {
        return this.mappedItem.contractPriceWithGst;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<AREditProgressPaymentsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService
    ) {
        super(dialogRef, toastService);
    }

    public saveProgressPaymentsDetails(): void {

        this.saveInProgress = true;
        this.mappedItem.$save()
            .subOnce({
                next: (success: IARProgressPaymentsMappedItem) => {
                    if (success) {
                        this.toastService.saveSuccess();
                        this.dialogRef.close(this.mappedItem);
                    }else {
                        this.toastService.saveError('There was an error saving the updated details. Please try again.');
                    }
                },
                error: (error) => {
                    this.handleError(this.handleSaveError(error));
                },
                complete: () => {
                    this.saveInProgress = false;
                }
            });
    }

    public cancelDialog(): void {
        return this.dialogRef.close();
    }
}
