import { NgModule } from '@angular/core';
import { CbDasherModule } from 'cb-hub-lib';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CommonModule } from '@angular/common';
import { LotClassicContactsDialogComponent } from './lot-classic-contacts-dialog/lot-classic-contacts-dialog.component';
import { LotDetailsDialogComponent } from './lot-details-dialog/lot-details-dialog.component';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { LotSidebarComponent } from './lot-sidebar/lot-sidebar.component';
import { MatDividerModule } from '@angular/material/divider';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { CbAmenitiesModule } from '@app/shared/components/amenities/amenities.module';
import { CbButtonModule } from '@app/shared/components/button/button.module';
import { PriceOptionsComponent } from './price-options/price-options.component';

@NgModule({
    declarations: [
        LotDetailsDialogComponent,
        LotClassicContactsDialogComponent,
        LotSidebarComponent,
        PriceOptionsComponent
    ],
    exports: [
        LotDetailsDialogComponent,
        LotClassicContactsDialogComponent,
        LotSidebarComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        BrowserModule,
        CbDatePipeModule,
        MatButtonModule,
        CbDialogModule,
        CbFormsModule,
        FormsModule,
        ReactiveFormsModule,
        CbDisplayValueModule,
        MatDividerModule,
        CbEnumToLabelModule,
        CbDasherModule,
        CbAmenitiesModule,
        CbButtonModule

    ]
})

export class CbLotSidebarModule { }
