import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { CommonModule } from '@angular/common';
import { DragulaModule } from 'ng2-dragula';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule } from 'cb-hub-lib';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { SecurityRoleViewComponent } from './security-role-view/security-role-view.component';
import { SecurityRoleReportDialogComponent } from './security-role-report-dialog/security-role-report-dialog.component';
import { SecurityRoleListComponent } from './security-role-list.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
    declarations: [
        SecurityRoleListComponent,
        SecurityRoleReportDialogComponent,
        SecurityRoleViewComponent
    ],
    imports: [
        RouterModule.forRoot([
            {
                component: SecurityRoleListComponent,
                path: 'security-roles',
                data: {
                    breadcrumb: 'Security Roles',
                }
            },
            {
                component: SecurityRoleViewComponent,
                path: 'security-roles/view/:id',
                data: {
                    breadcrumb: 'Security Role View',
                    breadcrumbPredecessors: [
                        { label: 'Security Roles', url: 'security-roles' }
                    ]
                }
            },
            {
                component: SecurityRoleViewComponent,
                path: 'security-roles/create',
                data: {
                    breadcrumb: 'Security Role Create',
                    breadcrumbPredecessors: [
                        { label: 'Security Roles', url: 'security-roles' }
                    ]
                }
            }
        ], {}),
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        DragulaModule.forRoot(),
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbDisplayValueModule,
        CbClickModule,
        CbHubTableModule,
        CbTableModule,
        CbSortByModule,
        MatTabsModule,
        MatGridListModule,
        CbWaitForModule,
        MatDividerModule,
    ]
})
export class SecurityRolesModule { }
