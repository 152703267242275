<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon end start"
             [matTooltip]="tooltipMessage.value">
            <mat-icon [ngClass]="cardIconClass.value">{{cardIcon.value}}</mat-icon>
        </div>
        <mat-card-title [ngClass]="cardIconClass.value">{{stepNumber}}. Sale Details</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex flex-col">
        <cb-display-value-new label="Building Consultant"
                              [value]="clientSale?.buildingConsultantName"></cb-display-value-new>
        <cb-display-value-new label="Date Qualified"
                              [value]="clientSale?.qualifiedDate | date"></cb-display-value-new>
        <cb-display-value-new [ngClass]="!clientSale?.purchaseReasonId ? 'cb-error' : ''"
                              label="Purchase Reason"
                              [value]="clientSale?.purchaseReasonLabel"></cb-display-value-new>
        <cb-display-value-new label="Insured Party Name"
                              [value]="clientSale?.insuredPartyName"></cb-display-value-new>
        <cb-display-value-new label="Insured Party Email"
                              [value]="clientSale?.insuredPartyEmail"></cb-display-value-new>
        <cb-display-value-new label="Allow Nominee"
                              [value]="clientSale?.allowNominee | cbPrettyBool"></cb-display-value-new>
        <cb-display-value-new label="Build Duration"
                              [value]="clientSale?.buildDuration"></cb-display-value-new>
    </mat-card-content>
    <mat-card-actions class="flex-wrap end-center flex-row">
        <button mat-stroked-button
                *ngIf="clientSale?.canRevertPendingLost && permissions?.canMarkSaleLost()"
                (click)="openRevertPendingLostDialog()">Revert Stalled Status
        </button>
        <button mat-stroked-button
                *ngIf="permissions?.canRevertUnconditional() && clientSale.canRevertUnconditional"
                (click)="openRevertUnconditionalDialog()">Set as Conditional
        </button>
        <button mat-stroked-button
                *ngIf="hasSaleLostPermission.value"
                [disabled]="!clientSale?.canSetSaleLost"
                (click)="openSaleLostDialog()">Stalled or Lost
        </button>
        <button mat-stroked-button
                color="accent"
                *ngIf="permissions?.canEdit()"
                [disabled]="!clientSale?.canEditSaleDetails"
                (click)="openEditDialog()">
            <mat-icon>edit</mat-icon>
            Edit
        </button>
    </mat-card-actions>
</mat-card>
