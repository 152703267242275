import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { BusinessAccountPermissions } from '@app/core/permissions';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BusinessAccountLogicService, BusinessAccountMappedItem } from '@app/logic/business-accounts';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { BUSINESS_ACCOUNT_APPROVAL_OVERRIDE_REQUEST_OUTCOME_ENUM, BUSINESS_ACCOUNT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BusinessAccountApprovalReasonComponent } from '../business-account-approval-reason-dialog/business-account-approval-reason-dialog.component';

@Component({
    selector: 'cb-business-account-status',
    templateUrl: './business-account-status.component.html',
    styleUrls: ['./business-account-status.component.scss']
})
export class BusinessAccountStatusComponent {
    @Input() public account: BusinessAccountMappedItem;
    @Output() public accountChange = new EventEmitter();
    @Output() public editFinanceInformationClicked = new EventEmitter();
    @Output() public accountUpdated = new EventEmitter();

    public BUSINESS_ACCOUNT_STATUS_ENUM = BUSINESS_ACCOUNT_STATUS_ENUM;
    public BUSINESS_ACCOUNT_APPROVAL_OVERRIDE_REQUEST_OUTCOME_ENUM = BUSINESS_ACCOUNT_APPROVAL_OVERRIDE_REQUEST_OUTCOME_ENUM;

    constructor(
        public readonly permissions: BusinessAccountPermissions,
        public readonly currentUser: CurrentUserService,
        protected readonly cbDialog: CbDialogService,
        protected readonly toastService: ToastService,
        protected readonly businessAccountLogicService: BusinessAccountLogicService,
    ) {
    }

    public hasAbmCodeAlert(): boolean {
        return this.account && !this.account.abmCode
            && (this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.NewRequest
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.PendingRequestApproval
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Pending);
    }

    public hasApprovalOverride(): boolean {
        return this.account
            && (this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.NewRequest
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.PendingRequestApproval
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Pending
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Declined
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Overridden);
    }

    public canSubmitAccount(): boolean {
        return this.account.isNewRequest() && this.account.canSubmitForApproval && this.currentUser.hasBranchBusinessAccountRequestApprover();
    }

    public canSubmitRequest(): boolean {
        return this.account.isNewRequest() && this.account.canSubmitForApproval
            && (this.currentUser.hasBranchBusinessAccountRequestApprover() || this.currentUser.hasBranchBusinessAccountRequester());
    }

    public canCancelRequest(): boolean {
        return this.account.isPending() && this.canSubmitRequest();
    }

    public canApproveRequest(): boolean {
        return this.account.isPending() && this.currentUser.hasBranchBusinessAccountRequestApprover();
    }

    public canApproveContract(): boolean {
        return this.account.canProcurementApprove && this.permissions.canApproveContracts()
            && !this.account.approvedByProcurementUser && !this.account.declinedByUser
            && (this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Pending
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Overridden
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Declined);
    }

    public canApproveHealthAndSafety(): boolean {
        return this.account.canHealthSafetyApprove && this.permissions.canApproveHs()
            && !this.account.approvedByHealthAndSafetyUser && !this.account.healthandSafetyRequestDeclinedByUser
            && (this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Pending
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Overridden
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Declined);
    }

    public canRequestApprovalOverride(): boolean {
        return !this.account.approvalOverrideRequest && this.currentUser.hasBusinessAccountRequestApprovalOverride()
            && (this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.NewRequest
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.PendingRequestApproval
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Pending
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Declined
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Overridden);
    }

    public canApproveOverrideRequests(): boolean {
        return this.account.approvalOverrideRequest && !this.account.approvalOverrideRequest.outcome
            && (this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Pending
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.PendingRequestApproval
                || this.account.status === BUSINESS_ACCOUNT_STATUS_ENUM.NewRequest);
    }

    public onSubmitAccountClicked(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Submit Account for Approval',
            message: 'Are you sure you want to submit this Business Account for approval?',
            confirmed: (): void => {
                this.businessAccountLogicService.submitAccount(this.account.id)
                    .subOnce(savedAccount => {
                        this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem);
                        this.accountUpdated.emit();
                    });
            }
        });
    }

    public onSubmitRequestClicked(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Submit Request for Approval',
            message: 'Are you sure you want to submit this Business Account Request for approval?',
            confirmed: (): void => {
                this.businessAccountLogicService.submitRequest(this.account.id)
                    .subOnce(savedAccount => {
                        this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem);
                        this.accountUpdated.emit();
                    });
            }
        });
    }

    public onCancelRequestClicked(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Cancel Business Account Request',
            message: 'Are you sure you want to cancel this Business Account Request?',
            confirmed: (): void => {
                this.businessAccountLogicService.cancelAccountRequest(this.account.id)
                    .subOnce(savedAccount => {
                        this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem);
                        this.accountUpdated.emit();
                    });
            }
        });
    }

    public onApproveRequestClicked(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Approve Business Account Request',
            message: 'Are you sure you want to approve this Business Account request?',
            confirmed: (): void => {
                this.businessAccountLogicService.approveRequest(this.account.id)
                    .subOnce(savedAccount => {
                        this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem);
                        this.accountUpdated.emit();
                    });
            }
        });
    }

    public onDeclineRequestClicked(): void {
        const dialog = this.cbDialog.open(BusinessAccountApprovalReasonComponent, {
            data: {
                dialogHeading: 'Decline Business Account Request',
                message: 'Are you sure you want to decline this Business Account request?',
                action: 'Decline'
            }
        });
        dialog.afterClosed().subOnce((reason: string) => {
            if (reason) {
                this.businessAccountLogicService.declineRequest(this.account.id, reason)
                    .subOnce(savedAccount => {
                        this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem);
                        this.accountUpdated.emit();
                    });
            }
        });
    }

    public onApproveContractClicked(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Approve Contract Approval',
            message: 'Are you sure you want to give Contract approval?',
            confirmed: (): void => {
                this.businessAccountLogicService.approveContract(this.account.id)
                    .subOnce(savedAccount => {
                        this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem);
                        this.accountUpdated.emit();
                    });
            }
        });
    }

    public onDeclineContractClicked(): void {
        const dialog = this.cbDialog.open(BusinessAccountApprovalReasonComponent, {
            data: {
                dialogHeading: 'Decline Contract Approval',
                message: 'Are you sure you want to decline this Business Account Contract?',
                action: 'Decline'
            }
        });
        dialog.afterClosed().subOnce((reason: string) => {
            if (reason) {
                this.businessAccountLogicService.declineContract(this.account.id, reason)
                    .subOnce(savedAccount => {
                        this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem);
                        this.accountUpdated.emit();
                    });
            }
        });
    }

    public onApproveHealthAndSafetyClicked(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Approve Health and Safety Approval',
            message: 'Are you sure you wish to give Health and Safety approval?',
            confirmed: (): void => {
                this.businessAccountLogicService.approveHealthAndSafety(this.account.id)
                    .subOnce(savedAccount => {
                        this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem);
                        this.accountUpdated.emit();
                    });
            }
        });
    }

    public onDeclineHealthAndSafetyClicked(): void {
        const dialog = this.cbDialog.open(BusinessAccountApprovalReasonComponent, {
            data: {
                dialogHeading: 'Decline Health and Safety Approval',
                message: 'Are you sure you want to decline this Business Account Health and Safety?',
                action: 'Decline'
            }
        });
        dialog.afterClosed().subOnce((reason: string) => {
            if (reason) {
                this.businessAccountLogicService.declineHealthAndSafety(this.account.id, reason)
                    .subOnce(savedAccount => {
                        this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem);
                        this.accountUpdated.emit();
                    });
            }
        });
    }

    public onRequestApprovalOverrideClicked(): void {
        const dialog = this.cbDialog.open(BusinessAccountApprovalReasonComponent, {
            data: {
                dialogHeading: 'Request Approval Override',
                message: 'You are about to request from the Directors that the usual approval process for establishing a Business Account be overridden.<br>'
                    + 'Please enter the reason why this process needs to be overridden',
                action: 'Request Override'
            }
        });
        dialog.afterClosed().subOnce((reason: string) => {
            if (reason) {
                this.businessAccountLogicService.requestOverrideApproval(this.account.id, reason)
                    .subOnce(savedAccount => {
                        this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem);
                        this.accountUpdated.emit();
                    });
            }
        });
    }

    public onApproveOverrideClicked(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Approve Approval Override',
            message: 'Are you sure you want to approve the Approval Override Request for this Account?',
            confirmed: (): void => {
                this.businessAccountLogicService.approveOverrideApproval(this.account.id)
                    .subOnce(savedAccount => {
                        this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem);
                        this.accountUpdated.emit();
                    });
            }
        });
    }

    public onDeclineOverrideClicked(): void {
        const dialog = this.cbDialog.open(BusinessAccountApprovalReasonComponent, {
            data: {
                dialogHeading: 'Decline Approval Override',
                message: 'Are you sure you want to decline the Approval Override Request for this Account?',
                action: 'Decline'
            }
        });
        dialog.afterClosed().subOnce((reason: string) => {
            if (reason) {
                this.businessAccountLogicService.approveOverrideApproval(this.account.id, true, reason)
                    .subOnce(savedAccount => {
                        this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem);
                        this.accountUpdated.emit();
                    });
            }
        });
    }
}
