<cb-table class="cb-margin-top"
          label="Trade Types"
          [columns]="columns"
          [loaded]="loaded"
          [minWidth]="1000"
          [maxHeightOffset]="271">
    <tbody cbTableBody>
        <ng-container *ngFor="let tradeType of orderTradeTypes(tradeTypes)">
            <tr>
                <td>
                    <button mat-raised-button
                            mat-icon-button
                            (click)="expanded[tradeType.id] = !expanded[tradeType.id]"
                            *ngIf="tradeType.mappedChildren?.length > 0">
                        <mat-icon>
                            {{expanded[tradeType.id] ? 'arrow_drop_up' :
                                'arrow_drop_down'}}
                        </mat-icon>
                    </button>
                </td>
                <td>
                    {{tradeType.label}}
                    <span *ngIf="!tradeType.isActive">
                        &nbsp;(Inactive)
                    </span>
                </td>
                <td>
                    -
                </td>
                <td>
                    -
                </td>
                <td>
                    -
                </td>
                <cb-td-checkbox [value]="tradeType.isActive"></cb-td-checkbox>
                <td cbTableStickyCol="end"
                    cbTableShrinkCol>
                    <button mat-raised-button
                            mat-icon-button
                            (click)="editTradeType(tradeType)"
                            [disabled]="!permissions?.canEdit()">
                        <mat-icon>
                            edit
                        </mat-icon>
                    </button>
                </td>
            </tr>
            <ng-container *ngIf="expanded[tradeType.id]">
                <ng-container *ngFor="let child of orderTradeTypes(tradeType?.filteredChildren)">
                    <tr class="child">
                        <td></td>
                        <td class="childLabel">
                            {{child.label}}
                        </td>
                        <td *ngIf="child.requiredComplianceRegistrations.length > 0">
                            <ul class="inline-list">
                                <li *ngFor="let complianceReg of child.requiredComplianceRegistrations">
                                    {{complianceReg.label}}
                                </li>
                            </ul>
                        </td>
                        <td *ngIf="child.requiredComplianceRegistrations.length < 1">
                            -
                        </td>
                        <cb-td-checkbox [value]="child.includeInLiveSchedule"></cb-td-checkbox>
                        <cb-td-checkbox [value]="child.canRequestSevenDayPayments"></cb-td-checkbox>
                        <cb-td-checkbox [value]="child.isActive"></cb-td-checkbox>
                        <td cbTableStickyCol="end"
                            cbTableShrinkCol>
                            <button mat-icon-button
                                    mat-raised-button
                                    (click)="editTradeType(child)">
                                <mat-icon>
                                    edit
                                </mat-icon>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </ng-container>
    </tbody>
</cb-table>
