<mat-card class="cb-margin flex-row flex"
          style="overflow-x: auto">
    <div class="flex-col flex-100">
        <div class="start-center flex-row">
            <h3 class="mat-title flex">Build Programme Templates</h3>
            <button class="header-button"
                    mat-raised-button
                    (click)="newItemClicked();"
                    color="primary">
                Create New Template
            </button>
        </div>
        <div class="cb-margin-top flex-col">
            <cb-build-programme-template-table [templates]="searchResults"
                                               (viewTemplate)="viewTemplateClicked($event)"
                                               (editTemplate)="editItemClicked($event, true)"
                                               (duplicateTemplate)="duplicateItemClicked($event)">
            </cb-build-programme-template-table>
        </div>
    </div>
</mat-card>
