<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon"
             [matTooltip]="tooltipMessage.value">
            <mat-icon [ngClass]="cardIconClass.value">{{cardIcon.value}}</mat-icon>
        </div>
        <mat-card-title [ngClass]="cardIconClass.value">{{stepNumber}}. Client Account</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex flex-col">
        <cb-display-value-new label="Name"
                              [value]="clientSale?.clientAccount?.name"></cb-display-value-new>
        <cb-display-value-new label="Legal Name"
                              [value]="clientSale?.clientAccount?.legalName"></cb-display-value-new>
        <cb-display-value-new label="Account Type"
                              [value]="clientSale?.clientAccount?.accountTypeLabel"></cb-display-value-new>
        <cb-display-value-new label="Main Contact Name"
                              [value]="clientSale?.clientAccount?.mainContactName"></cb-display-value-new>
        <cb-display-value-new label="Staff Member Exists"
                              [value]="clientSale?.clientAccount?.staffMemberExists | cbPrettyBool">
        </cb-display-value-new>
        <cb-display-value-new label="Solicitor Name"
                              [value]="clientSale?.clientAccount?.solicitorName"></cb-display-value-new>
        <cb-display-value-new label="Solicitor Email"
                              [value]="clientSale?.clientAccount?.solicitorEmail">
        </cb-display-value-new>
    </mat-card-content>
    <mat-card-actions class="end-center flex-row">
        <button mat-stroked-button
                (click)="viewClientAccount($event)">
            <mat-icon svgIcon="card-search"></mat-icon>
            View Account
        </button>
        <button mat-stroked-button
                color="accent"
                *ngIf="permissions?.canEdit()"
                [disabled]="!clientSale?.canEditClientAccount"
                (click)="openEditDialog()">
            <mat-icon>edit</mat-icon>
            Edit
        </button>
    </mat-card-actions>
</mat-card>
