<cb-dialog>
    <form class="flex-col flex"
          #form="ngForm">
        <div class="flex-row">
            <div class="flex-60">
                <cb-input label="Group Name"
                          name="name'"
                          [required]="true"
                          [(ngModel)]="mappedItem.name"></cb-input>
                <div class="start-center flex-row">
                    <div class="flex-col">
                        <cb-select label="Code Name"
                                   name="codeName'"
                                   [options]="documentGroupCodes"
                                   [(ngModel)]="mappedItem.codeName"
                                   (change)="onCodeChanged()">
                        </cb-select>
                        <div *ngIf="isCodeDuplicated"
                             class="cb-padding-bottom-2x">
                            <mat-error>
                                <small>
                                    Code name is already in use
                                </small>
                            </mat-error>
                        </div>
                    </div>
                    <cb-info-popup [icon]="'info'"
                                   [classList]="'mat-accent'"
                                   [tooltip]="'This is only required if the code needs to access this group programmatically.'">
                    </cb-info-popup>
                </div>
            </div>
            <div class="flex-40">
                <cb-checkbox label="Is Active"
                             name="isActive"
                             [(ngModel)]="mappedItem.isActive"></cb-checkbox>
                <div class="flex-row">
                    <cb-checkbox label="Code Only"
                                 name="codeOnly"
                                 class="cb-padding-top"
                                 [(ngModel)]="mappedItem.codeOnly"></cb-checkbox>
                    <cb-info-popup [icon]="'info'"
                                   [tooltip]="'If selected this will disable upload from all system areas. This is used to allow document stubs to be created by the back end code only.'">
                    </cb-info-popup>
                </div>
            </div>
        </div>
        <div class="flex-row">
            <div class="cb-padding-right flex-50">
                <div class="flex-row space-between-start">
                    <div class="mat-subheading-2">
                        Document Types
                    </div>
                    <button mat-raised-button
                            color="primary"
                            (click)="onAddEditDocumentTypeClick()">
                        Add Document Type
                    </button>
                </div>
                <cb-table [loaded]="true">
                    <thead cbTableHead>
                        <tr>
                            <td>
                                Document Type
                            </td>
                            <td style="width: 120px;">
                                Active
                            </td>
                            <td style="width: 60px;"></td>
                        </tr>
                    </thead>
                    <tbody cbTableBody>
                        <tr *ngFor="let type of mappedItem.types">
                            <td>
                                {{ getDocumentTypeDisplayLabel(type.documentType) }}
                            </td>
                            <td>
                                <mat-icon>
                                    {{type.isActive ? "check_box" : "check_box_outline_blank"}}
                                </mat-icon>
                            </td>
                            <td>
                                <button mat-raised-button
                                        mat-icon-button
                                        (click)="onAddEditDocumentTypeClick(type)">
                                    <mat-icon>
                                        edit
                                    </mat-icon>
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="!mappedItem.types?.length">
                            <td colspan="3">
                                <mat-icon color="accent">
                                    info
                                </mat-icon>
                                <span>
                                    &nbsp;There are no Document Type for this Group
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </cb-table>
            </div>
            <div class="cb-padding-left flex-50">
                <cb-table [loaded]="true">
                    <thead cbTableHead>
                        <tr>
                            <td>
                                View from System Area
                                <cb-info-popup [icon]="'info'"
                                               [tooltip]="'Note that a document group can be viewed from lots of system areas but uploads can only happen from one system area.'">
                                </cb-info-popup>
                            </td>
                            <td style="width: 120px;">
                                Can Upload
                            </td>
                            <td style="width: 60px;"></td>
                        </tr>
                    </thead>
                    <tbody cbTableBody>
                        <tr *ngIf="!!availableSystemAreas.length">
                            <td colspan="2">
                                <form>
                                    <cb-select label="Select System Area"
                                               name="systemArea'"
                                               [options]="availableSystemAreas"
                                               [(ngModel)]="selectedSystemAreaId">
                                    </cb-select>
                                </form>
                            </td>
                            <td>
                                <button mat-raised-button
                                        mat-icon-button
                                        [disabled]="!selectedSystemAreaId"
                                        (click)="onAddSystemAreaCliced()">
                                    <mat-icon>
                                        add
                                    </mat-icon>
                                </button>
                            </td>
                        </tr>
                        <tr *ngFor="let systemArea of mappedItem.systemAreas">
                            <td>
                                {{systemArea.systemArea | cbEnumToLabel: SYSTEM_AREA_ENUM}}
                            </td>
                            <td class="checkbox-list">
                                <form class="start-center">
                                    <cb-checkbox label
                                                 name="selectedSystemArea'"
                                                 [ngModel]="systemArea.isDefaultGroup"
                                                 (change)="setDefaultSystemArea(systemArea)">
                                    </cb-checkbox>
                                </form>
                            </td>
                            <td>
                                <button mat-raised-button
                                        mat-icon-button
                                        (click)="onRemoveSystemAreaClick(systemArea)">
                                    <mat-icon>
                                        remove_circle
                                    </mat-icon>
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="!mappedItem.systemAreas?.length">
                            <td colspan="3">
                                <mat-icon color="accent">
                                    info
                                </mat-icon>
                                <span>
                                    &nbsp;There are no System Areas for this Group
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </cb-table>
            </div>
        </div>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="!form.valid || !canSave()">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>