<div #infiniteScrollContainer
     class="cb-padding-left-2x cb-padding-right-2x cb-padding-bottom-2x cb-padding-top flex-col flex">
    <mat-card class="flex-col flex-100">
        <div class="flex-col flex-100">
            <div class="flex-col">
                <h3 class="mat-title flex"
                    style="margin: 0;">Client Sales
                </h3>
                <mat-divider [inset]="true"
                             class="cb-margin-bottom"> </mat-divider>
            </div>
            <div class="cb-table cb-margin-top">
                <mat-table [dataSource]="jobs">
                    <ng-container matColumnDef="saleNumber">
                        <mat-header-cell *matHeaderCellDef> Client Sale # </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.saleNumber}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lotJobNumber">
                        <mat-header-cell *matHeaderCellDef> Job Number </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.lotJobNumber}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="buildType">
                        <mat-header-cell *matHeaderCellDef> Build Type </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.buildType }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="clientSaleStatus">
                        <mat-header-cell *matHeaderCellDef> Client Sale Status </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{CLIENT_SALE_STATUS_ENUM[element.status]}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="street">
                        <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.address.street}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="suburb">
                        <mat-header-cell *matHeaderCellDef> Suburb </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.address.suburb}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lotStatus">
                        <mat-header-cell *matHeaderCellDef> Lot Status </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{LOT_JOB_STATUS_ENUM[element.lotStatus]}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="buildingConsultant">
                        <mat-header-cell *matHeaderCellDef> Building Consultant </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.buildingConsultant}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions"
                                  stickyEnd>
                        <mat-header-cell *matHeaderCellDef>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <button mat-raised-button
                                    mat-icon-button
                                    (click)="viewClientAccount(element, $event)">
                                <mat-icon>search</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="
                                    displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div class="cb-margin flex-col"
                     *ngIf="jobsLoaded && jobs.length === 0 || !jobsLoaded">
                    <cb-info-message *ngIf="jobsLoaded && jobs.length === 0"
                                     [message]="'This Client Account has no Client Sales'"
                                     [icon]="'info_circle'"></cb-info-message>
                    <cb-loading-spinner *ngIf="!jobsLoaded"></cb-loading-spinner>
                </div>
            </div>
        </div>
        <div class="flex-col flex-100">
            <div class="flex-col">
                <h3 class="mat-title cb-padding-top-2x flex"
                    style="margin: 0;">Business Accounts
                </h3>
                <mat-divider [inset]="true"
                             class="cb-margin-bottom"> </mat-divider>
            </div>
            <div class="cb-table cb-margin-top">
                <mat-table [dataSource]="thirdPartySales">
                    <ng-container matColumnDef="saleNumber">
                        <mat-header-cell *matHeaderCellDef> Client Sale # </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.saleNumber}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lotJobNumber">
                        <mat-header-cell *matHeaderCellDef> Job Number </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.lotJobNumber}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="buildType">
                        <mat-header-cell *matHeaderCellDef> Build Type </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.buildType }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="clientSaleStatus">
                        <mat-header-cell *matHeaderCellDef> Client Sale Status </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{CLIENT_SALE_STATUS_ENUM[element.status]}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="street">
                        <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.address.street}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="suburb">
                        <mat-header-cell *matHeaderCellDef> Suburb </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.address.suburb}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lotStatus">
                        <mat-header-cell *matHeaderCellDef> Lot Status </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{LOT_JOB_STATUS_ENUM[element.lotStatus]}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="buildingConsultant">
                        <mat-header-cell *matHeaderCellDef> Building Consultant </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.buildingConsultant}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions"
                                  stickyEnd>
                        <mat-header-cell *matHeaderCellDef>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <button mat-raised-button
                                    mat-icon-button
                                    (click)="viewClientAccount(element, $event)">
                                <mat-icon>search</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="
                                    displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div class="cb-margin flex-col">
                    <cb-info-message *ngIf="thirdPartySalesLoaded && thirdPartySales.length === 0"
                                     [message]="' This Client Account has no Third Party Vendor Lots'"
                                     [icon]="'info_circle'"></cb-info-message>
                    <cb-loading-spinner *ngIf="!thirdPartySalesLoaded"></cb-loading-spinner>
                </div>
            </div>
        </div>
    </mat-card>
</div>
