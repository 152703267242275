<form #contractGenerationForm="ngForm"
      class="cb-margin-2x flex-row flex-100">
    <div class="cb-margin-right-3x flex-col flex-33">
        <cb-input label="Company Name"
                  name="companyName"
                  [required]="true"
                  [(ngModel)]="contractGenerationDto.companyName">
        </cb-input>
        <cb-input label="Street Address"
                  name="streetAddress"
                  [required]="true"
                  [(ngModel)]="contractGenerationDto.streetAddress">
        </cb-input>
        <cb-input label="Town / city"
                  name="city"
                  [required]="true"
                  [(ngModel)]="contractGenerationDto.city">
        </cb-input>
        <cb-select label="Payment Terms"
                   [(ngModel)]="contractGenerationDto.paymentTerms"
                   name="paymentTerms"
                   [options]="paymentFrequencies"
                   [required]="true"></cb-select>
        <cb-select label="Document Type"
                   [(ngModel)]="contractGenerationDto.documentType"
                   name="documentType"
                   [options]="contractGenerationDocTypes"
                   [required]="true"></cb-select>
        <cb-select label="Format"
                   [(ngModel)]="contractGenerationDto.documentFormat"
                   name="documentFormat"
                   [options]="contractGenerationDocFormats"
                   [required]="true"></cb-select>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x flex-col flex-33">
        <cb-selection-list name="businessEntities"
                           [(ngModel)]="contractGenerationDto.businessEntities"
                           label="Business Entities"
                           [options]="availableBusinessEntities"
                           [slim]="true">
        </cb-selection-list>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x flex-col flex-33">
        <h4 class="cb-title">Trade Types</h4>
        <div *ngFor="let parent of availableTradeTypes"
             class="cb-margin-top flex-col">
            <button class="trade-type-heading center-center flex-row flex"
                    (click)="parent.isOpen=!parent.isOpen">
                <span class="mat-subheading-2 cb-no-margin-bottom cb-margin-right-2x">{{parent.label}}</span>
                <hr class="mat-divider flex"/>
                <mat-icon>{{parent.isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
            </button>
            <div *ngIf="parent.isOpen"
                 class="list cb-padding-left cb-padding-top">
                <cb-checkbox *ngFor="let child of parent.children"
                             [label]="child.label"
                             [name]="'tradeType'+child.id"
                             (change)="onTradeTypeSelectionChanged(child, $event)"></cb-checkbox>
            </div>
        </div>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="generateContract()"
                [disabled]="!(contractGenerationForm.dirty ) || !contractGenerationForm.valid || !(contractGenerationDto.businessEntities?.length > 0) || !(contractGenerationDto.tradeTypes?.length > 0)">Generate
        </button>
    </div>
</form>
