import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbInfoMessageModule, CbLoadingSpinnerModule} from 'cb-hub-lib';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {CbDisplayValueModule} from '@app/shared/components/display-value/display-value.module';
import {DragulaModule} from 'ng2-dragula';
import {BuildStagesComponent} from './build-stages.component';
import {BuildStageDialogComponent} from './components/build-stage-dialog/build-stage-dialog.component';
import {CbClickModule} from '@app/shared/directives/click/click.module';

@NgModule({
    declarations: [
        BuildStagesComponent,
        BuildStageDialogComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        DragulaModule.forRoot(),
        MatCheckboxModule,
        MatInputModule,
        MatRadioModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbDisplayValueModule,
        CbClickModule,
    ],
    exports: [
        BuildStagesComponent
    ]
})
export class BuildStagesSettingsModule { }
