<cb-dialog dialogHeading="Create Client Sale">
    <form #form="ngForm">
        <cb-lot-autocomplete-scroller label="House & Land / Design & Land Lot"
                                      name="lotSelect"
                                      [(ngModel)]="selectedLot"
                                      [required]="true"
                                      [requireMatch]="true"
                                      [matchProps]="['id']"
                                      [availableOnly]="true"
                                      [lotContractTypes]="allowedContractTypes">
        </cb-lot-autocomplete-scroller>
        <div class="start-center flex-row">
            <cb-contact-autocomplete-scroller class="cb-margin-right flex"
                                              label="Main Contact"
                                              name="mainContact"
                                              [(ngModel)]="mainContact"
                                              [required]="true"
                                              (change)="contactSearchParams.recompute()"
                                              [active]="true"
                                              [requireMatch]="true"
                                              [matchProps]="['id']"></cb-contact-autocomplete-scroller>
            <button mat-raised-button
                    mat-icon-button
                    (click)="createMainContact()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="start-center flex-row">
            <cb-contact-autocomplete-scroller class="cb-margin-right flex"
                                              label="Additional Contact"
                                              name="additionalContact"
                                              [(ngModel)]="additionalContact"
                                              [required]="false"
                                              (change)="contactSearchParams.recompute()"
                                              [active]="true"
                                              [requireMatch]="true"
                                              [matchProps]="['id']"></cb-contact-autocomplete-scroller>
            <button mat-raised-button
                    mat-icon-button
                    (click)="createAdditionalContact()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <cb-datepicker label="Enquiry Date"
                       name="enquiryDate"
                       [(ngModel)]="createDto.enquiryDate"
                       pickerType="calendar"
                       [required]="true"></cb-datepicker>
        <cb-select label="Enquiry Origin"
                   name="enquiryOrigin"
                   [(ngModel)]="createDto.enquiryOrigin"
                   [options]="enquiryOrigins"
                   [required]="true"></cb-select>
        <cb-select *ngIf="createDto.enquiryOrigin === SHOWHOME_ORIGIN"
                   label="Showhome"
                   name="showhomeSelect"
                   [(ngModel)]="createDto.showhomeId"
                   [options]="showhomes"
                   [required]="true"></cb-select>
        <cb-input *ngIf="createDto.enquiryOrigin === SHOWHOME_ORIGIN && createDto.showhomeId === 0"
                  label="Other Showhome"
                  name="otherShowhome"
                  [(ngModel)]="createDto.otherShowhome"
                  [required]="true"></cb-input>
        <cb-user-autocomplete-scroller [tag]="BUILDING_CONSULTANT"
                                       label="Building Consultant"
                                       name="buildingConsultant"
                                       [(ngModel)]="buildingConsultant"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [required]="true"
                                       [active]="true"></cb-user-autocomplete-scroller>
        <cb-client-account-autocomplete-scroller label="Select Existing Client Account"
                                                 name="selectClientAccount"
                                                 [(ngModel)]="selectedAccount"
                                                 [disabled]="createDto.createNewClientAccount"
                                                 [required]="true"
                                                 [requireMatch]="true"
                                                 [matchProps]="['id']"
                                                 [searchParams]="accountSearchParams">
        </cb-client-account-autocomplete-scroller>
        <cb-checkbox label="Create New Client Account"
                     name="createNewClientAccount"
                     [(ngModel)]="createDto.createNewClientAccount"
                     (change)="createClientAccountChange()"></cb-checkbox>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="form.invalid || form.pristine"
                [clickLimit]="1"
                (cbClick)="save()">Save</button>
        <button mat-raised-button
                (click)="cancel(form.dirty)">Cancel</button>
    </footer>
</cb-dialog>
