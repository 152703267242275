import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { CbDialogModule } from '../../shared/components/dialog/dialog.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CbFormsModule } from '../../shared/components/forms/forms.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbProductInputFindModule } from '@app/shared/components/forms/individual/product-input-find/product-input-find.module';
import { ManageScheduleTemplateItemDialogComponent } from './manage-schedule-template-item-dialog/manage-schedule-template-item-dialog.component';
import { ScheduleItemsTableComponent } from './schedule-items-table/schedule-items-table.component';
import { CbVarModule } from '@app/shared/directives/var/var.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { ManageScheduleTemplateComponent } from './manage-schedule-template/manage-schedule-template.component';
import { ScheduleTemplateItemsTableComponent } from './schedule-template-items-table/schedule-template-items-table.component';
import { CbImageButtonModule } from '@app/shared/components/image-handlers/image-button/image-button.module';
import { CbInfoMessageModule, CbInfoPopupModule, CbLoadingSpinnerModule, CbTableModule } from 'cb-hub-lib';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';

const MAT_MODULES = [
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatDividerModule,
    MatTooltipModule,
    MatRadioModule
];

const SPEC_TEMPLATE_ROUTE_DATA = {
    breadcrumb: 'Edit Specification Template',
    breadcrumbPredecessors: [
        { label: 'Specification Templates', url: '/spec-templates' }
    ]
};

@NgModule({
    declarations:
        [
            ManageScheduleTemplateComponent,
            ScheduleItemsTableComponent,
            ScheduleTemplateItemsTableComponent,
            ManageScheduleTemplateItemDialogComponent
        ],
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        MatCheckboxModule,
        CbDisplayValueModule,
        CbProductInputFindModule,
        CbFormsModule,
        CbClickModule,
        CbImageButtonModule,
        CbLoadingSpinnerModule,
        CbInfoMessageModule,
        CbDialogModule,
        MatExpansionModule,
        MatSidenavModule,
        CbHubTableModule,
        CbTableModule,
        CbWaitForModule,
        CbVarModule,
        CbInfoPopupModule,
        RouterModule.forRoot([
            {
                component: ManageScheduleTemplateComponent,
                path: 'spec-schedule-templates/view/:id',
                data: SPEC_TEMPLATE_ROUTE_DATA,
            }
        ], {}),
        ...MAT_MODULES
    ]
})
export class ScheduleTemplateModule { }
