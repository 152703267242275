<mat-card class="cb-margin flex-col flex">
    <form class="flex-col"
          #productCategoriesForm="ngForm">
        <div class="cb-margin-bottom-2x start-center flex-row">
            <button mat-raised-button
                    color="primary"
                    (click)="addCategory()">
                Add Top Level Categoy
            </button>
        </div>
        <div class="flex-row flex">
            <div class="cb-margin-right-2x flex-col flex-33">
                <cb-category-filter class="flex"
                                    (change)="setSelectedCategory($event)"
                                    name="selectedCategories"
                                    [(ngModel)]="selectedCategories"
                                    [categoryEdited$]="categoryEdited$.asObservable()"
                                    [categoryAdded$]="categoryAdded$.asObservable()">
                </cb-category-filter>
            </div>
            <div class="flex-col flex">
                <mat-divider class="cb-margin-bottom-2x"></mat-divider>
                <div class="start-start flex-row">
                    <h4 class="mat-title">{{currentlySelectedCategory?.name}}</h4>
                    <span class="flex"></span>
                    <button mat-raised-button
                            class="cb-margin-right"
                            color="primary"
                            [disabled]="!currentlySelectedCategory"
                            (click)="editCategory(currentlySelectedCategory)">
                        Edit
                    </button>
                    <button mat-raised-button
                            class="cb-margin-right"
                            color="primary"
                            [disabled]="true">
                        Make Inactive
                    </button>
                    <button mat-raised-button
                            color="primary"
                            [disabled]="!currentlySelectedCategory"
                            (click)="addCategory(currentlySelectedCategory)">
                        Add Child Category
                    </button>
                </div>
                <cb-table class="cb-margin-top"
                          label="Address Regions"
                          [columns]="displayedColumns"
                          [loaded]="true">
                    <tbody cbTableBody>
                        <tr *ngFor="let attr of currentlySelectedCategory?.attributes">
                            <td>{{attr?.name}}</td>
                            <td>
                                <ul>
                                    <li *ngFor="let value of attr?.options">{{value?.name}}</li>
                                </ul>
                            </td>
                            <cb-td-checkbox [value]="attr?.isActive"></cb-td-checkbox>
                            <td cbTableShrinkCol
                                cbTableStickyCol="end">
                                <button mat-raised-button
                                        mat-icon-button
                                        (click)="editCategoryAttribute(attr)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="currentlySelectedCategory?.id">
                            <td colspan="5"
                                [style.textAlign]="'right'">
                                <button mat-raised-button
                                        mat-icon-button
                                        (click)="editCategoryAttribute(undefined)">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="!currentlySelectedCategory?.id">
                            <td colspan="5"
                                [style.textAlign]="'left'">
                                <cb-info-message message="Select a category to view and manage Attributes"
                                                 icon="info_circle">
                                </cb-info-message>
                            </td>
                        </tr>
                    </tbody>
                </cb-table>
            </div>
        </div>
    </form>
</mat-card>
